import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-p-type-dir',
  templateUrl: './p-type-dir.component.html',
  styleUrls: ['./p-type-dir.component.css']
})
export class PTypeDirComponent implements OnInit {
  id: any;
  testTypes: any;
  variantCount: any;
  costOfAttempt: any;
  constructor(public router: Router, private api: ApiService, public translate: TranslateService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.api.getProbTestEntTypes(4)
      .subscribe({
        next: (res: any) => {
          this.testTypes = res.items;
          // this.variantCount = res.items.someProperty;
          // sessionStorage.setItem('variantCount', JSON.stringify(this.variantCount));
          // this.id = res.items.someProperty;
          // sessionStorage.setItem('id', JSON.stringify(this.id));
          // this.costOfAttempt = res.items.someProperty;
          // sessionStorage.setItem('costOfAttempt', JSON.stringify(this.costOfAttempt));
        }
      });
  }

  link(e: any){
    sessionStorage.setItem('variantCount', JSON.stringify(e.variantCount));
    sessionStorage.setItem('id', JSON.stringify(e.id));
    sessionStorage.setItem('costOfAttempt', JSON.stringify(e.costOfAttempt));
    this.router.navigate(['profile/prob-test/4/' + e.id]);
  }

}
