<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="mb-4 w-full flex bg-blue-200 p-4 text-lg">
  <button class="znak-blue mr-4">
    !
  </button>
  <div class="text-blue-600">
    <div class="uppercase font-bold">
                <span *ngIf="translate.currentLang == 'ru'">

                    <p class="text-blue-800">Прием заявлений для участия в тестировании при приеме в гражданство или восстановлении в гражданстве Республики Казахстан завершается  11 ноября в 23:59 часов.</p>


                </span>
      <span *ngIf="translate.currentLang == 'kz'">


                    <p class="text-blue-800"> Қазақстан Республикасының азаматтығына қабылдау немесе азаматтығын қалпына келтіру кезіндегі тестілеуге қатысу үшін өтініш беру  11 қарашада сағат 23:59-да аяқталады.</p>


                </span>
    </div>
  </div>
</div>
 <p class="text-center text-3xl font-hairline text-blue-500 mb-4">{{ 'forAppCitizen' | translate }}:</p>
<form action="" [formGroup]="applicationForm">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ 'PersonalData' | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>
  <div class="flex py-1">
    <div class="w-1/3">
      {{ 'IIN' | translate }}
    </div>
    <div class="w-2/3">
      <input readonly disabled [mask]="iin" formControlName="iin" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Surname' | translate }}
    </div>
    <div class="w-2/3">
      <input disabled readonly formControlName="lastname" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Name' | translate }}
    </div>
    <div class="w-2/3">
      <input disabled readonly formControlName="firstname" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Patronymic' | translate }}
    </div>
    <div class="w-2/3">
      <input disabled readonly formControlName="patronymic" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'YourEmail' | translate }}
    </div>
    <div class="w-2/3">
      <input disabled readonly formControlName="email" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Citizenship' | translate }}
    </div>
    <div class="border w-1/3 h-6 rounded">
      <span *ngIf="translate.currentLang == 'kz'">{{ citizenship?.name.kk }} </span>
      <span *ngIf="translate.currentLang == 'ru'">{{ citizenship?.name.ru }} </span>
    </div>

  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Nationality' | translate }}
    </div>
    <div class="flex  rounded-mdp-4">
      <select class="border rounded" formControlName="nationId" >
        <option disabled [value]="0">
          {{ 'IndicateNationality' | translate }}
        </option>
        <option disabled *ngFor="let item of nationsList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'Sex' | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <div class="flex justify-around text-center">
        <div class="mr-4">
          <input value="1" disabled [checked]="applicationForm.get('sexId').value == 1" formControlName="sexId"
                 class="mr-2 bg-gray-200 rounded pol-radio" id="pol1" type="radio">
          <label for="pol1">{{ 'Male' | translate }}</label>
        </div>
        <div class="">
          <input value="2" disabled [checked]="applicationForm.get('sexId').value == 2" formControlName="sexId"
                 class="mr-2  bg-gray-200 rounded pol-radio" id="pol2" type="radio">
          <label for="pol2">{{ 'Female' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'yearOfBirth' | translate }}
    </div>
    <div class="w-2/3">
      <input disabled readonly formControlName="birthDate" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'PhoneNumber' | translate }}
    </div>
    <div class="w-2/3">
      <input [mask]="phone" placeholder="+7 (000) 000-00-00" formControlName="phoneNumber" class="border w-1/2 h-6 rounded" type="text">
    </div>
  </div>

  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ 'TestParams' | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'TestLang' | translate }}
    </div>
    <div class="flex  rounded-mdp-4">
      <select class="border rounded" formControlName="testLangId" >
        <option disabled [value]="0">
          {{ 'TestLang' | translate }}
        </option>
        <option  *ngFor="let item of langList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>

  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ 'TestLocation' | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'TestObl' | translate }}
    </div>
    <div class="flex  rounded-mdp-4">
      <select (change)="getTestOrgList(1)" class="border rounded" formControlName="oblId" >
        <option disabled [value]="0">
          {{ 'SelectTestObl' | translate }}
        </option>
        <option  *ngFor="let item of oblList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'TestLocation' | translate }}
    </div>
    <div class="flex  rounded-mdp-4">
      <select (change)="getTestPeriodList(1)" class="border rounded" formControlName="testOrgId" >
        <option disabled [value]="0">
          {{ 'SelectTestSite' | translate }}
        </option>
        <option  *ngFor="let item of testorgList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>

  <div class="flex py-3">
    <div class="w-1/3">
      {{ 'TestDateKaztest' | translate }}
    </div>
    <div class="flex  rounded-mdp-4">
      <select class="border rounded" formControlName="testPeriodId" >
        <option disabled [value]="0">
          {{ 'SelectTestDate' | translate }}
        </option>
        <option  *ngFor="let item of testPeriodList" [value]="item.id">
          <span>{{ item.testDate}} - {{ 'freePlace' | translate }}: {{ item.freePlaceCount }} </span>
        </option>
      </select>
    </div>
  </div>

  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ 'DisabilityData' | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>
  <fieldset style="height: 70px;">
  <div class="flex text-center mb-3 h-5">
    <div class="w-4/5 text-left">
      {{ 'DisabilityCs' | translate }}
    </div>
    <div class="w-2/5 mt-2">
      <input value="true" formControlName="isHasDisability"
             class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis1" type="radio">
      <label class="cursor-pointer" for="dis1">{{ 'Yes' | translate }}</label>
    </div>
    <div class="w-2/5 mt-2">
      <input value="false" formControlName="isHasDisability"
             class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis2" type="radio">
      <label class="cursor-pointer" for="dis2">{{ 'No' | translate }}</label>
    </div>
  </div>
  </fieldset>
  <div *ngIf="applicationForm.get('isHasDisability').value == 'true'" class="flex mobile-style-3">
    <div class="w-1/3">
      {{ 'Disability' | translate }}:
    </div>
    <div class="w-full grid">
      <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="disabilityId">
        <option disabled [value]="0">
          {{ 'SelectWhatNeed' | translate }}
        </option>
        <option *ngFor="let item of disabilityList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
  </div>

  <fieldset *ngIf="applicationForm.get('isHasDisability').value == 'true'"
            style="height: 70px;">
    <div class="flex text-center mb-3 h-5">
      <div class="w-1/5 text-left mt-2">
        {{ 'helper' | translate }}:
      </div>
      <div class="w-1/5 mt-2">
        <input value="true" formControlName="isDisabilityHelperRequired"
               class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis3" type="radio">
        <label class="cursor-pointer" for="dis3">{{ 'Yes' | translate }}</label>
      </div>
      <div class="w-2/5 mt-2">
        <input value="false" formControlName="isDisabilityHelperRequired"
               class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis4" type="radio">
        <label class="cursor-pointer" for="dis4">{{ 'No' | translate }}</label>
      </div>
    </div>
  </fieldset>

  <div *ngIf="applicationForm.get('isDisabilityHelperRequired').value == 'true'" class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-2xl font-hairline uppercase tracking-widest">
      {{ 'DisabilityHelperData' | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>

  <div class="" *ngIf="applicationForm.get('isDisabilityHelperRequired').value == 'true' && applicationForm.get('isHasDisability').value == 'true' ">
    <div class="">
      <div formGroupName="disabilityHelperInfo">
        <div class="flex py-1">
          <div class="w-1/3">
            {{ 'IIN' | translate }}
          </div>
          <div class="w-2/3">
            <input [mask]="iin" formControlName="iin" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
        <div class="flex py-3">
          <div class="w-1/3">
            {{ 'Surname' | translate }}
          </div>
          <div class="w-2/3">
            <input formControlName="lastname" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
        <div class="flex py-3">
          <div class="w-1/3">
            {{ 'Name' | translate }}
          </div>
          <div class="w-2/3">
            <input formControlName="firstname" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
        <div class="flex py-3">
          <div class="w-1/3">
            {{ 'Patronymic' | translate }}
          </div>
          <div class="w-2/3">
            <input formControlName="patronymic" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
        <div class="flex py-3">
          <div class="w-1/3">
            {{ 'PhoneNumber' | translate }}
          </div>
          <div class="w-2/3">
            <input [mask]="phone" placeholder="+7 (000) 000-00-00" formControlName="phoneNumber" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
        <div class="flex py-3">
          <div class="w-1/3">
            {{ 'SpecialityId' | translate }}
          </div>
          <div class="w-2/3">
            <input formControlName="specialityName" class="border w-1/2 h-6 rounded" type="text">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="applicationForm.get('isHasDisability').value == 'true' " class="mt-8 p-2 bg-white rounded-sm shadow-lg">
    <div class="">
      <label>
        {{ 'InvalidSpravka' | translate }}:
        <input type="file" formControlName="filename" (change)="onFileSelected($event)">
      </label>
    </div>
  </div>

<!--  <div *ngIf="applicationForm.get('isHasDisability').value == 'true' && applicationForm.get('disabilityId').value != 0" class="mt-8 p-2 bg-white rounded-sm shadow-lg">-->
<!--    <div class="flex">-->
<!--      <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"-->
<!--              (click)="addFile()">{{ 'addFile' | translate }}</button>-->
<!--      <div class="mx-auto w-1/2 text-center text-xl font-bold">-->
<!--        {{ 'InvalidSpravka' | translate }}-->
<!--      </div>-->
<!--      <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>-->
<!--    </div>-->
<!--    <span class="add-files-scroll">-->
<!--                <div formArrayName="docFiles" class="w-full mt-4 controls-border"-->
<!--                     *ngFor="let item of files.controls; index as i">-->
<!--                    <div [formGroup]="files.controls[i]">-->
<!--                        <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text"-->
<!--                                                                    readonly formControlName="filename"></span>-->
<!--                        <div class="f-btn-group">-->
<!--                            <div class="relative">-->
<!--                                <input (change)="changeListener($event, i, 'edit')"-->
<!--                                       class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file" />-->
<!--                                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">-->
<!--                                    <p>-->
<!--                                        <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate-->
<!--                                          }}</span>-->
<!--                                        <ng-template #elseBlock><span> {{ 'UploadInvFile' | translate-->
<!--                                          }}</span></ng-template>-->
<!--                                    </p>-->
<!--                                </div>-->
<!--                                <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' |-->
<!--                                  translate }}</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="s-btn-group">-->
<!--                            <button type="button" title="Удалить файл"-->
<!--                                    class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"-->
<!--                                    (click)="removeFile(i)">-->
<!--                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"-->
<!--                                     data-prefix="fas" data-icon="trash" class="svg-inline&#45;&#45;fa fa-trash fa-w-14"-->
<!--                                     role="img" viewBox="0 0 448 512">-->
<!--                                    <path fill="currentColor"-->
<!--                                          d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />-->
<!--                                </svg>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </span>-->
<!--  </div>-->

  <button (click)="modalAgreement = true" class="btn btn-primary w-full mt-8">
    {{ "Continue" | translate }}
  </button>

  <div *ngIf="modalAgreement === true" class="overlay">
    <div class="modal-window">
      <div class="relative">
        <div class="modal-sub-header">
          {{ "PeredPod" | translate }}
        </div>
        <div
          (click)="modalAgreement = false"
          class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold"
        >
          X
        </div>
      </div>
      <div (scroll)="onScrollEnd($event)" class="modal-text">
        <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(sogData.contentRus)"></div>
        <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(sogData.contentKaz)"></div>
      </div>

      <div class="text-center">
        <input
          formControlName="applicationAgree"
          (change)="agreeCheck($event)"
          type="checkbox"
          id="get-agree"
          class="get-agreement"
        />
        <label class="ml-2" for="get-agree">{{
            "RulesText-3" | translate
          }}</label>
      </div>

      <button
        [disabled]="!(applicationForm.get('applicationAgree').value)"
        (click)="postForm()"
        class="btn btn-primary w-full"
      >
        {{ "PostApplication" | translate }}
      </button>
    </div>
  </div>
</form>
