import { Component, OnInit, isDevMode, DoCheck } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../service/api.service"
import { MessageService } from "../../../service/message.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { KaztestService } from '../applications/kaztest/service/kaztest.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, DoCheck {

  applications = [];
  applicationsEnt = [];
  applicationsNqt = [];
  pageLangId: any;
  loading: any = false;
  id: number;
  appId: any;
  deleteModal = false;
  certificateButton: any;
  moduleId: any;
  showDeleteAppealModal = false;
  ikt = '000000000';
  appTypeList: any;
  showEditAppType = false;
  showTestCertificate = false;
  seasonId: number;
  appTypesForm: FormGroup;
  showSubjects = false;
  changeId: number;
  appTypeId: number;
  previousLength = 0;
  canChange: boolean;
  showErrNqt = false;
  showErrPvo = false;
  showErrCertif = false;
  showErrEnt = false;
  showErrKaztest = false;
  showNoAppealModal = false;
  deleteAppAppealModal = false;
  deleteCitizenApps = false;
  messages:any[];
  appealModal = false;

  isDev = isDevMode();

  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    public sanitizer:DomSanitizer,
    private messageService: MessageService,
    private kazTestApi:KaztestService
    ) {



  }

  ngOnInit(): void {

    this.applications = [];

    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    this.messageService.getMessages().subscribe({
      next:(res:any)=>{
        this.messages = res.items;
      }
    });

    this.getApplicationsList();
    // console.log(this.applications);
  }

  sanitizeStyle(unsafeStyle: string) {
    return this.sanitizer.bypassSecurityTrustStyle(unsafeStyle);
  }

  ngDoCheck() {
    if (this.applications?.length !== this.previousLength) {
      this.previousLength = this.applications.length;
      this.applications.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA; // Сортировка от новых к старым
      });
    }
  }

  closeEditApp() {
    this.showEditAppType = false;
    this.changeId = 0;
    this.appTypeId = 0;
  }

  checkTestPeriod() {
    // console.log(this.appTypeList);
    this.appTypeList.forEach(element => {
      if (this.appTypesForm.get('categoryToChange').value == element.id) {
        this.canChange = element.canChangeTestPeriod;
        // console.log(element);
      }
    });
  }

  getEntAppTypes() {
    this.api.getAppTypeEnt(this.seasonId)
      .subscribe((res: any) => {
        this.appTypeList = res.items;
        this.appTypesForm.get('categoryToChange').setValue(this.appTypeId);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);

      });
  }


  showAppTypeModal(seasonId: number, id: number, appTypeId: number) {
    this.showEditAppType = true;
    this.seasonId = seasonId;
    this.changeId = id;
    this.appTypeId = appTypeId;
    this.appTypesForm = new FormGroup({
      categoryToChange: new FormControl(0)
    });
    this.getEntAppTypes();
  }

  editEntAppType() {
    this.showEditAppType = false;
    this.router.navigate(['profile/history/edit/ent/' + this.appTypesForm.get('categoryToChange').value], { queryParams: { id: this.changeId, appType: this.appTypesForm.get('categoryToChange').value, canChangeTestPeriod: this.canChange }, });
  }

  showEditNqt(id: number, jobTypeId: number) {
    this.router.navigate(['profile/history/edit/nqt/' + jobTypeId], { queryParams: { id: id, jobType: jobTypeId } });
  }

  getApplicationsList() {
    this.applications = [];
    const requests = [
      this.getNqtItems(),
      this.getEntItems(),
      this.getAstItems(),
      this.getPvoItems(),
      this.getDirItems(),
      this.getCitizenItem(),
      this.getKaztestItems(),
      this.getKazTestZayav(),
      this.getAppApealls(),
      this.getKaztestItemsPg(),
    ];

    let completedRequests: any = 0;

    const incrementAndSort = () => {
      completedRequests++;
      if (completedRequests === requests.length) {
        // Все запросы завершены, выполняем сортировку
        this.applications.sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB; // Сортировка от новых к старым
        });
      }
    };

    requests.forEach((request: any) => {
      request.subscribe({
        next: (res: any) => {
          res.items.forEach((item: any) => {
            this.applications.push(item); // Добавляем элементы в общий массив
          });
          incrementAndSort();
        },
        error: (err) => {
          console.error('Ошибка запроса:', err);
          incrementAndSort(); // Увеличиваем счётчик даже при ошибке
        },
      });
    });
  }

  getCitizenItem() {
    this.api.getCitizenItems()
      .subscribe((res: any) => {
        res.items.forEach((e: any) => {
          this.applications.push(e);
        });
      });
  }

  getAppApealls(){
    this.kazTestApi.getAppApeall()
      .subscribe((res: any) => {
        res.items.forEach((e: any)=> {
          this.applications.push(e);
        });
    });
  }

  getKazTestZayav(){
    this.kazTestApi.getApp().subscribe({
      next:(res:any)=>{
        res.items.forEach((e: any) => {
          this.applications.push(e);
        });
      },
      error:(err:any)=>{
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    })
  }

  getDirItems() {
    this.api.getApplicationListDir()
      .subscribe((res: any) => {
        res.items.forEach((element: any) => {
          this.applications.push(element);
        });

      }, (err: any) => {
        this.showErrNqt = true;
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  getKaztestItems() {
    this.api.getApplicationListKaztest().subscribe({
      next: (res: any) => {
        res.items.forEach((e: any) => {
          this.applications.push(e);
        });

      }, error: (err) => {
        this.showErrKaztest = true;

        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    });
  }

  getKaztestItemsPg() {
    this.api.getApplicationListKaztestPg().subscribe({
      next: (res: any) => {
        res.items.forEach((e: any) => {
          this.applications.push(e);
        });

      }, error: (err) => {
        this.showErrKaztest = true;

        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    });
  }

  getNqtItems() {
    this.api.getApplicationList()
      .subscribe((res: any) => {
        res.items.forEach((element: any) => {
          this.applications.push(element);
        });
        // .push(res.items);

      }, (err: any) => {
        this.showErrNqt = true;
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getEntItems() {
    this.api.getApplicationListEnt()
      .subscribe((res: any) => {
        // this.applications.push(res.items);

        res.items.forEach((element: any) => {
          this.applications.push(element);
        });

      }, (err: any) => {
        this.loading = false;
        this.showErrEnt = true;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);

      });
  }

  getAstItems() {
    this.api.getAstAppItemsList()
      .subscribe((res: any) => {
        // this.applications.push(res.items);
        // res.items.forEach((element: any) => {
        //   this.applications.push(element);
        // });


      }, (err: any) => {
        this.loading = false;
        this.showErrCertif = true;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);

      });
  }

  getPvoItems() {
    this.api.getAppItemsPvo()
      .subscribe((res: any) => {
        // this.applications.push(res.items);
        res.items.forEach((element: any) => {
          this.applications.push(element);
        });
        this.loading = false;


      }, (err: any) => {
        console.log(err);


        this.showErrPvo = true;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
  }





  checkCertificate(id, iin, year, testType) {
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        // console.log(res);
        if (res.data.isExists) {
          this.api.downloadCertificate(id, iin, year, testType)
            .subscribe((res: any) => {
              let certifLink = res.data.certificateDownLoadUrl;
              window.open(certifLink, "_blank");
              this.loading = false;
            });
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  showDeleteModal(applicationId, moduleId) {
    this.deleteModal = true;
    this.moduleId = moduleId;
    this.appId = applicationId;
  }

  showNoAppeal(applicationId, moduleId?){
    this.showNoAppealModal = true;
    this.moduleId = moduleId;
    this.appId = applicationId;
  }


  showAppealModal(applicationId: number, moduleId: number){
    this.appealModal = true;
    this.moduleId = moduleId;
    this.appId = applicationId;
  }

  showDeleteAppAppealModal(applicationId: number, moduleId: number){
    this.deleteAppAppealModal = true;
    this.moduleId = moduleId;
    this.appId = applicationId;
  }

  showDeleteAppCitizenlModal(applicationId: number, moduleId: number){
    this.deleteCitizenApps = true;
    this.moduleId = moduleId;
    this.appId = applicationId;
  }

  cancelAppeal(){
    switch(this.moduleId){
      case 2:
        this.loading = true;
        this.api.cancelAppealEnt(this.appId).subscribe({
          next:()=>{
            this.appealModal = false;
            this.getApplicationsList();
            this.loading = false;
          },
          error:(err:any)=>{
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.appealModal = false;
            this.loading = false;
          }
        })
    }
  }
  postSetNoAppeal(){
    this.api.setNoAppeal(this.appId)
      .subscribe((res: any) => {
        this.showNoAppealModal = false;
        this.getApplicationsList();
      });
  }


  deleteAppAppeals(){
    this.api.deleteAppAppeal(this.appId)
      .subscribe((res: any) => {
        this.deleteAppAppealModal = false;
        this.getApplicationsList();
      });
  }

  deleteCitizen(){
    this.api.deleteCitizenApp(this.appId)
      .subscribe((res: any) => {
        this.deleteCitizenApps = false;
        this.getApplicationsList();
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.deleteModal = false;
        this.loading = false;
      });
  }

  deleteApplication() {
    switch (this.moduleId) {
      case 1:
        this.loading = true;
        this.api.deleteApplicationById(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      case 2:
        this.loading = true;
        this.api.deleteApplicationByIdEnt(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      case 3:
        this.loading = true;
        this.api.deleteApplicationByIdCert(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      case 4:
        this.loading = true;
        this.api.deleteAppItemsPvo(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      case 8:
        this.loading = true;
        this.kazTestApi.delete(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      case 9:
        this.loading = true;
        this.api.deleteAppItemsDir(this.appId)
          .subscribe((res: any) => {
            this.deleteModal = false;
            this.getApplicationsList();
            this.loading = false;
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            this.deleteModal = false;
            this.loading = false;
          });
        break;
      default: break;
    }

  }

}
