<div *ngIf="router.url == '/profile/prob-test/5'">
  <div class="text-center text-lg mb-8">
    {{ 'SelectTestType' | translate }}:
  </div>
  <div *ngFor="let item of testTypes" >
    <button (click)="link(item)"
            class="uppercase mb-8 text-2xl w-full text-center p-8 border rounded hover:bg-blue-200 cursor-pointer"
            type="button">
      <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
      <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
    </button>
  </div>
</div>
<router-outlet></router-outlet>
