import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  isDevMode,
} from '@angular/core';
import { ImComponent } from '../profile/im/im.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocationStrategy, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { MobileService } from 'src/app/service/mobile.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  // @ViewChild(ImComponent) im: ImComponent;

  @Output('removeInterval') app: EventEmitter<any> = new EventEmitter();

  loading: any;
  showPage: number;
  applicationId: string;
  jobTypeId: string;
  showSideMenu = false;
  isMobile = false;
  isDev = isDevMode();
  pageTitle = 'ҰТО/НЦТ';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public api: ApiService,
    public location: LocationStrategy,
    public translate: TranslateService,
    private mobileService: MobileService,
    public title: Title,
    private loc:Location
  ) {
    this.isMobile = mobileService.isMoble;
  }

  ngOnInit(): void {
    this.api.getNewMessages().subscribe(
      (res: any) => {},
      (err: any) => {}
    );

    if (
      localStorage.getItem('token') === 'null' || localStorage.getItem('token') === undefined ||
      localStorage.getItem('isRegistrationComplete') == 'null' ||
      localStorage.getItem('isRegistrationComplete') == '0'
    ) {
      this.router.navigate(['/auth']);
    } else {
      this.applicationId = this.route.snapshot.queryParamMap.get('id');
      this.jobTypeId = this.route.snapshot.queryParamMap.get('jobType');
    }
    this.changePageTitle();
  }

  changePageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        if(Object.keys(data).length === 0){
          this.title.setTitle(this.pageTitle);
          sessionStorage.setItem("pageTitle", this.pageTitle)
        }else{
          sessionStorage.setItem("pageTitle", this.translate.instant(data.breadcrumb))
          this.title.setTitle(this.translate.instant(data.breadcrumb));
        }
      });
  }

  exit() {
    this.app.emit();
    this.api.logout().subscribe();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
