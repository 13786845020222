import { HttpClient } from '@angular/common/http';
import { isDevMode, Injectable } from '@angular/core';
import { TokenService } from '../../../../../service/token.service';
import { PaymentModel, PostModel } from '../model/schema.interface';

@Injectable({
  providedIn: 'root'
})
export class KaztestService {
  private url:string = '';
  private urlAppeal:string = '';
  private certificate = '';

  constructor(private http: HttpClient,  private tokenService: TokenService) {
    if (isDevMode()) {
      this.url = 'https://app.debug.testcenter.kz/kaztest/student/app/api/v1'; // Путь для localhost
      this.urlAppeal = 'https://app.debug.testcenter.kz/kaztest/student/appeal/app/api/v1';
      this.certificate = 'https://certificate.debug.testcenter.kz/api/v1';

  }else
    {
        this.url = 'kaztest/student/app/api/v1'; // Путь для боевого
        this.certificate = 'https://certificate.testcenter.kz/api/v1';
        this.urlAppeal = 'kaztest/student/appeal/app/api/v1';
    }
  }

  getApp(){
    return this.http.get(`${this.url}/app/items`, this.tokenService.getHeaderAuth());
  }

  appealsShortInfo(id: any){
    return this.http.get(`${this.urlAppeal}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
  }

  editAppeal(id: any, body: any){
    return this.http.put(`${this.urlAppeal}/app/items/${id}`, body, this.tokenService.getHeaderAuth());
  }


  getCategories(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/category/items`, this.tokenService.getHeaderAuth());
  }
  downloadFile(id: any){
    return this.http.get(`${this.url}/app/items/${id}/disability-document`, {headers: this.tokenService.getTok()});
  }
  getNations(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/nation/items`, this.tokenService.getHeaderAuth());
  }

  getCitizenShips(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/citizenship/items`, this.tokenService.getHeaderAuth());
  }

  getGenders(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/sex/items`, this.tokenService.getHeaderAuth());
  }

  getTestCites(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/test-city/items`, this.tokenService.getHeaderAuth());
  }

  getObl(testTypeId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/obl/items`, this.tokenService.getHeaderAuth());
  }

  getTestOrg(testTypeId:number, oblId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/obl/items/${oblId}/test-org/items`, this.tokenService.getHeaderAuth());
  }

  getTestPeriod(testTypeId:number, testOrgId:number){
    return this.http.get(`${this.url}/app/test-type/items/${testTypeId}/test-org/items/${testOrgId}/test-period/items`, this.tokenService.getHeaderAuth());
  }

  update(id:number, body:PostModel){
    return this.http.put(`${this.url}/app/items/${id}`, body, this.tokenService.getHeaderAuth())
  }

  delete(id:number){
    return this.http.delete(`${this.url}/app/items/${id}`, this.tokenService.getHeaderAuth())
  }

  getPhoto(id:number){
    return this.http.get(`${this.url}/app/items/${id}/photo`, this.tokenService.getHeaderAuth())
  }

  createOrder(studentTestId:number, body:PaymentModel){
    return this.http.post(`${this.url}/app/items/${studentTestId}/order/create`, body,this.tokenService.getHeaderAuth() )
  }

  getShort(id:number){
    return this.http.get(`${this.url}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
  }

  getShortAppeal(id:number){
    return this.http.get(`${this.urlAppeal}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
  }

  getTestType(){
    return this.http.get(`${this.url}/app/test-type/items`, this.tokenService.getHeaderAuth());
  }

  checkCertificatecheck(id: number, iin: string, year: number, testType: number) {
    return this.http.get(`${this.certificate}/certificate/test/is-exists/test-type/${testType}/year/${year}/student/${id}/iin/${iin}`);
  }

  downloadCertificate(id: number, iin: string, year: number, testType: number) {
    return this.http.get(`${this.certificate}/certificate/test/download-link/test-type/${testType}/year/${year}/student/${id}/iin/${iin}`);
  }

  appItemsForApeals(item: any){
    return this.http.post(`${this.url}/app/items`, item, this.tokenService.getHeaderAuth());
  }

  getResultCommon(id: any){
    return this.http.get(`${this.urlAppeal}/test-result/${id}/common`, this.tokenService.getHeaderAuth());
  }

  getAnswerCard(id: any){
    return this.http.get(`${this.urlAppeal}/test-result/${id}/answer-card`, this.tokenService.getHeaderAuth());
  }

  setNoAppeal(studentTestId: any){
    return this.http.post(`${this.url}/app/items/${studentTestId}/set-no-appeal`, {}, this.tokenService.getHeaderAuth());
  }

  post(body:PostModel){
    return this.http.post(`${this.url}/app/items`, body, this.tokenService.getHeaderAuth());
  }

  postAppAppeal(body:PostModel){
    return this.http.post(`${this.urlAppeal}/app/items`, body, this.tokenService.getHeaderAuth());
  }

  showSubjectsAppeal(studentTestId: any) {
    return this.http.post(`${this.url}/app/items/${studentTestId}/show-subjects`, {}, this.tokenService.getHeaderAuth());
  }

  getAppApeall(){
    return this.http.get(`${this.urlAppeal}/app/items`, this.tokenService.getHeaderAuth());
  }
// appItemsShort(id: any) {
//   return this.http.get(`${this.url}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
// }

  deleteAppAppeal(id: any){
    return this.http.delete(`${this.urlAppeal}/app/items/${id}`, this.tokenService.getHeaderAuth());
  }

}
