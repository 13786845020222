<div *ngIf="router.url == '/profile/applications/4/seasons/21'" class="mb-3 flex bg-blue-200 p-4 text-lg">
    <button class="znak-blue mr-4">
        !
    </button>
    <div class="text-blue-600">
        <div class="text-blue-600">
            <span *ngIf="translate.currentLang == 'kz'">

<!--                <p>Тестілеу орны мен уақыты туралы ақпарат ағымдағы жылғы <b>18 шілдеде</b> түсушінің жеке кабинетінде-->
<!--                    «Менің-->
<!--                    іс-әрекетім» бөлімінде қолжетімді болады.</p>-->
              <p>Тестілеу орны мен уақыты туралы ақпарат ағымдағы жылғы <b>13 қарашада </b> түсушінің жеке кабинетінде
                    «Менің
                    іс-әрекетім» бөлімінде қолжетімді болады.</p>


            </span>
            <span *ngIf="translate.currentLang == 'ru'">


<!--                <p> Информация о месте и времени проведения тестирования будет доступна <b>18 июля</b> текущего года в-->
<!--                    личном кабинете поступающего в разделе «Моя история».</p>-->
              <p> Информация о месте и времени проведения тестирования будет доступна <b>13 ноября </b> текущего года в
                    личном кабинете поступающего в разделе «Моя история».</p>


            </span>
        </div>
    </div>
</div>

<!--<div *ngIf="router.url == '/profile/applications/4/seasons/12'" class="mb-4 flex bg-blue-200 p-4 text-lg">-->
<!--    <button class="znak-blue mr-4">-->
<!--        !-->
<!--    </button>-->
<!--    <div class="text-blue-600">-->
<!--        <div class="text-blue-600">-->
<!--            <span *ngIf="translate.currentLang == 'kz'">-->

<!--                <p>Магистратураға түсу үшін кешенді тестілеуге қатысу үшін өтініштер қабылдау Алматы қаласында-->
<!--                    орындардың жоқ болуына байланысты жабылды.</p>-->


<!--            </span>-->
<!--            <span *ngIf="translate.currentLang == 'ru'">-->


<!--                <p>В связи с отсутствием мест в городе Алматы прием заявлений на участие в комплексном тестировании для-->
<!--                    поступления в магистратуру закрыт.</p>-->


<!--            </span>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<div *ngIf="this.router.url == '/profile/applications/' + appId +'/seasons/' + seasonId" class="flex flow">
    <div *ngFor="let appType of appTypeList" [routerLink]="this.router.url + '/' + appType.path"
        [queryParams]="{appTypeId: appType.id}"
        class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
        <span *ngIf="translate.currentLang == 'kz'">{{ appType.name.kk }}</span>
        <span *ngIf="translate.currentLang == 'ru'">{{ appType.name.ru }}</span>
    </div>
</div>
<router-outlet></router-outlet>

<p *ngIf="router.url == '/profile/applications/4/seasons/9'" class="mb-2 text-center text-xl mt-2 text-blue-600">
    <!-- <span *ngIf="translate.currentLang == 'kz'">
        <a target="_blank" href="https://testcenter.kz/press-tsentr/novosti/?ELEMENT_ID=5116">{{ 'PvoSubTxt3' | translate }}</a>
    </span>
    <span *ngIf="translate.currentLang == 'ru'">
        <a target="_blank" href="https://testcenter.kz/ru/press-tsentr/novosti/?ELEMENT_ID=5117">{{ 'PvoSubTxt3' | translate }}</a>
    </span>
    <br> -->
    <span *ngIf="translate.currentLang == 'kz'">
        <a target="_blank" href="https://testcenter.kz/press-tsentr/novosti/?ELEMENT_ID=5453">{{ 'PvoSubTxt5' |
            translate }}</a>
    </span>
    <span *ngIf="translate.currentLang == 'ru'">
        <a target="_blank" href="https://testcenter.kz/ru/press-tsentr/novosti/?ELEMENT_ID=5452">{{ 'PvoSubTxt5' |
            translate }}</a>
    </span>
</p>

<p *ngIf="router.url == '/profile/applications/4/seasons/10'" class="mb-2 text-center text-xl mt-2 text-blue-600">
    <!-- <span *ngIf="translate.currentLang == 'kz'">
        <a target="_blank" href="https://testcenter.kz/press-tsentr/novosti/?ELEMENT_ID=5118">{{ 'PvoSubTxt4' | translate }}</a>
    </span>
    <span *ngIf="translate.currentLang == 'ru'">
        <a target="_blank" href="https://testcenter.kz/ru/press-tsentr/novosti/?ELEMENT_ID=5119">{{ 'PvoSubTxt4' | translate }}</a>
    </span>
    <br> -->
    <span *ngIf="translate.currentLang == 'kz'">
        <a target="_blank" href="https://testcenter.kz/press-tsentr/novosti/?ELEMENT_ID=5454">{{ 'PvoSubTxt6' |
            translate }}</a>
    </span>
    <span *ngIf="translate.currentLang == 'ru'">
        <a target="_blank" href="https://testcenter.kz/ru/press-tsentr/novosti/?ELEMENT_ID=5455">{{ 'PvoSubTxt6' |
            translate }}</a>
    </span>
</p>
