import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KaztestService } from '../../applications/kaztest/service/kaztest.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kaz-test-appeal-result',
  templateUrl: './kaz-test-appeal-result.component.html',
  styleUrls: ['./kaz-test-appeal-result.component.css']
})
export class KazTestAppealResultComponent implements OnInit {

  @Input() app;
  @Output() deleteApp = new EventEmitter<any>();
  @Output() noAppeal = new EventEmitter<any>();
  @Output() deleteAppeal = new EventEmitter<any>();

  loading: any;
  id: any;
  shortList: any;
  isCollapsed = true;
  isTableVisible = false;
  showSubjects = false;

  constructor(
    private api: KaztestService,
    private router: Router,
    private _notifications: NotificationsService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }
  toggleTextVisibility(subjectId: number) {
    const subject = this.app.subjects.find((sub) => sub.id === subjectId);
    if (subject) {
      subject.showFullText = !subject.showFullText;
    }
  }

  showPayment(id: number){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 3} });
  }

  // showEdit(id:number, testTypeId:number){
  //   if(id !== 0 || testTypeId != 0){
  //     this.router.navigate(['profile/history/edit/kaztest', testTypeId, id]);
  //   }
  // }
  //
  // showAppeal(id:number, testTypeId:number){
  //   this.router.navigate(['profile/applications/3/3/', id]);
  // }
  //
  // showDeleteModal(){
  //   this.deleteApp.emit();
  // }
  //
  // showNoModal(){
  //   this.noAppeal.emit();
  // }

  showDeleteAppealModal(){
    this.deleteAppeal.emit();
  }

  showAppealEdit (id: number){
    this.router.navigate(['profile/history/edit/kazappealedit', id]);
  }
  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
            .subscribe((res: any) => {
              let certifLink = res.data.certificateDownLoadUrl;
              window.open(certifLink, "_blank");
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  // toggleTable() {
  //   this.app.showSubjects = !this.app.showSubjects;
  //   this.isTableVisible = !this.isTableVisible;
  //   this.showSubjectsAppAppeal();
  // }

  // showSubjectsAppAppeal(){
  //   this.api.showSubjectsAppeal(this.app.id)
  //     .subscribe((res: any) => {
  //     });
  // }


  // appItemsForApeal(){
  //   let data: any = {};
  //   data = {
  //     iin: this.shortList.iin,
  //     studentTestId: this.app.studentTestId,
  //     email: this.shortList.email,
  //     phoneNumber: this.app.phoneNumber,
  //     subjects: [
  //       {
  //         id: this.app.id,
  //         appealReason: this.app.appealReason
  //       }
  //     ]
  //   };
  //   this.api.appItemsForApeals(data)
  //     .subscribe((res: any) => {
  //     });
  // }
  postSetNoAppeal(id: any){
    this.noAppeal.emit(id);
  }




}
