import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { KaztestService } from 'src/app/components/profile/applications/kaztest/service/kaztest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from '../../../../../../service/message.service';
import {logger} from 'codelyzer/util/logger';
@Component({
  selector: 'app-kaz-appeal-edit',
  templateUrl: './kaz-appeal-edit.component.html',
  styleUrls: ['./kaz-appeal-edit.component.css']
})
export class KazAppealEditComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  totalScore: any;
  nationsList = [];
  countryList = [];
  subjects: any;
  dataofbday = 0;
  phone = '+0 (000) 000-00-00';
  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canAddAppealDescription = false;
  showWindowModal = false;
  blockBtn = false;
  onlineTestAppealCount: any;
  ruleskazTestSogData: any;
  testTypeId: number = 0;
  studentTestId: any;
  testPeriod = [];
  showAnwerCard = false;
  textModal = false;
  appealReason: string;
  appealReasonDescription: any;
  applicationAgree = false;
  subjectId: any;
  id: any;
  appealList = [];
  constructor(
    public mainApi: ApiService,
    public api: KaztestService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer:DomSanitizer,
    private router:Router,
    private messagService: MessageService

  ) {
    // this.route.url.subscribe((v) => (this.testTypeId = +v[0].path));
    // this.route.params.subscribe({
    //   next:params=>{
    //     if(params['testTypeId'] && params['id']){
    //       this.id = +params['id'];
    //       if( this.id != 0){
    //         this.isEdit = true;
    //       }
    //     }
    //   }
    // });

    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      iin: new FormControl(0, Validators.required),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      birthDate: new FormControl(0),
      subjects: new FormArray([]),
      studentTestId: new FormControl(0, Validators.required),
    });

    this.messagService.getKazTestSog()
      .subscribe((res: any) => {
        this.ruleskazTestSogData = res;
      });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {this.id = params.get('id');
      // this.getCommon(this.id);
      // this.getAnswer(this.id);
    });

    this.appealsShortInfoList();
    this.getNationList();
    this.getCitizenshipList();

    this.mainApi.profileInfo().subscribe((res: any) => {
      this.applicationForm.get('studentTestId').setValue(res.data.studentTestId);
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      if (res.data.sexId == 0) {
        this.canEditSexId = true;
      }
      if (res.data.nationId == 0 || res.data.nationId == 99) {
        this.canEditNationId = true;
      }
      if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
        this.canEditCitizenshipId = true;
      }
      if (res.data.phoneNumber == 0) {
        this.canEditPhoneNumber = true;
      }
      this.applicationForm.get('sexId').patchValue(res.data.sexId);
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
      this.applicationForm.get('nationId').patchValue(res.data.nationId);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
      this.applicationForm.get('birthDate').setValue(res.data.birthDate);
      this.applicationForm.get('iin').setValue(res.data.iin);
      this.applicationForm.get('appealReason').setValue(res.data.subjects.appealReason);

    });

  }

  toggleTextVisibility(subjectId: number) {
    const subject = this.subjects.find((sub) => sub.id === subjectId);
    if (subject) {
      subject.showFullText = !subject.showFullText; // Переключаем состояние
    }
  }

  appealsShortInfoList(){
    this.api.appealsShortInfo(this.id)
      .subscribe((res: any) => {
        this.appealList = res.data;
        this.subjects = res.data.subjects;
        this.applicationForm.get('studentTestId').setValue(res.data.studentTestId);
        this.subjects.forEach((e: any) => {
            const subject: FormGroup = new FormGroup({
              id: new FormControl(e.id),
              appealReasonDescription: new FormControl(e.appealReason),
            });

            this.subject.push(subject);

        });

      });
  }

  get subject(): FormArray {return this.applicationForm.get('subjects') as FormArray;}
  getNationList() {
    this.api.getNations(this.testTypeId).subscribe(
      (res: any) => {
        this.nationsList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
            'Ошибка',
            err.error.errorMessage.ru,
            NotificationType.Error,
            5000
          )
          : this._notifications.create(
            'Қате',
            err.error.errorMessage.kk,
            NotificationType.Error,
            5000
          );
      }
    );
  }

  getCitizenshipList() {
    this.api.getCitizenShips(this.testTypeId).subscribe(
      (res: any) => {
        this.countryList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
            'Ошибка',
            err.error.errorMessage.ru,
            NotificationType.Error,
            5000
          )
          : this._notifications.create(
            'Қате',
            err.error.errorMessage.kk,
            NotificationType.Error,
            5000
          );
      }
    );
  }

  showWindow(id: any){
    this.showWindowModal = !this.showWindowModal;
    const group = this.subject.controls.find((group: FormGroup) => group.get('id')?.value === id) as FormGroup;
    this.subjectId = group.get('id').value;
    this.appealReasonDescription = group.get('appealReasonDescription').value;
  }

  clearReason() {
    // Находим группу формы по `subjectId`
    const group = this.subject.controls.find(
      (group: FormGroup) => group.get('id')?.value === this.subjectId
    ) as FormGroup;

    // Очищаем поле `appealReasonDescription` в форме
    if (group) {
      group.get('appealReasonDescription')?.setValue('');
    }

    // Обновляем объект в массиве `applications`
    const application = this.subjects.find((app) => app.id === this.subjectId);
    if (application) {
      application.appealReason = ''; // Очищаем поле `appealReason`
    }

    // Закрываем модальное окно
    this.showWindowModal = false;
  }
  saveAgreeModal() {
    this.showWindowModal = false;
    const group = this.subject.controls.find((group: FormGroup) => group.get('id')?.value === this.subjectId) as FormGroup;
    group.get('appealReasonDescription').setValue(this.appealReasonDescription);
    const updatedSubject = this.subjects.find((sub) => sub.id === this.subjectId);
    if (updatedSubject) {
      updatedSubject.appealReason = this.appealReasonDescription;
    }
  }

  cancel(){
    this.showWindowModal = false;
  }



  postApp(){
    const transformedSubjects = this.applicationForm.value.subjects
      .filter(subject => subject.appealReasonDescription.trim() !== '')
      .map(subject => ({
        id: subject.id,
        appealReasonDescription: subject.appealReasonDescription.trim()
      }));
    let payload = {
      iin: this.applicationForm.get('iin').value,
      studentTestId: this.applicationForm.get('studentTestId').value,
      email: this.applicationForm.get('email').value,
      phoneNumber: this.applicationForm.get('phoneNumber').value,
      subjects: transformedSubjects.length > 0 ? transformedSubjects : []
    };

    this.api.editAppeal(this.id, payload)
      .subscribe((res: any) => {
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();
        }, 200);

      },
        (err: any) => {
          this.translate.currentLang == 'ru'
            ? this._notifications.create(
              'Ошибка',
              err.error.errorMessage.ru,
              NotificationType.Error,
              5000
            )
            : this._notifications.create(
              'Қате',
              err.error.errorMessage.kk,
              NotificationType.Error,
              5000
            );
        });
  }



  funcCancel(){
    this.router.navigateByUrl('/profile/history');

  }
  openTextAgree(){
    this.textModal = true;

  }
  closeAgreeModalPostApp() {
    this.textModal = false;
    this.applicationAgree = false;
  }
  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.blockBtn = false;
    }
  }

  agreeCheck() {
    this.applicationAgree = !this.applicationAgree;
  }

}
