<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div *ngIf="router.url == '/profile/im'" class="">

    <div *ngFor="let m of messages;" class="mb-4">
        <span *ngIf="translate.currentLang == 'kz'">
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(m.content.kk)"></div>
        </span>
        <span *ngIf="translate.currentLang == 'ru'">
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(m.content.ru)"></div>
        </span>

    </div>

    <div [routerLink]="'/profile/applications'"
        class="mb-4 text-center border-blue-600 border-2 cursor-pointer rounded bg-blue-200 hover:bg-blue-100 p-4">
        <div class="text-3xl text-blue-600">
            {{ 'PostApplication' | translate }}
        </div>
    </div>
    <div class="flex mb-4 text-header">
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-1/3 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'MyData' | translate }}
        </div>
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="uppercase text-3xl mt-2">
        {{ lastname }} {{ firstname }} {{ patronymic }}
    </div>
    <div class="flex text-lg mt-3">
        <p class="text-gray-600 w-48">
            {{ 'IIN' | translate }}
        </p>
        <p>
            {{ iin }}
        </p>
        <button class="ml-3 bg-blue-500 hover:bg-blue-700 text-white  font-bold  rounded" (click)="updateProfileInfo()" ><span class=" py-3 px-3 text-sm">{{ 'Update' | translate }}</span></button>


    </div>
    <div class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'Citizenship' | translate }}
        </p>
        <p>
            <span *ngIf="translate.currentLang == 'kz'">{{ profileInfo?.citizenship.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ profileInfo?.citizenship.name.ru }} </span>
        </p>
    </div>
    <div class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'Nationality' | translate }}
        </p>
        <p>
            <span *ngIf="translate.currentLang == 'kz'">{{ profileInfo?.nation.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ profileInfo?.nation.name.ru }} </span>
        </p>
    </div>

    <div class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'Sex' | translate }}
        </p>
        <p>
            <span *ngIf="translate.currentLang == 'kz'">{{ profileInfo?.sex.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ profileInfo?.sex.name.ru }} </span>
        </p>
    </div>
    <div *ngIf="profileInfo?.iinType == 2" class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'BirthDate' | translate }}
        </p>
        <p>
            {{ profileInfo?.birthDate }}
        </p>
    </div>
    <div *ngIf="profileInfo?.iinType == 2" class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'DocumentId' | translate }}
        </p>
        <p>
            {{ profileInfo?.documentId }}
        </p>
    </div>
    <div class="flex text-lg">
        <p class="text-gray-600 w-48">
            {{ 'PhoneNumber' | translate }}
        </p>
        <p>
            +{{ phoneNumber | mask: '0 000 000 00 00'}}
        </p>
        <span [routerLink]="'/profile/im/edit-profile'" class="edit-btn ml-2 cursor-pointer ">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
        </span>
    </div>

    <!-- <div class="flex mb-4 text-header">
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-1/3 text-center text-3xl font-hairline tracking-widest uppercase">
            {{ 'Security' | translate }}
        </div>
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
    </div> -->
    <div class="flex mt-4">
        <p class="w-48 text-lg text-gray-600">
            {{ 'Email' | translate }}
        </p>
        <p class="flex">
            {{ email }}
            <span [routerLink]="'change-email'" class="edit-btn ml-2 cursor-pointer ">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
            </span>
        </p>
    </div>
    <div class="flex">
        <p class="w-48 text-lg text-gray-600">
            {{ 'Password' | translate }}
        </p>
        <p [routerLink]="'change-password'" class="underline text-gray-400 cursor-pointer">
            {{ 'EditPassword' | translate }}
        </p>
    </div>

    <!-- <div class="flex mb-4 text-header">
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-1/3 text-center text-3xl font-hairline tracking-widest uppercase">
            {{ 'Info' | translate }}
        </div>
        <div class="w-1/3 h-1 bg-blue-300 mt-4">
        </div>
    </div> -->

    <!-- <div class="mb-4 flex bg-blue-200 p-4">
        <button class="znak-blue mr-4">
            !
        </button>
        <div class="text-blue-600">
            {{ 'ForInvalidsInfo' | translate }}
        </div>
     </div> -->

    <!-- <div class="mb-4 flex bg-red-200 p-4">
        <div class="znak mr-4">
            !
        </div>
        <div class="text-red-600">
            {{ 'PayInfo' | translate }}
        </div>
     </div> -->

    <div class="">
        <!-- <p class="text-center text-2xl text-red-600 mb-4 bg-red-200 p-4">{{ 'EditInfo' | translate }}</p> -->
        <!-- <p class="text-center text-2xl text-blue-600 mb-4">{{ 'KaspiInfo-1' | translate }}</p>
        <ol class="list-decimal list-inside">
            <li>
                {{ 'KaspiInfo-2' | translate }}
            </li>
            <li>
                {{ 'KaspiInfo-3' | translate }}
            </li>
            <li>
                {{ 'KaspiInfo-4' | translate }}
            </li>
            <ul class="list-disc list-inside">
                <li>
                    {{ 'KaspiInfo-5' | translate }}
                </li>
                <li>
                    {{ 'KaspiInfo-6' | translate }}
                </li>
                <li>
                    {{ 'KaspiInfo-7' | translate }}
                </li> -->
        <!-- <li>
                    {{ 'KaspiInfo-8' | translate }}
                </li> -->
        <!-- <li>
                    {{ 'KaspiInfo-9' | translate }}
                </li>
            </ul>
        </ol>  -->
    </div>
    <div class="">
        <p class="text-center text-2xl text-blue-600 mb-4">{{ 'Contacts' | translate }}</p>

      <p class="mb-2">
        Call center:  +7 (7172) 799889
      </p>

      <p class="mb-2">
        ҰБТ/ЕНТ: +7 (7172) 799889 (ішкі номер 186, 132,  193)
      </p>

      <p class="mb-2">
        ПББ/ОЗП: +7 (7172) 799889 (ішкі номер169, 194 ,121,197)
      </p>

      <p class="mb-2">
        Магистратура: +7 (7172) 799889 (ішкі номер 133, 134)
      </p>

      <p class="mb-2">
        Апелляция ҰБТ/ЕНТ: +7 (7172) 799889 (ішкі номер 189)
      </p>

      <p class="mb-2">
        Апелляция ПББ/ОЗП: +7 (7172) 799889 (ішкі номер 164,  200 )
      </p>

      <p class="mb-2">
        Апелляция (Магистратура): +7 (7172) 799889 (ішкі номер172)
      </p>

      <p class="mb-2">
        ҚАЗТЕСТ: kazakhtest.kz Кеңсе: +7 (7172) 799889 (ішкі номер 190, 202)
      </p>

<!--      <p class="mb-2">-->
<!--        QAZTEST : + 7 (7172) 69 56 84, 69 52 85, 69 52 96-->
<!--      </p>-->
        <!-- <p class="">
            {{ 'PayTestQuestions' | translate }} : <a class="underline text-blue-700" href="./../../../../../../assets/Ustudy.pdf" target="_blank"> {{ 'UstudyContacts' | translate }}</a>
        </p> -->
    </div>

    <div class="mt-2">
        <p>
            <!-- {{ 'SAS' | translate }} -->
        </p>
    </div>

</div>


<router-outlet *ngIf="router.url != '/profile/im'"></router-outlet>
