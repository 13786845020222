<div class="container">
  <h1>Privacy Policy</h1>
  <p>At <strong>[ҰЛТТЫҚ ТЕСТІЛЕУ ОРТАЛЫҒЫ]</strong>, we highly value the privacy of our users. This privacy policy explains how the UTO (com.testcenter.utodeneme) application collects, uses, and protects user data.</p>

  <h2>1. Collected Data</h2>
  <p>The UTO application may collect the following types of personal and sensitive user data:</p>
  <ul>
    <li>Email address</li>
    <li>Phone number</li>
    <li>Device information</li>
  </ul>

  <h2>2. Data Usage</h2>
  <p>The collected data may be used for the following purposes:</p>
  <ul>
    <li>To improve user experience</li>
    <li>To provide customer support</li>
    <li>For analysis and research purposes</li>
    <li>To enhance application performance</li>
  </ul>

  <h2>3. Data Sharing</h2>
  <p>User data may be shared in the following circumstances:</p>
  <ul>
    <li>With third-party service providers (e.g., analytics services)</li>
    <li>With authorities as required by law</li>
    <li>With other parties with user consent</li>
  </ul>

  <h2>4. Data Security</h2>
  <p>User data is stored on secure servers. Appropriate technical and administrative measures are taken to ensure data security. Measures are in place to protect data from unauthorized access, alteration, disclosure, or destruction.</p>

  <h2>5. Data Retention and Deletion</h2>
  <p>Collected data is retained only for as long as necessary and is securely deleted thereafter. Users may request the deletion of their data.</p>

  <h2>6. User Rights</h2>
  <p>Users have the right to access, correct, delete, and object to the processing of their personal data. To exercise these rights, users can contact us at <a href="mailto:uto@testcenter.kz">uto@testcenter.kz</a>.</p>

  <h2>7. Children's Privacy</h2>
  <p>The UTO application does not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information as soon as possible.</p>

  <h2>8. Changes to the Privacy Policy</h2>
  <p>This privacy policy may be updated from time to time. We will notify users of any changes through the application or via email.</p>

  <h2>9. Contact Information</h2>
  <p>If you have any questions or concerns about this privacy policy, please contact us at <a href="mailto:uto@testcenter.kz" class="contact-info">uto@testcenter.kz</a>.</p>

  <p><strong>Privacy Policy Last Updated:</strong> 08/06/2024</p>
</div>
