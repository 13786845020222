import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-e-zayav-prob-ent',
  templateUrl: './e-zayav-prob-ent.component.html',
  styleUrls: ['./e-zayav-prob-ent.component.css'],
})
export class EZayavProbEntComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  textModal = false;
  applicationAgreeParent = false;
  phoneNumber: any;
  iin: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  blockBtn = false;
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  shortList: any;
  applicationAgree = false;
  id: any;
  testPeriodId: any;
  testOrgOblId: any;
  testOrgId: any;
  testTypeGroupId: any;
  testLanguageList = [];
  sabjPairList = [];
  testTypeEntList = [];
  collegList = [];
  eduList = [];
  varlist = [];
  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });
    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      testOrgOblId: new FormControl(0, Validators.required),
      profileSubjectPairId: new FormControl(0, Validators.required),
      testLanguageId: new FormControl(0, Validators.required),
      testTypeId: new FormControl(0, Validators.required),
      collegeSpecId: new FormControl(0, Validators.required),
      eduProgramId: new FormControl(0, Validators.required)
    });
  }

  ngOnInit(): void {
    this.applicationForm.get('testTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('profileSubjectPairId').setValue(0);
      this.applicationForm.get('collegeSpecId').setValue(0);
      this.applicationForm.get('eduProgramId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.sabjPairList = [];
      this.collegList = [];
      this.eduList = [];
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('profileSubjectPairId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('collegeSpecId').valueChanges.subscribe((value) => {
      this.applicationForm.get('eduProgramId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.eduList = [];
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('eduProgramId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('testOrgOblId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testOrgId').setValue(0);
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testOrgList = [];
      this.testPeriodList = [];

      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.applicationForm.get('testOrgId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testPeriodList = [];

      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.api.getShortProbEnt(this.id)
      .subscribe((res: any) => {
        this.shortList = res.data;
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.testPeriodId = res.data.testPeriodId;
        this.testOrgId = res.data.testOrgId;
        this.testOrgOblId = res.data.testOrgOblId;
        this.testTypeGroupId = res.data.testTypeGroupId;
        this.iin = res.data.iin;
        this.email = res.data.email;
        this.applicationForm.get('email')?.patchValue(res.data.email);
        this.applicationForm.get('phoneNumber')?.patchValue(res.data.phoneNumber);
        this.applicationForm.get('testOrgOblId')?.setValue(this.shortList.testOrgOblId);
        this.applicationForm.get('testOrgId')?.setValue(this.shortList.testOrgId);
        this.applicationForm.get('testPeriodId')?.setValue(this.shortList.testPeriodId);
        this.applicationForm.get('testTypeId')?.setValue(this.shortList.testTypeId);
        this.applicationForm.get('profileSubjectPairId')?.setValue(this.shortList.profileSubjectPairId);
        this.applicationForm.get('collegeSpecId')?.setValue(this.shortList.collegeSpecId);
        this.applicationForm.get('eduProgramId')?.setValue(this.shortList.eduProgramId);
        this.applicationForm.get('testLanguageId')?.setValue(this.shortList.testLanguageId);
        this.getTestTypeForEnt();
        this.changeEnt();
        this.eduProgram();
        if (this.applicationForm.get('testTypeId')?.value == 62){
          this.profileLangPair();
        }else {
          this.testLanguageTipo();
        }
        this.varInfoEntList();
        this.getObl();
        this.getTestOrg();
        this.getTestPeriod();
    });
  }

  getTestTypeForEnt(){
    this.api.getTestTypeForEnt(this.testTypeGroupId)
      .subscribe((res: any) => {
        this.testTypeEntList = res.items;
      });
  }
  changeEnt(){
    if (this.applicationForm.get('testTypeId').value == 62) {
      this.profileSubjPair();
    }else{
      this.collegSpec();
    }
  }
  profileSubjPair(){
    this.api.getProfileSubjPair(this.applicationForm.get('testTypeId').value)
      .subscribe((res: any) => {
        this.sabjPairList = res.items;
      });
  }
  profileLangPair(){
    this.api.getProfileLangPair(this.applicationForm.get('testTypeId').value, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.testLanguageList = res.items;
      });
  }

  collegSpec(){
    this.api.getCollege(this.applicationForm.get('testTypeId').value)
      .subscribe((res: any) => {
        this.collegList = res.items;
      });
  }

  eduProgram() {
    this.api.getEduProgramForPobEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('collegeSpecId').value)
      .subscribe((res: any) => {
        this.eduList = res.items;
      });
  }

  testLanguageTipo(){
    this.api.getTestLanguageTipo(this.applicationForm.get('testTypeId').value, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.testLanguageList = res.items;
      });
  }

  varInfoEntList(){
    if (this.applicationForm.get('testTypeId').value == 62 && this.applicationForm.get('testLanguageId').value != 0){
      this.api.varInfoEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('profileSubjectPairId').value, this.applicationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varlist = res.items;
        });
    } else {
      this.api.varInfoTypoEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varlist = res.items;
        });
    }

  }
  getObl() {
    this.api.getObls(this.testTypeGroupId).subscribe((res: any) => {
      this.obls = res.items;
    });
  }
  getTestOrg(){
    this.api.getTestOrgs(this.testTypeGroupId, this.applicationForm.get('testOrgOblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      });
  }

  getTestPeriod(){
    this.api.getTestPeriods(this.testTypeGroupId, this.applicationForm.get('testOrgId')!.value, this.id)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
      });
  }
  postForm(){
    this.loading = true;
    this.api.editDataEnt(this.id, this.applicationForm.value)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
          this.router.navigateByUrl('/profile/prob-test/2/2');
        },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });

  }

}
