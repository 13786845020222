import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class konkursPvoConfig {
  konkursPvoBackend: any;
  apiRoutes: any;

  constructor() {
    if (isDevMode()) {
      this.konkursPvoBackend = 'https://app.debug.testcenter.kz/konkurs/pvo/app/api/v1'; // Путь для localhost
    }
    if (!isDevMode()) {
      this.konkursPvoBackend = 'konkurs/pvo/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
        getAppItems: this.konkursPvoBackend + '/app/items'
    }

  }
}
