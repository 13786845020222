<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ "PVOProb" | translate }}
</p>
<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ 'probNct' | translate}}
</p>
<form [formGroup]="applicationForm" action="">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ "PersonalData" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "IIN" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ iin }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Surname" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ lastname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Name" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ firstname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Patronymic" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ patronymic }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "YourEmail" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ email }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "PhoneNumber" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text"/>
    </div>
  </div>
  <div class="flex mt-4 mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ "TestParams" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "SelectObl" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select (change)="getTestOrg()" formControlName="testOrgOblId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectObl" | translate }}
        </option>
        <option *ngFor="let item of obls" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "SelectTestSite" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select (change)="getTestPeriod()" formControlName="testOrgId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectTestSite" | translate }}
        </option>
        <option *ngFor="let item of testOrgList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "SelectTestDate" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select formControlName="testPeriodId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectTestDate" | translate }}
        </option>
        <option *ngFor="let item of testPeriodList" [value]="item.id">
          {{ item.testDate }} ({{'FreePlaceCount' | translate}}:{{item.freePlaceCount}})
        </option>
      </select>
    </div>
  </div>
</form>
<div class="mt-4 flex">
  <button (click)="postForm()" class="mx-2 rounded-lg bg-blue-500 hover:bg-blue-700 w-1/2 cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
    {{ 'Edit' | translate }}
  </button>
  <button [routerLink]="'/profile/prob-test/1/2'" class="mx-2 rounded-lg bg-red-500 hover:bg-red-700 w-1/2 cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
    {{ 'Cancel' | translate }}
  </button>
</div>
