import { Component, OnInit, isDevMode } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { KaztestService } from '../kaztest/service/kaztest.service';

declare var halyk: { pay: (arg0: any) => any; };

interface EPayModel{
  invoiceId: number;
  invoiceIdAlt: string;
  backLink:string;
  failureBackLink:string;
  postLink:string;
  failurePostLink:string;
  language:string;
  description:string;
  terminal:string;
  amount:number;
  currency:string;
  email:string;
  phone:string;
  cardSave:boolean;
  auth:any;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  loading = false;
  applicationId: any;
  paymentUrl: string;
  testStatusId: any;
  paymentForm: FormGroup;
  type: any;
  step = 2;
  kaspiOrderId = 0;
  onlineOrderId = 0;

  paymentObject:EPayModel = {
    invoiceId: 0,
    invoiceIdAlt: '',
    backLink: isDevMode() ?  'https://app.debug.testcenter.kz/profile/history' : 'https://app.testcenter.kz/profile/history',
    failureBackLink: isDevMode() ?  'https://app.debug.testcenter.kz/profile/history' : 'https://app.testcenter.kz/profile/history',
    postLink: isDevMode() ? 'https://cabinet.debug.testcenter.kz/api/site/v1/orders/halyk/success' : 'https://cabinet.testcenter.kz/api/site/v1/orders/halyk/success',
    failurePostLink: isDevMode() ? 'https://cabinet.debug.testcenter.kz/api/site/v1/orders/halyk/failure' : 'https://cabinet.testcenter.kz/api/site/v1/orders/halyk/failure',
    language: 'kaz',
    description: '',
    terminal: '75340f88-b951-4386-ab95-e944341763d3',
    amount: 0,
    currency: 'KZT',
    email: '',
    phone: '',
    cardSave: false,
    auth: null
  };

  constructor(
    public api: ApiService,
    private kazTestApi:KaztestService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService
    ) {
      this.paymentObject.language = this.translate.currentLang == 'kz' ? 'kaz' : 'rus';
    }

  ngOnInit(): void {

    this.applicationId = this.route.snapshot.queryParamMap.get('id');

    this.type = +this.route.snapshot.queryParamMap.get('type');
    console.log(this.type);

    switch(this.type){
      case 4:
        this.getDataPvo();
        break;
      case 5:
        this.getDataDir();
        break;
      case 8:
        this.getDataKaztest();
        break;
      case 3:
        this.getDataAppeal();
        break;
      case 2:
        this.getDataCitizen();
        break;
      default:
        this.getDataEnt();
    }


    this.paymentForm = new FormGroup({
      paymentProviderTypeId: new FormControl(),
      returnUrl: new FormControl('https://app.testcenter.kz/profile/history')
    });

  }


  getDataCitizen(){
    this.api.getCitizenShortItems(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if(res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    )
  }

  getDataKaztest(){
    this.kazTestApi.getShort(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if(res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    )
  }

  getDataPvo(){
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if(res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {

      });
  }

  getDataDir(){
    this.api.getDirShort(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if(res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {

      });
  }

  getDataEnt(){
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if(res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {

      });
  }

  getDataAppeal(){
    this.api.appealsShortInfo(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0) {
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        } else {
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1) {
          this.kaspiOrderId = res.data.orderId;
        }
      });
  }

  pay(){
    this.step = 3;
  }

  createOrder(){
    switch(this.type){
      case 4:
      this.createOrderPvo();
        break;
      case 5:
        this.createOrderDir();
        break;
      case 8:
        this.createOrderKaztest();
        break;
      case 3:
        this.createOrderKaztestAppeal();
        break;
      case 2:
        this.citizenOrder();
        break;
      default:
        this.createOrderEnt();
    }
  }

  createOrderKaztest(){
    this.loading = true;
    this.kazTestApi.createOrder(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;

          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }

      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderEnt(){
    this.loading = true;
    this.api.createOrder(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;

          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ЕНТ ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }

      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderPvo(){
    this.loading = true;
    this.api.orderCreatePvo(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;

          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ПВО ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderKaztestAppeal(){
  this.loading = true;
  this.api.orderKaztestAppeal(this.applicationId, this.paymentForm.value)
    .subscribe((res: any) => {
      if(this.paymentForm.get('paymentProviderTypeId').value == 1){
        this.kaspiOrderId = res.data.orderId;
        this.loading = false;
      }
      if(this.paymentForm.get('paymentProviderTypeId').value == 2){
        this.loading = false;
        this.onlineOrderId = res.data.orderId;
        this.paymentUrl = res.data.paymentUrl;

        this.paymentObject.invoiceId = res.data.orderId;
        this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
        this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
        this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
        this.paymentObject.email = res.data.email;
        this.paymentObject.phone = res.data.phoneNumber;
        this.paymentObject.auth = res.data.accessToken;
        halyk.pay(this.paymentObject);
      }
    },(err: any) => {
      this.loading = false;
    });
}

  citizenOrder(){
    this.loading = true;
    this.api.citizenOrderСreate(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;

          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }
  createOrderDir(){
    this.loading = true;
    this.api.orderCreateDir(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if(this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if(this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;

          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  goToPayment(){
    console.log(this.paymentUrl);
    this.router.navigate([this.paymentUrl]);
  }

  getTestStatus(){

    switch(this.type){
      case 4:
        this.getTestStatusPvo();
        break;
      case 5:
        this.getTestStatusDir();
        break;
      case 8:
        this.getTestStatusKaztest();
        break;
      case 2:
        this.getDataCitizen();
        break;
      default:
        this.getTestStatusEnt();
    }
  }

  getTestStatusKaztest(){
    this.loading = true;
    this.kazTestApi.getShort(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {

      });
  }

  getTestStatusPvo(){
    this.loading = true;
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {

      });
  }

  getTestStatusCitizen(){
    this.loading = true;
    this.api.getCitizenShortItems(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {

      });
  }
  getTestStatusDir(){
    this.loading = true;
    this.api.getDirShort(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {

      });
  }

  getTestStatusEnt(){
    this.loading = true;
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {

      });
  }

}
