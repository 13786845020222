import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../service/token.service';
import { authConfig } from '../config/authRoutes';
import { nqtConfig } from '../config/nqtRoutes';
import { entConfig } from '../config/entRoutes';
import { astConfig } from '../config/astRoutes';
import { pvoConfig } from '../config/pvoRoutes';
import { konkursConfig } from '../config/konkursRoutes';
import { konkursPvoConfig } from '../config/konkursPvoRoutes';
import { notificationConfig } from '../config/notificationRoutes';
import { dirConfig } from '../config/dirRoutes';
import { kaztestConfig } from '../config/kaztestRoutes';
import { modoConfig } from '../config/modoRoutes';
import { probtestConfig } from '../config/probtestRoutes';
import { map, tap } from 'rxjs/operators';
import {orleuConfig} from '../config/orleuRoutes';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = environment.kaztestApi;
  urlAppeal = environment.kaztestApiAppeal;
  urlKazTestProb = environment.kaztestApiProb;
  urlPg = environment.kaztestApiPg;
  urlCitizenship = environment.citizenApi;
  urlCitizenProb = environment.citizenProb;
  urlDirProb = environment.dirProb;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private auth_config: authConfig,
    private nqt_config: nqtConfig,
    private ent_config: entConfig,
    private ast_config: astConfig,
    private pvo_config: pvoConfig,
    private message_config: notificationConfig,
    private konkurs_config: konkursConfig,
    private konkursPvo_config: konkursPvoConfig,
    private dir_config: dirConfig,
    private kaztest_config: kaztestConfig,
    private modo_config: modoConfig,
    private probtest_config: probtestConfig,
    private orleu_Config: orleuConfig
  ) { }

  auth(token: any): any {
    return this.http.post(this.auth_config.apiRoutes.auth, token);
  }


  // Получение данных заявления на апелляцию по содержанию тестовых заданий
  contentAppelFileData(questionId: number, predmetId: number, id: number): any {
    return this.http.get(this.ent_config.apiRoutes.listOfItems + "/" + predmetId + "/question/items/" + questionId + "/content-appeal/additional-file/items/" + id, this.tokenService.getHeaderAuth());
  }

  appealsShortInfo(id: any){
    return this.http.get(`${this.urlAppeal}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
  }

  isAlive(){
    return this.http.post(this.auth_config.authBackend + '/is-alive', {}, this.tokenService.getHeaderAuth());
  }

  register(data: any): any {
    return this.http.post(this.auth_config.apiRoutes.register, data);
  }

  logout(){
    return this.http.post(this.auth_config.apiRoutes.logout, {}, this.tokenService.getHeaderAuth())
  }

  registerConfirmation(data: any): any {
    return this.http.post(this.auth_config.apiRoutes.registerConfirmation, data);
  }

  profileInfo(): any {
    return this.http.get(this.auth_config.apiRoutes.profileInfo, this.tokenService.getHeaderAuth());
  }

  updateProfileInfo(): any {
    return this.http.put(`${this.auth_config.authBackend}/personal-data`, {} , this.tokenService.getHeaderAuth())
  }


  personalData(iin: number): any {
    return this.http.get(this.auth_config.apiRoutes.getPersonalData + '/' + iin, this.tokenService.getHeaderAuth())
  }

  editProfileInfo(data: any): any {
    return this.http.put(this.auth_config.apiRoutes.profileInfo, data, this.tokenService.getHeaderAuth())
  }

  resetPassword(data: any): any {
    return this.http.post(this.auth_config.apiRoutes.resetPassword, data);
  }

  resetPasswordConfirmation(data: any): any {
    return this.http.post(this.auth_config.apiRoutes.resetPasswordConfirmation, data);
  }

  changePassword(passData): any {
    return this.http.put(this.auth_config.apiRoutes.changePassword, passData, this.tokenService.getHeaderAuth())
  }

  changeEmail(emailData): any {
    return this.http.post(this.auth_config.apiRoutes.changeEmail, emailData, this.tokenService.getHeaderAuth())
  }

  changeEmailConfirmation(data: any): any {
    return this.http.post(this.auth_config.apiRoutes.changeEmailConfirmation, data, this.tokenService.getHeaderAuth())
  }

  getNationList(): any {
    return this.http.get(this.auth_config.apiRoutes.nation, this.tokenService.getHeaderAuth())
  }

  getCitizenshipList(): any {
    return this.http.get(this.auth_config.apiRoutes.citizenship, this.tokenService.getHeaderAuth())
  }

  getSexList(): any {
    return this.http.get(this.auth_config.apiRoutes.sex, this.tokenService.getHeaderAuth())
  }

  getFromListOfActualCategories(jobTypeId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/category/from/items', this.tokenService.getHeaderAuth());
  }

  getToListOfActualCategories(jobTypeId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/category/to/items', this.tokenService.getHeaderAuth());
  }

  getKATOList(jobTypeId: number, id: number, sort: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/kato/items/' + id + '/children?sort=' + sort, this.tokenService.getHeaderAuth());
  }

  getJobList(jobTypeId: number, id: number, sort: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/kato/items/' + id + '/job/items?sort=' + sort, this.tokenService.getHeaderAuth());
  }

  getKATOList2(id: number, sort: number): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/kato/items/' + id + '/children?sort=' + sort, this.tokenService.getHeaderAuth());
  }

  getJobList2(id: number, sort: number): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/kato/items/' + id + '/job/items?sort=' + sort, this.tokenService.getHeaderAuth());
  }

  getsubjectCount(jobTypeId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + "/" + jobTypeId + '/employment-type/items/' + employmentTypeId + '/subject/ord/count', this.tokenService.getHeaderAuth());
  }

  getSubjectItems(jobTypeId: number, subjectOrd: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + "/" + jobTypeId + '/employment-type/items/' + employmentTypeId + '/subject/ord/items/' + subjectOrd + '/subject/items', this.tokenService.getHeaderAuth());
  }

  getSubjectLang(jobTypeId: number, subjectId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + "/" + jobTypeId + '/employment-type/items/' + employmentTypeId + '/subject/items/' + subjectId + '/lang/items', this.tokenService.getHeaderAuth());
  }

  getSubjectLangDir(eduTypeId: any): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/edu-type/items/' + eduTypeId + '/lang/items', this.tokenService.getHeaderAuth());
  }

  getTipoSubjectLang(jobTypeId: number, eduTypeId: number, specialityId: number, subjectOrd: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + "/" + jobTypeId + '/employment-type/items/' + employmentTypeId + '/edu-type/items/' + eduTypeId + '/speciality/items/' + specialityId + '/subject/items/' + subjectOrd + '/lang/items', this.tokenService.getHeaderAuth());
  }

  postNqtSoForm(data: any): any {
    return this.http.post(this.nqt_config.apiRoutes.postNqtSoForm, data, this.tokenService.getHeaderAuth());
  }

  postNqtSoFormDir(data: any): any {
    return this.http.post(this.nqt_config.dirBackend + '/app/items', data, this.tokenService.getHeaderAuth());
  }

  postNqtSoFormDirEdit(data: any, appId: any): any {
    return this.http.put(this.nqt_config.dirBackend + '/app/items/' + appId, data, this.tokenService.getHeaderAuth());
  }

  testOrgList(jobTypeId: number, employmentTypeId: any = 0, oblId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/obl/items/' + oblId + '/test-org/items', this.tokenService.getHeaderAuth());
  }

  testOrgListDir(oblId: any = 0): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/obl/items/' + oblId + '/test-org/items', this.tokenService.getHeaderAuth());
  }

  getOblList(jobTypeId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/obl/items', this.tokenService.getHeaderAuth());
  }

  getOblListDir(): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/obl/items', this.tokenService.getHeaderAuth());
  }

  getDirShort(id: any) {
    return this.http.get(this.nqt_config.dirBackend + '/app/items/' + id + '/short', this.tokenService.getHeaderAuth());
  }

  getRaionList(jobTypeId: number, employmentTypeId: any = 0, oblId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/obl/items/' + oblId + '/raion/items', this.tokenService.getHeaderAuth());
  }

  getVuzList(jobTypeId: number, employmentTypeId: any = 0, oblId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/obl/items/' + oblId + '/institute/items', this.tokenService.getHeaderAuth());
  }

  citizenship(testTypeId: any){
    return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/citizenship/items`, this.tokenService.getHeaderAuth());
  }

getAppCitizenshort(id: any){
  return this.http.get(`${this.urlCitizenship}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
}

  downloadFile(id: any){
    return this.http.get(`${this.urlCitizenship}/app/items/${id}/disability-doc-photo`, {responseType: 'blob', headers: this.tokenService.getTok()});
  }

appItemCitizen(id: any, body: any){
  return this.http.put(`${this.urlCitizenship}/app/items/${id}`, body, this.tokenService.getHeaderAuth());
}

getNation(testTypeId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/nation/items`, this.tokenService.getHeaderAuth());
}

getLang(testTypeId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/lang/items`, this.tokenService.getHeaderAuth());
}

getObl(testTypeId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/obl/items`, this.tokenService.getHeaderAuth());
}

getTestOrg(testTypeId: any, oblId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/obl/items/${oblId}/test-org/items`, this.tokenService.getHeaderAuth());
}

getTestPeriod(testTypeId: any, testOrgId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/test-org/items/${testOrgId}/test-period/items`, this.tokenService.getHeaderAuth());
}

disability(testTypeId: any){
  return this.http.get(`${this.urlCitizenship}/app/test-type/items/${testTypeId}/disability/items`, this.tokenService.getHeaderAuth());
}



  getCollegeList(jobTypeId: number, employmentTypeId: any = 0, oblId: any = 0, raionId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/obl/items/' + oblId + '/raion/items/' + raionId + '/college/items', this.tokenService.getHeaderAuth());
  }

  getDirEduType(): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/edu-type/items/', this.tokenService.getHeaderAuth());
  }

  getInstituteSpeciality(jobTypeId: any): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/institute-speciality/items', this.tokenService.getHeaderAuth());
  }

  getCollegeSpeciality(jobTypeId: any): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/college-speciality-for-graduates/items', this.tokenService.getHeaderAuth());
  }

  getEmploymentTypeId(jobTypeId: any): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items', this.tokenService.getHeaderAuth());
  }

  testOrgListByObl(jobTypeId: number, oblId: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/obl/items/' + oblId + '/test-org/items', this.tokenService.getHeaderAuth());
  }

  testPeriodList(jobTypeId: number, testOrgId: number, studentTestId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/test-org/items/' + testOrgId + '/test-period/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  testPeriodListDir(testOrgId: number, studentTestId: number): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/test-org/items/' + testOrgId + '/test-period/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  getSpecialityItems(jobTypeId: number, eduTypeId: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/edu-type/items/' + eduTypeId + '/speciality/items', this.tokenService.getHeaderAuth());
  }

  getTipoSoSubjectsItems(jobTypeId: number, eduTypeId: number, specialityId: number, subjectOrd: number, sort: number, employmentTypeId: any = 0): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/edu-type/items/' + eduTypeId + '/speciality/items/' + specialityId + '/subject/ord/items/' + subjectOrd + '/subject/items?sort=' + sort, this.tokenService.getHeaderAuth());
  }

  searchJobByBin(bin: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobSearch + '?bin=' + bin, this.tokenService.getHeaderAuth());
  }

  searchJobByBin2(bin: number): any {
    return this.http.get(this.nqt_config.dirBackend + '/job/search' + '?bin=' + bin, this.tokenService.getHeaderAuth());
  }

  saveNqtEsse(value: any, studentTestId: any, textAnswerId: any) {
    return this.http.put(this.nqt_config.nqtBackend + '/app/items/' + studentTestId + '/text-answer/items/' + textAnswerId, value, this.tokenService.getHeaderAuth());
  }

  // https://app.debug.testcenter.kz/nqt/student/api/v1/app/items/454629/text-answer/items/3647

  getApplicationList(): any {
    return this.http.get(this.nqt_config.apiRoutes.postNqtSoForm, this.tokenService.getHeaderAuth());
  }

  getApplicationListDir(): any {
    return this.http.get(this.nqt_config.dirBackend + '/app/items', this.tokenService.getHeaderAuth());
  }

  getApplicationListEnt(): any {
    return this.http.get(this.ent_config.apiRoutes.appItems, this.tokenService.getHeaderAuth());
  }

  getHeaderTypeList(employmentTypeId: number, jobTypeId: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/header-type/items', this.tokenService.getHeaderAuth());
  }

  getHeaderLang(jobTypeId: number, employmentTypeId: any = 0, headerTypeId: number): any {
    return this.http.get(this.nqt_config.apiRoutes.jobType + "/" + jobTypeId + '/employment-type/items/' + employmentTypeId + '/header-type/items/' + headerTypeId + '/lang/items', this.tokenService.getHeaderAuth());
  }

  deleteApplicationById(id): any {
    return this.http.delete(this.nqt_config.apiRoutes.postNqtSoForm + '/' + id, this.tokenService.getHeaderAuth());
  }

  deleteApplicationByIdEnt(id): any {
    return this.http.delete(this.ent_config.apiRoutes.appItems + '/' + id, this.tokenService.getHeaderAuth());
  }

  editApplicationById(id, data): any {
    return this.http.put(this.nqt_config.apiRoutes.postNqtSoForm + '/' + id, data, this.tokenService.getHeaderAuth());
  }

  getApplicationInformation(id: any) {
    return this.http.get(this.nqt_config.apiRoutes.postNqtSoForm + '/' + id + '/short', this.tokenService.getHeaderAuth());
  }

  getApplicationInformationEnt(id: any) {
    return this.http.get(this.ent_config.apiRoutes.appItems + '/' + id + '/short', this.tokenService.getHeaderAuth());
  }

  oblList(jobTypeId: any) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/obl/items', this.tokenService.getHeaderAuth());
  }

  eduInstitute(jobTypeId: any, oblId: any) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/obl/items/' + oblId + '/institute/items', this.tokenService.getHeaderAuth());
  }

  eduTipoCollege(jobTypeId: any, oblId: any, raionId: any) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/obl/items/' + oblId + '/raion/items/' + raionId + '/college/items', this.tokenService.getHeaderAuth());
  }

  getEsseAnswer(id: any) {
    return this.http.get(this.nqt_config.nqtBackend + '/app/items/' + id + '/text-answer/items', this.tokenService.getHeaderAuth());
  }

  raionList(jobTypeId: any, oblId: any) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/obl/items/' + oblId + '/raion/items', this.tokenService.getHeaderAuth());
  }

  instituteSpeciality(jobTypeId: any, employmentTypeId: any = 0) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/employment-type/items/' + employmentTypeId + '/institute-speciality/items', this.tokenService.getHeaderAuth());
  }

  instituteTipoSpeciality(jobTypeId: any) {
    return this.http.get(this.nqt_config.apiRoutes.jobType + '/' + jobTypeId + '/college-speciality-for-graduates/items', this.tokenService.getHeaderAuth());
  }

  getSeasonsEnt() {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems, this.tokenService.getHeaderAuth());
  }

  getAppTypeEnt(seasonId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + '/app-type/items', this.tokenService.getHeaderAuth());
  }

  getAppTypeNqt() {
    return this.http.get(this.nqt_config.apiRoutes.entryPoint, this.tokenService.getHeaderAuth());
  }

  getAppTypeDir() {
    return this.http.get(this.nqt_config.dirBackend + '/entry-point/items', this.tokenService.getHeaderAuth());
  }

  getSeasonTypeDir() {
    return this.http.get(this.nqt_config.dirBackend + '/season/items', this.tokenService.getHeaderAuth());
  }

  // getCountryEnt(seasonId: number, appTypeId: number){
  //   return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/country/items', this.tokenService.getHeaderAuth());
  // }

  getCountryEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/country/items', this.tokenService.getHeaderAuth());
  }

  getDopSertifs(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/another-exam-type/items', this.tokenService.getHeaderAuth());
  }

  getEduCountryEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/edu-country/items', this.tokenService.getHeaderAuth());
  }

  getNationsEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/nation/items', this.tokenService.getHeaderAuth());
  }

  getSexEnt(id: any) {
    return this.http.get(this.ent_config.apiRoutes.appTestType + id + '/sex/items', this.tokenService.getHeaderAuth());
  }

  getStatTheme(id: any) {
    return this.http.get(this.ent_config.apiRoutes.appItems + '/' + id + '/stat/test-result/theme', this.tokenService.getHeaderAuth());
  }

  getForeignLangCertEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/foreign-lang-cert/items', this.tokenService.getHeaderAuth());
  }

  getOblEnt(seasonId: number, appTypeId: number, studentTestId: number = 0) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/obl/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  getRaionEnt(seasonId: number, appTypeId: number, oblId: any, studentTestId: number = 0) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/obl/items/' + oblId + '/raion/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  getVuzEnt(seasonId: number, appTypeId: number, oblId: any) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/obl/items/' + oblId + '/edu-institute/items', this.tokenService.getHeaderAuth());
  }

  collegeSpeciality(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/college-speciality/items', this.tokenService.getHeaderNotAuth());
  }

  eduProgram(seasonId: number, appTypeId: number, collegeSpecialityId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/college-speciality/items/' + collegeSpecialityId + '/edu-program/items', this.tokenService.getHeaderNotAuth());
  }

  getSchoolEnt(seasonId: number, appTypeId: number, oblId: any, raionId: any, studentTestId: number = 0) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/obl/items/' + oblId + '/raion/items/' + raionId + '/school/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  getEduInfoEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/edu-info', this.tokenService.getHeaderAuth());
  }

  getProfileSubjectEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/profile-subject-pair/items', this.tokenService.getHeaderAuth());
  }

  getProfileSubjectEntTvor(seasonId: number, appTypeId: number, eduProgramId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/edu-program/items/' + eduProgramId + '/profile-subject-pair/items');
  }

  getProfileSubjectTipoEnt(seasonId: number, appTypeId: number, eduProgramId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/edu-program/items/' + eduProgramId + '/subject/items');
  }

  getLangEnt(seasonId: number, appTypeId: number, profileSubjectPairId: any) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/profile-subject-pair/items/' + profileSubjectPairId + '/lang/items');
  }

  getTipoLangEnt(seasonId: number, appTypeId: number, eduProgramId: number, subjectGroupId: any) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/edu-program/items/' + eduProgramId + '/subject/items/' + subjectGroupId + '/lang/items');
  }

  getTestOrgEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/test-org/items', this.tokenService.getHeaderAuth());
  }

  getTestOblEnt(seasonId: number, appTypeId: number, oblId: any, studentTestId: any) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + this.ent_config.apiRoutes.appType + appTypeId + '/obl/items/' + oblId + '/test-org/items?student-test-id=' + studentTestId, this.tokenService.getHeaderAuth());
  }

  getTestOrgItemsEnt(seasonId: number, appTypeId: number, testOrgId: any, studentTestId: any, isEmergencySchool = false) {
    return this.http.get(`${this.ent_config.apiRoutes.appSeasonItems}/${seasonId}${this.ent_config.apiRoutes.appType}${appTypeId}/test-org/items/${testOrgId}/test-period/items?student-test-id=${studentTestId}&is-emergency-school=${isEmergencySchool}`, this.tokenService.getHeaderAuth());
  }

  checkCertificatecheck(id: any, iin: any, year: any, testType: any) {
    return this.http.get(this.dir_config.apiRoutes.certUrl + '/certificate/test/is-exists/test-type/' + testType + '/year/' + year + '/student/' + id + '/iin/' + iin);
  }

  checkGrantcheck(id: any, iin: any, year: any, testType: any) {
    return this.http.get(this.dir_config.apiRoutes.certUrl + '/certificate/grant/is-exists/test-type/' + testType + '/year/' + year + '/student/' + id + '/iin/' + iin);
  }
  showedDate(id: any) {
    return this.http.put(this.pvo_config.apiRoutes.getAppItems + '/' + id + '/test-location/set-as-showed', {}, this.tokenService.getHeaderAuth())
  }

  downloadCertificate(id: any, iin: any, year: any, testType: any) {
    return this.http.get(this.dir_config.apiRoutes.certUrl + '/certificate/test/download-link/test-type/' + testType + '/year/' + year + '/student/' + id + '/iin/' + iin);
  }

  downloadGrant(id: any, iin: any, year: any, testType: any) {
    return this.http.get(this.dir_config.apiRoutes.certUrl + '/certificate/grant/download-link/test-type/' + testType + '/year/' + year + '/student/' + id + '/iin/' + iin);
  }

  postApplicationEnt(data: any) {
    return this.http.post(this.ent_config.apiRoutes.appItems, data, this.tokenService.getHeaderAuth());
  }

  postEditApplicationEnt(data: any, studentTestId: any) {
    return this.http.put(this.ent_config.apiRoutes.appItems + '/' + studentTestId, data, this.tokenService.getHeaderAuth());
  }

  getTestInfo(token: any) {
    return this.http.get(this.ent_config.apiRoutes.appItems + '/test-data', this.tokenService.getOnlineTestHeaderAuth(token));
  }

  // Dir Start

  getTestInfoDir(token: any) {
    return this.http.get(this.dir_config.apiRoutes.appItems, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  getEduTypes() {
    return this.http.get(this.dir_config.apiRoutes.eduItems);
  }

  getLanguages(eduTypeId: any) {
    return this.http.get(this.dir_config.apiRoutes.eduItems + '/' + eduTypeId + '/lang/items');
  }

  putTestInfoDir(data: any, token: any) {
    return this.http.put(this.dir_config.apiRoutes.appItems, data, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  //Dir End

  // orleu Start

  getTestInfoOrleu(token: any) {
    return this.http.get(this.orleu_Config.apiRoutes.appItems, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  getEduTypesOrleu(eduTypeId: any) {
    return this.http.get(`${this.orleu_Config.backend}/app/edu-type/items/${eduTypeId}/subject/items`);
  }

  getLanguageOrleu(eduTypeId: any, subjectGroupId: any) {
    return this.http.get(`${this.orleu_Config.backend}/app/edu-type/items/${eduTypeId}/subject/items/${subjectGroupId}/lang/items`);
  }

  putTestInfoOrleu(data: any, token: any) {
    return this.http.put(this.orleu_Config.apiRoutes.appItems, data, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  //orleu End

  //Modo

  getClassNum() {
    return this.http.get(this.modo_config.apiRoutes.appItems + '/class-num/items');
  }

  getClassNumLang(classNumId: number) {
    return this.http.get(this.modo_config.apiRoutes.appItems + '/class-num/items/' + classNumId + '/lang/items');
  }

  getModoTestData(token:any){
    return this.http.get(this.modo_config.apiRoutes.appItems, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  putModoTestData(data:any, token:any){
    return this.http.put(this.modo_config.apiRoutes.appItems, data, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  //Modo End

  putTestInfo(data: any, token: any) {
    return this.http.put(this.ent_config.apiRoutes.appItems + '/test-data', data, this.tokenService.getOnlineTestHeaderAuth(token));
  }

  createOrder(studentTestId: any, data: any) {
    return this.http.post(this.ent_config.apiRoutes.appItems + '/' + studentTestId + '/order/create', data, this.tokenService.getHeaderAuth());
  }

  // AST
  getAstAppItemsList() {
    return this.http.get(this.ast_config.astRoutes.getAppList, this.tokenService.getHeaderAuth());
  }

  getAstAppTypeItems() {
    return this.http.get(this.ast_config.astRoutes.getTypesSertif, this.tokenService.getHeaderAuth());
  }

  getAstProfileSubjectPair() {
    return this.http.get(this.ast_config.astRoutes.getProfilePair, this.tokenService.getHeaderAuth());
  }

  getAstAppForeignLangCert() {
    return this.http.get(this.ast_config.astRoutes.getForeignLangCert, this.tokenService.getHeaderAuth());
  }

  getAstAppItems() {
    return this.http.get(this.ast_config.astRoutes.appAstItems, this.tokenService.getHeaderAuth());
  }

  postAstApp(data: any) {
    return this.http.post(this.ast_config.astRoutes.appAstItems, data, this.tokenService.getHeaderAuth());
  }

  postIbApp(data: any) {
    return this.http.post(this.ast_config.ibRoutes.addib, data, this.tokenService.getHeaderAuth());
  }

  putIbApp(data: any, id: any) {
    return this.http.put(this.ast_config.ibRoutes.addib + '/' + id, data, this.tokenService.getHeaderAuth());
  }

  putAstApp(data: any, id) {
    return this.http.put(this.ast_config.astRoutes.appAstItems + '/' + id, data, this.tokenService.getHeaderAuth());
  }

  deleteApplicationByIdCert(id): any {
    return this.http.delete(this.ast_config.astRoutes.getAppList + '/' + id, this.tokenService.getHeaderAuth());
  }

  getShortInfoAst(id: any) {
    return this.http.get(this.ast_config.astRoutes.appAstItems + '/' + id + '/short', this.tokenService.getHeaderAuth())
  }

  // SAT

  getSatAppTypeItems() {
    return this.http.get(this.ast_config.satRoutes.getTypesSertif, this.tokenService.getHeaderAuth());
  }

  getSatProfileSubject(profileSubjectPairId: any) {
    return this.http.get(this.ast_config.certifBackend + '/another-exam-certificate/sat/subject/items' + '?profile-subject-pair-id=' + profileSubjectPairId, this.tokenService.getHeaderAuth());
  }

  getCertEntProfileSubject() {
    return this.http.get(this.ast_config.satRoutes.profileSubjectPair, this.tokenService.getHeaderAuth());
  }

  getCertEntIbProfileSubject() {
    return this.http.get(this.ast_config.ibRoutes.profileSubjectPair, this.tokenService.getHeaderAuth());
  }

  getSatAppForeignLangCert() {
    return this.http.get(this.ast_config.satRoutes.getForeignLangCert, this.tokenService.getHeaderAuth());
  }

  getIbAppForeignLangCert() {
    return this.http.get(this.ast_config.ibRoutes.ibforeign, this.tokenService.getHeaderAuth());
  }

  getShortInfoIb(id: any) {
    return this.http.get(this.ast_config.ibRoutes.addib + '/' + id + '/short', this.tokenService.getHeaderAuth())
  }

  getMathSubjects() {
    return this.http.get(this.ast_config.certifBackend + '/another-exam-certificate/ib/subject/math/items', this.tokenService.getHeaderAuth());
  }

  getALevelMathSubjects() {
    return this.http.get(this.ast_config.certifBackend + '/another-exam-certificate/a-level/subject/math/items', this.tokenService.getHeaderAuth());
  }

  getIbSubjects(profileSubjectPairId: any) {
    return this.http.get(this.ast_config.certifBackend + '/another-exam-certificate/ib/subject/profile/items' + '?profile-subject-pair-id=' + profileSubjectPairId, this.tokenService.getHeaderAuth());
  }

  getALevelSubjects(profileSubjectPairId: any) {
    return this.http.get(this.ast_config.certifBackend + '/another-exam-certificate/a-level/subject/profile/items' + '?profile-subject-pair-id=' + profileSubjectPairId, this.tokenService.getHeaderAuth());
  }

  getSatAppItems() {
    return this.http.get(this.ast_config.satRoutes.appSatItems, this.tokenService.getHeaderAuth());
  }

  postSatApp(data: any) {
    return this.http.post(this.ast_config.satRoutes.appSatItems, data, this.tokenService.getHeaderAuth());
  }

  putSatApp(data: any, id) {
    return this.http.put(this.ast_config.satRoutes.appSatItems + '/' + id, data, this.tokenService.getHeaderAuth());
  }

  getShortInfoSat(id: any) {
    return this.http.get(this.ast_config.satRoutes.appSatItems + '/' + id + '/short', this.tokenService.getHeaderAuth());
  }

  // PVO

  getSeasonsPvo() {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems, this.tokenService.getHeaderAuth());
  }

  getAppTypePvo(seasonId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items', this.tokenService.getHeaderAuth());
  }

  getCountryPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/country/items', this.tokenService.getHeaderAuth());
  }

  getMasterType(seasonId: number, appTypeId: number){
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items', this.tokenService.getHeaderAuth())
  }

  // /app/season/items/{seasonId}/app-type/items/{appTypeId}/master-type/items

  getNationPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/nation/items', this.tokenService.getHeaderAuth());
  }

  getForeignLangCertPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/foreign-lang-cert/items', this.tokenService.getHeaderAuth()).pipe(map((e:any)=> {

      const modifiedData:any = e.items.map((item:any)=>{
        if(!item.isAdditionalExamRequired){
          return { ...item, grantMessages: {
            kk: '* TOEFL ITP сертификатының иегерлері "ағылшын тілін білуге арналған қосымша тестілеуге" қатысуға өтініш беруі керек',
            ru: '* Обладателям сертификата TOEFL ITP необходимо также подать заявление на участие в "Дополнительном тестировании на знание английского языка"'
          }}
        }
        return item;
      })

      const response = {
        items: modifiedData
      }

      return response;
    }));

  }

  getEduInstituteOblPvo(seasonId: number, appTypeId: number, eduProgramId: number, masterTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items/' + masterTypeId + '/edu-program/items/' + eduProgramId + '/edu-institute-obl/items', this.tokenService.getHeaderAuth());
  }

  getInstitutePvo(seasonId: number, appTypeId: number, oblId: number, eduProgramId: number, masterTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items/' + masterTypeId + '/edu-program/items/' + eduProgramId + '/edu-institute-obl/items/' + oblId + '/institute/items', this.tokenService.getHeaderAuth()).pipe(map((e:any)=> {

      const modifiedData:any = e.items.map((item:any)=>{
        if(!item.isGrantExists){
          return { ...item, grantMessage: {
            kk: '* Білім беру бағдарламаларының осы тобы бойынша мемлекеттік білім беру гранты бөлінбеген.',
            ru: '* По данной группе образовательных программ государственный образовательный грант не выделен.'
          }}
        }
        return item;
      })

      const response = {
        items: modifiedData
      }

      return response;
    }));
  }

  getStudyTypePvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/study-type/items', this.tokenService.getHeaderAuth());
  }

  getMasterTypePvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items', this.tokenService.getHeaderAuth());
  }

  getEduInstuteOblPvo(seasonId:number, masterTypeId:number, eduProgramId:number, appTypeId:number){
    return this.http.get(`${this.pvo_config.apiRoutes.getAppSeasonItems}/${seasonId}/app-type/items/${appTypeId}/master-type/items/${masterTypeId}/edu-program/items/${eduProgramId}/edu-institute-obl/items`, this.tokenService.getHeaderAuth());
  }

  getEduInstutePvo(seasonId:number, masterTypeId:number, eduProgramId:number, appTypeId:number, oblId:number){
    return this.http.get(`${this.pvo_config.apiRoutes.getAppSeasonItems}/${seasonId}/app-type/items/${appTypeId}/master-type/items/${masterTypeId}/edu-program/items/${eduProgramId}/edu-institute-obl/items/${oblId}/institute/items`, this.tokenService.getHeaderAuth());
  }

  getEduAreaPvo(seasonId: number, appTypeId: number, masterTypeId: number, prevEduDirectionId: any) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items/' + masterTypeId + '/edu-area/items', this.tokenService.getHeaderAuth());
  }

  getEduAreaEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/edu-area/items', this.tokenService.getHeaderAuth());
  }

  getGopPvo(seasonId: number, appTypeId: number, eduAreaId: number, masterTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items/' + masterTypeId + '/edu-area/items/' + eduAreaId + '/edu-program/items', this.tokenService.getHeaderAuth()).pipe(map((e:any)=> {

      const modifiedData:any = e.items.map((item:any)=>{
        if(!item.isGrantExists){
          return { ...item, grantMessage: {
            kk: '* Білім беру бағдарламаларының осы тобы бойынша мемлекеттік білім беру гранты бөлінбеген.',
            ru: '* По данной группе образовательных программ государственный образовательный грант не выделен.'
          }}
        }
        return item;
      })

      const response = {
        items: modifiedData
      }

      return response;
    }));
  }

  getTestLangPvo(seasonId: number, appTypeId: number, eduProgramId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/edu-program/items/' + eduProgramId + '/test-lang/items', this.tokenService.getHeaderAuth());
  }

  getTestLangPhd(seasonId: number, appTypeId: number, masterTypeId: number, eduProgramId: number, instituteId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/master-type/items/' + masterTypeId + '/edu-program/items/' + eduProgramId + '/institute/items/' + instituteId + '/test-lang/items', this.tokenService.getHeaderAuth());
  }

  getForeignLangPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/foreign-lang/items', this.tokenService.getHeaderAuth());
  }

  getTgoLangPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/tgo-lang/items', this.tokenService.getHeaderAuth());
  }

  getProfileSubjectPvo(seasonId: number, appTypeId: number, eduProgramId: number, testLangId: number, ord: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/edu-program/items/' + eduProgramId + '/test-lang/items/' + testLangId + '/profile-subject/by-ord/' + ord + '/items', this.tokenService.getHeaderAuth());
  }

  getCityPvo(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/test-city/items', this.tokenService.getHeaderAuth());
  }

  getPrevEduArea(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/prev-edu-area/items', this.tokenService.getHeaderAuth());
  }

  getPrevEduDirection(seasonId: number, appTypeId: number, prevEduAreaId: any) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/prev-edu-area/items/' + prevEduAreaId + '/prev-edu-direction/items', this.tokenService.getHeaderAuth());
  }

  getDisability(seasonId: number, appTypeId: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/disability/items', this.tokenService.getHeaderAuth());
  }

  getDisabilityEnt(seasonId: number, appTypeId: number) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/disability/items', this.tokenService.getHeaderAuth());
  }

  getDisabilityNqt(jobTypeId: number, employmentTypeId: number) {
    return this.http.get(`${this.nqt_config.apiRoutes.jobType}/${jobTypeId}/employment-type/items/${employmentTypeId}/disability/items`, this.tokenService.getHeaderAuth());
  }

  getShortInfoPvo(id: number) {
    return this.http.get(this.pvo_config.apiRoutes.getAppItems + '/' + id + '/short', this.tokenService.getHeaderAuth());
  }

  paymentCitizenApp(studentTestId: any){
    return this.http.post(`${this.urlCitizenship}/app/items/${studentTestId}/order/create`, this.tokenService.getHeaderAuth());
  }

  getAppItemsPvo() {
    return this.http.get(this.pvo_config.apiRoutes.getAppItems, this.tokenService.getHeaderAuth());
  }

  postAppItemsPvo(appValue: any) {
    return this.http.post(this.pvo_config.apiRoutes.getAppItems, appValue, this.tokenService.getHeaderAuth());
  }

  putAppItemsPvo(studentTestId: number, appValue: any) {
    return this.http.put(this.pvo_config.apiRoutes.getAppItems + '/' + studentTestId, appValue, this.tokenService.getHeaderAuth());
  }

  deleteAppItemsPvo(studentTestId: number) {
    return this.http.delete(this.pvo_config.apiRoutes.getAppItems + '/' + studentTestId, this.tokenService.getHeaderAuth());
  }

  deleteAppItemsDir(studentTestId: number) {
    return this.http.delete(this.nqt_config.dirBackend + '/app/items/' + studentTestId, this.tokenService.getHeaderAuth());
  }

  orderCreatePvo(studentTestId: number, value: any) {
    return this.http.post(this.pvo_config.apiRoutes.getAppItems + '/' + studentTestId + '/order/create', value, this.tokenService.getHeaderAuth());
  }

  orderCreateDir(studentTestId: number, value: any) {
    return this.http.post(this.nqt_config.dirBackend + '/app/items/' + studentTestId + '/order/create', value, this.tokenService.getHeaderAuth());
  }

  getKonkursResults() {
    return this.http.get(this.konkurs_config.apiRoutes.getAppItems, this.tokenService.getHeaderAuth());
  }

  getKonkursPvoResults() {
    return this.http.get(this.konkursPvo_config.apiRoutes.getAppItems, this.tokenService.getHeaderAuth());
  }

  getGopForVuzSpr(seasonId: number, appTypeId: number, eduAreaId = 1, withCreative: boolean) {
    return this.http.get(this.ent_config.apiRoutes.appSeasonItems + '/' + seasonId + '/app-type/items/' + appTypeId + '/edu-area/items/' + eduAreaId + '/edu-program/items?withCreative=' + withCreative, this.tokenService.getHeaderAuth());
  }

  getNewMessages() {
    return this.http.get(this.message_config.apiRoutes.newMessages, this.tokenService.getHeaderAuth());
  }

  getApplicationListKaztest() {
    return this.http.get(`${this.kaztest_config.backend}/test-result/items`, this.tokenService.getHeaderAuth());
  }


  getEntTestTries(token:string){
    return this.http.get(`${this.probtest_config.backendEnt}/payment/test-tries?token=${token}`);
  }

  getKazTestTries(token:string){
    return this.http.get(`${this.urlKazTestProb}/payment/test-tries?token=${token}`);
  }

  getCitizenTries(token:string){
    return this.http.get(`${this.urlCitizenProb}/payment/test-tries?token=${token}`);
  }

  getDirTries(token:string){
    return this.http.get(`${this.urlDirProb}/payment/test-tries?token=${token}`);
  }

  getPvoTestTries(token:string){
    return this.http.get(`${this.probtest_config.backendPvo}/payment/test-tries?token=${token}`);
  }

  getNqtTestTries(token:string){
    return this.http.get(`${this.probtest_config.backendNqt}/payment/test-tries?token=${token}`);
  }

  createOrderProbEnt(token:string, body:any){
    return this.http.post(`${this.probtest_config.backendEnt}/payment/order?token=${token}`, body);
  }

  createOrderProbKazTest(token:string, body:any){
    return this.http.post(`${this.urlKazTestProb}/payment/order?token=${token}`, body);
  }

  createOrderProbCitizen(token:string, body:any){
    return this.http.post(`${this.urlCitizenProb}/payment/order?token=${token}`, body);
  }

  createOrderProbDir(token:string, body:any){
    return this.http.post(`${this.urlDirProb}/payment/order?token=${token}`, body);
  }

  createOrderProbPvo(token:string, body:any){
    return this.http.post(`${this.probtest_config.backendPvo}/payment/order?token=${token}`, body);
  }

  createOrderProbNqt(token:string, body:any){
    return this.http.post(`${this.probtest_config.backendNqt}/payment/order?token=${token}`, body);
  }

  getKaztestCertificate(seasonId:number, appTypeId:number){
    return this.http.get(`${this.pvo_config.apiRoutes.getAppSeasonItems}/${seasonId}/app-type/items/${appTypeId}/kaztest-certificate/items`, this.tokenService.getHeaderAuth());
  }

  getProbTestTypes(){
    return this.http.get(`${this.probtest_config.backend}/test-tries/test-type-group/items`);
  }

  cancelAppealEnt(id:number): any {
    return this.http.post(`${this.ent_config.apiRoutes.appItems}/${id}/appeal/cancel`, {}, this.tokenService.getHeaderAuth());
  }

  setNoAppeal(studentTestId: any){
    return this.http.post(`${this.url}/app/items/${studentTestId}/set-no-appeal`, {}, this.tokenService.getHeaderAuth());
  }

  orderKaztestAppeal(appealAppId: any, value: any){
    return this.http.post(`${this.urlAppeal}/app/items/${appealAppId}/order/create`, value, this.tokenService.getHeaderAuth());
  }

  deleteAppAppeal(id: any){
    return this.http.delete(`${this.urlAppeal}/app/items/${id}`, this.tokenService.getHeaderAuth());
  }

  getApplicationListKaztestPg() {
    return this.http.get(`${this.urlPg}/test-result/items`, this.tokenService.getHeaderAuth());
  }

  getAppCitizen(data: any){
  return this.http.post(`${this.urlCitizenship}/app/items`, data, this.tokenService.getHeaderAuth());
}

  getCitizenItems(){
    return this.http.get(`${this.urlCitizenship}/app/items`, this.tokenService.getHeaderAuth());
  }

  getCitizenShortItems(id: any){
    return this.http.get(`${this.urlCitizenship}/app/items/${id}/short`, this.tokenService.getHeaderAuth());
  }

deleteCitizenApp(id: any){
  return this.http.delete(`${this.urlCitizenship}/app/items/${id}`, this.tokenService.getHeaderAuth());
}

citizenOrderСreate(studentTestId: any, value: any){
  return this.http.post(`${this.urlCitizenship}/app/items/${studentTestId}/order/create`, value, this.tokenService.getHeaderAuth());

}



}
