<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
    {{ 'Editing' | translate }}
</p>
<form [formGroup]="applicationForm" action="">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex">
        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex  rounded-mdp-4">
                {{ 'IIN' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ iin }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Surname' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ lastname }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Name' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ firstname }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Patronymic' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ patronymic }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'YourEmail' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ email }}
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'DateOfBirth' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input disabled class="w-full pl-1 bg-gray-200 rounded" type="date" formControlName="birthDate"
                    (change)="getDateOfBirth($event.target.value)">
            </div>

            <div class="flex  rounded-mdp-4">
                {{ 'Citizenship' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="citizenshipId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateСitizenship' | translate }}
                    </option>
                    <option [disabled]="!canEditCitizenshipId" *ngFor="let item of countryList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Nationality' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="nationId" class="w-full pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'IndicateNationality' | translate }}
                    </option>
                    <option [disabled]="!canEditNationId" *ngFor="let item of nationsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
          <div class="flex  rounded-mdp-4" *ngIf="showParentData">
            {{ 'FIOPredstavitelya' | translate }}
          </div>
          <div class="flex  rounded-mdp-4" *ngIf="showParentData">
            <input class="w-full pl-1 bg-gray-200 rounded" type="text" formControlName="parentFullname">
          </div>
          <div class="flex  rounded-mdp-4" *ngIf="showParentData">
            {{ 'PhoneNumberPredstavitelya' | translate }}
          </div>
          <div class="flex  rounded-mdp-4" *ngIf="showParentData">
            <input formControlName="parentPhoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00"
                   class="w-full pl-1 bg-gray-200 rounded" type="text">
          </div>
          <div class="flex rounded-mdp-4" *ngIf="showParentData">
            {{ 'emailPredstavitelya' | translate }}
          </div>
          <div class="flex rounded-mdp-4" *ngIf="showParentData">
            <input formControlName="parentEmail"
                   class="w-full pl-1 bg-gray-200 rounded" type="text">
          </div>
            <div class="flex  rounded-mdp-4">
                {{ 'Sex' | translate }}
            </div>
            <div class="flex rounded-mdp-4">
                <div class="flex justify-around text-center">
                    <div class="mr-4">
                        <input value="1" [checked]="applicationForm.get('sexId').value == 1" formControlName="sexId"
                            class="mr-2 bg-gray-200 rounded pol-radio" id="pol1" type="radio">
                        <label for="pol1">{{ 'Male' | translate }}</label>
                    </div>
                    <div class="">
                        <input value="2" [checked]="applicationForm.get('sexId').value == 2" formControlName="sexId"
                            class="mr-2  bg-gray-200 rounded pol-radio" id="pol2" type="radio">
                        <label for="pol2">{{ 'Female' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'PhoneNumber' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00"
                    class="w-full pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'DopSertifs' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="anotherExamTypeId" class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="0">
                        {{ 'DopSertifs' | translate }}
                    </option>
                    <option *ngFor="let item of dopSertifsList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'InternationalEnglishCertificate' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <select formControlName="foreignLangCertTypeId"
                    (change)="checkSertif(applicationForm.get('foreignLangCertTypeId').value)"
                    class="w-full h-6 pl-1 bg-gray-200 rounded">
                    <option disabled [value]="-1">
                        {{ 'SelectСertificate' | translate }}
                    </option>
                    <option *ngFor="let item of foreignLangCertList" [value]="item.id">
                        <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                    </option>
                </select>

                <div class="ml-2">{{ 'Ball' | translate }}:</div>
                <input [mask]="applicationForm.get('foreignLangCertTypeId').value == 4? foreignLangCertScoreMaskIelts: foreignLangCertScoreMask" formControlName="foreignLangCertScore"
                    placeholder="{{ 'Ball' | translate }}" class="w-full h-6 bal pl-1 bg-gray-200 rounded" type="text">
            </div>
            <div class="flex  rounded-mdp-4">
                {{ 'SetForeignLangCertificateDate' | translate }}
            </div>
            <div class="flex  rounded-mdp-4">
                <input formControlName="foreignLangCertTestDate" class="w-full text-center bg-gray-200 rounded mb-2"
                    type="date">
            </div>
        </div>
    </div>
    <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PlaceOfStudy' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'YearOfEndTIPOStudy' | translate }}:
        </div>
        <div class="w-1/3">
            <input formControlName="schoolFinishYear" placeholder="- - - -" class="w-16 text-center bg-gray-200 rounded mb-2" [mask]="year" type="text">
        </div>
    </div>

    <div class="flex">
        <div class="w-1/3">
            {{ 'PlaceOfStudy' | translate }}:
        </div>
        <div class="w-2/3 grid">
            <select class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="oblId" (change)="getRaionList()">
                <option disabled [value]="0">
                    {{ 'SelectObl' | translate }}
                </option>
                <option [disabled]="canEditOblId" *ngFor="let item of oblList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
            <select class="w-1/2 pl-1 bg-gray-200 rounded mb-2" formControlName="raionId" (change)="getSchoolList()">
                <option disabled [value]="0">
                    {{ 'SelectRaion' | translate }}
                </option>
                <option [disabled]="canEditRaionId" *ngFor="let item of raionList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
            <select class="w-full flex pl-1 bg-gray-200 rounded mb-2" formControlName="schoolId">
                <option disabled [value]="0">
                    {{ 'PlaceOfStudy' | translate }}
                </option>
                <option class="" [disabled]="canEditSchoolId" *ngFor="let item of schoolList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'TIPOSpeciality' | translate }}:
        </div>
        <div class="w-2/3">
            <select formControlName="collegeSpecialityId" (change)="getEduProgram(0)" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'TIPOSpeciality' | translate }}
                </option>
                <option *ngFor="let item of collegeSpecialityList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.code }} - {{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.code }} - {{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectGOP' | translate }}:
        </div>
        <div class="w-2/3">
            <select (change)="getSpecialDisciplines(0)" formControlName="eduProgramId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'SelectGOP' | translate }}
                </option>
                <option *ngFor="let item of eduProgramList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.code }} - {{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.code }} - {{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestParams' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SpecDisc' | translate }}:
        </div>
        <div class="w-2/3">
            <select (change)="getLangList(0)" formControlName="tipoSubjectId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'SpecDisc' | translate }}
                </option>
                <option *ngFor="let item of specialDisciplines" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTheLanguageForTesting' | translate }}:
        </div>
        <div class="w-1/3">
            <select formControlName="testLangId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'LanguageOfSubjects' | translate }}
                </option>
                <option *ngFor="let item of langList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="appShortInfo.canChangeTestPeriod" class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectObl' | translate }}:
        </div>
        <div class="w-1/3">
            <select (change)="getTestOrg()" formControlName="testOblId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let item of oblList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="appShortInfo.canChangeTestPeriod" class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTestSite' | translate }}:
        </div>
        <div class="w-1/3">
            <select (change)="getTestDate()" formControlName="testOrgId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'TestLocation' | translate }}
                </option>
                <option *ngFor="let item of testOrgList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="appShortInfo.canChangeTestPeriod" class="flex mb-2">
        <div class="w-1/3">
            {{ 'SelectTestDate' | translate }}:
        </div>
        <div class="w-1/3">
            <select formControlName="testPeriodId" class="w-full pl-1 bg-gray-200 rounded">
                <option disabled [value]="0">
                    {{ 'TestDate' | translate }}
                </option>
                <option *ngFor="let item of testDateList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                </option>
            </select>
        </div>
    </div>

    <div class="flex mb-4 mt-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'DisabilityData' | translate }}:
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <fieldset form="applicationForm" style="height: 70px;">
        <div class="flex text-center mb-3 h-5">
            <div class="w-4/5 text-left">
                {{ 'DisabilityC' | translate }}:
            </div>
            <div class="w-2/5 mt-2">
                <input [checked]="applicationForm.get('isHasDisability').value == 'true'" value="true" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis1" type="radio">
                <label class="cursor-pointer" for="dis1">{{ 'Yes' | translate }}</label>
            </div>
            <div class="w-2/5 mt-2">
                <input [checked]="applicationForm.get('isHasDisability').value == 'false'" value="false" formControlName="isHasDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis2" type="radio">
                <label class="cursor-pointer" for="dis2">{{ 'No' | translate }}</label>
            </div>
        </div>
    </fieldset>
    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'" class="flex">
        <div class="w-1/3">
            {{ 'Disability' | translate }}:
        </div>
        <div class="w-full grid">
            <select class="w-full pl-1 bg-gray-200 rounded mb-2" formControlName="disabilityId">
                <option disabled [value]="0">
                    {{ 'SelectWhatNeed' | translate }}
                </option>
                <option *ngFor="let item of disabilityList" [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>

    <fieldset *ngIf="applicationForm.get('isHasDisability').value == 'true'" form="applicationForm" style="height: 70px;">
        <div class="flex text-center mb-3 h-5">
            <div class="w-1/5 text-left mt-2">
                {{ 'helper' | translate }}:
            </div>
            <div class="w-1/5 mt-2">
                <input [checked]="applicationForm.get('isNeedHelperDueDisability').value == 'true'" value="true" formControlName="isNeedHelperDueDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis3" type="radio">
                <label class="cursor-pointer" for="dis3">{{ 'Yes' | translate }}</label>
            </div>
            <div class="w-2/5 mt-2">
                <input [checked]="applicationForm.get('isNeedHelperDueDisability').value == 'false'" value="false" formControlName="isNeedHelperDueDisability" class="mr-4 pl-1 bg-gray-200 rounded pol-radio" id="dis4" type="radio">
                <label class="cursor-pointer" for="dis4">{{ 'No' | translate }}</label>
            </div>
        </div>
    </fieldset>

    <div *ngIf="applicationForm.get('isHasDisability').value == 'true'  && applicationForm.get('disabilityId').value != 0" class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
        <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile()">{{ 'addFile' | translate }}</button>
            <div class="mx-auto w-1/2 text-center text-xl font-bold">
                {{ 'InvalidSpravka' | translate }}
            </div>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
        </div>
        <span class="add-files-scroll">
        <div formArrayName="docFiles" class="w-full mt-4 controls-border" *ngFor="let item of files.controls; index as i">
          <div [formGroup]="files.controls[i]">
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
            <div class="f-btn-group">
              <div class="relative">
                <input (change)="changeListener($event, i, 'edit')" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                  <p>
                    <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                    <ng-template #elseBlock><span> {{ 'UploadInvFile' | translate }}</span></ng-template>
                  </p>
                </div>
                <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
              </div>
            </div>
            <div class="s-btn-group">
              <button  type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile(i)">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
              </button>
            </div>
          </div>
        </div>
       </span>
    </div>

    <button [disabled]="" (click)="EditApplication()" class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">
        {{ 'Edit' | translate }}
    </button>

</form>

