import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-p-type',
  templateUrl: './p-type.component.html',
  styles: [
  ]
})
export class PTypeComponent implements OnInit {
  id: any;
  testTypes: any;
  variantCount: any;
  costOfAttempt: any;
  constructor(public router: Router, private api: ApiService, public translate: TranslateService, private route: ActivatedRoute) {
    }

  ngOnInit(): void {
    this.api.getProbTestEntTypes(2)
      .subscribe({
        next: (res: any) => {
          this.testTypes = res.items;
          // this.variantCount = res.items.someProperty;
          // sessionStorage.setItem('variantCount', JSON.stringify(this.variantCount));
          // this.id = res.items.someProperty;
          // sessionStorage.setItem('id', JSON.stringify(this.id));
          // this.costOfAttempt = res.items.someProperty;
          // sessionStorage.setItem('costOfAttempt', JSON.stringify(this.costOfAttempt));
        }
      });
    // this.testTypes = [
    //   {
    //     id: 1,
    //     name: {
    //       kk: 'Пробное тестирование в удаленном формате',
    //       ru: 'Пробное тестирование в удаленном формате'
    //     }
    //   }
    // ];
  }

  link(e: any){
    sessionStorage.setItem('variantCount', JSON.stringify(e.variantCount));
    sessionStorage.setItem('id', JSON.stringify(e.id));
    sessionStorage.setItem('costOfAttempt', JSON.stringify(e.costOfAttempt));
    this.router.navigate(['profile/prob-test/2/' + e.id]);
  }

}
