import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { KaztestService } from 'src/app/components/profile/applications/kaztest/service/kaztest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from '../../../../../../service/message.service';

@Component({
  selector: 'app-kaz-appeal',
  templateUrl: './kaz-appeal.component.html',
  styleUrls: ['./kaz-appeal.component.css']
})
export class KazAppealComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  appealScore: number;
  appealReasonDescription: any;
  testScore: number;
  email: any;
  totalScore: any;
  nationsList = [];
  countryList = [];
  subjects = [];
  questions= [];
  answerList = [];
  dataofbday = 0;
  phone = '+0 (000) 000-00-00';
  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canAddAppealDescription = false;
  showWindowModal = false;
  blockBtn = false;
  onlineTestAppealCount: any;
  ruleskazTestSogData: any;
  testTypeId: number = 0;

  commonList = [];
  testPeriod = [];
  visibleLoad = false;
  rulesKazTest = false;
  showAnwerCard = false;
  textModal = false;
  totalAppealScore: any;
  totalTestScore: any;

  applicationAgree = false;
  applicationAgreePostApp = false;

  subjectId: any;
  id: any;

  isEdit = false;

  constructor(
    public mainApi: ApiService,
    public api: KaztestService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer:DomSanitizer,
    private router:Router,
    private messagService: MessageService

  ) {
    // this.route.url.subscribe((v) => (this.testTypeId = +v[0].path));
    // this.route.params.subscribe({
    //   next:params=>{
    //     if(params['testTypeId'] && params['id']){
    //       this.id = +params['id'];
    //       if( this.id != 0){
    //         this.isEdit = true;
    //       }
    //     }
    //   }
    // });

    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      iin: new FormControl(0, Validators.required),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      birthDate: new FormControl(0),
      appealReasonDescription: new FormControl(''),
      subjects: new FormArray([]),
      studentTestId: new FormControl(this.id)
    });

    this.messagService.getKazTestSog()
      .subscribe((res: any) => {
        this.ruleskazTestSogData = res;
      });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getCommon(this.id);
      this.getAnswer(this.id);
    });

    this.getNationList();
    this.getCitizenshipList();
    // this.getCategories();
    // this.getObl();
    // this.getCommon();

    this.mainApi.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      if (res.data.sexId == 0) {
        this.canEditSexId = true;
      }
      if (res.data.nationId == 0 || res.data.nationId == 99) {
        this.canEditNationId = true;
      }
      if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
        this.canEditCitizenshipId = true;
      }
      if (res.data.phoneNumber == 0) {
        this.canEditPhoneNumber = true;
      }
      this.applicationForm.get('sexId').patchValue(res.data.sexId);
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
      this.applicationForm.get('nationId').patchValue(res.data.nationId);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
      this.applicationForm.get('birthDate').setValue(res.data.birthDate);
      this.applicationForm.get('iin').setValue(res.data.iin);
      this.applicationForm.get('studentTestId').setValue(this.id);
      // this.getDateOfBirth(res.data.birthDate);
    });

    // if(this.isEdit){
    //   this.api.getShort(this.id).subscribe({
    //     next: (res: any)=> {
    //       const data = res.data;
    //
    //       // Test Params
    //       this.applicationForm.get('categoryId').setValue(data.categoryId);
    //       this.applicationForm.get('oblId').setValue(data.testOrgOblId);
    //
    //       const obl = {
    //         target: {
    //           value: data.testOrgOblId
    //         }
    //       };
    //       // this.changeObl(obl);
    //
    //       const testOrg = {
    //         target: {
    //           value: data.testOrgId
    //         }
    //       };
    //       // this.changeTestOrg(testOrg);
    //       this.applicationForm.get('testOrgId').setValue(data.testOrgId);
    //       this.applicationForm.get('testPeriodId').setValue(data.testPeriodId);
    //     }
    //   });
    // }

  }
  get subject(): FormArray {return this.applicationForm.get('subjects') as FormArray;}
  getNationList() {
    this.api.getNations(this.testTypeId).subscribe(
      (res: any) => {
        this.nationsList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
            'Ошибка',
            err.error.errorMessage.ru,
            NotificationType.Error,
            5000
          )
          : this._notifications.create(
            'Қате',
            err.error.errorMessage.kk,
            NotificationType.Error,
            5000
          );
      }
    );
  }

  getCitizenshipList() {
    this.api.getCitizenShips(this.testTypeId).subscribe(
      (res: any) => {
        this.countryList = res.items;
      },
      (err: any) => {
        this.translate.currentLang == 'ru'
          ? this._notifications.create(
            'Ошибка',
            err.error.errorMessage.ru,
            NotificationType.Error,
            5000
          )
          : this._notifications.create(
            'Қате',
            err.error.errorMessage.kk,
            NotificationType.Error,
            5000
          );
      }
    );
  }
  changeAgree(e: any){
    this.applicationAgree = !this.applicationAgree;
  }

  getCommon(id: any){
    this.api.getResultCommon(id)
      .subscribe((res: any) => {
        this.commonList = res.data;
        this.subjects = res.data.subjects;
        this.subjects.forEach((e: any) => {
          if (e.canAddAppealDescription){
            const subject: FormGroup = new FormGroup({
              id: new FormControl(e.id),
              appealReasonDescription: new FormControl(' '),
            });

            this.subject.push(subject);
          }
        });
        console.log(this.applicationForm.value);
      });
  }

  getAnswer(id: any){
    this.api.getAnswerCard(id)
      .subscribe((res: any) => {
        this.answerList = res.data;
        this.subjects = res.data.subjects;
      });
  }

  showAnswer(){
    this.showAnwerCard = !this.showAnwerCard;
  }

  showWindow(id: any){
    this.showWindowModal = !this.showWindowModal;
    const group = this.subject.controls.find((group: FormGroup) => group.get('id')?.value === id) as FormGroup;
    this.subjectId = group.get('id').value;
    this.appealReasonDescription = group.get('appealReasonDescription').value;
  }
  closeAgreeModal() {
    this.showWindowModal = false;
    const group = this.subject.controls.find((group: FormGroup) => group.get('id')?.value === this.subjectId) as FormGroup;
    group.get('appealReasonDescription').setValue(this.appealReasonDescription);
  }

  cancel(){
    this.showWindowModal = false;
  }

  clearReason(){
    this.showWindowModal = false;
    const group = this.subject.controls.find((group: FormGroup) => group.get('id')?.value === this.subjectId) as FormGroup;
    group.get('appealReasonDescription').setValue('');
  }

  postApp(){
    this.api.postAppAppeal(this.applicationForm.value)
      .subscribe((res: any) => {
        if (res.data.paymentType.id == 2){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id, type: 3 } });
        }else{
          this.router.navigateByUrl('/profile/history');
        }

      });
  }
  openTextAgree(){
    this.textModal = true;

  }
  closeAgreeModalPostApp() {
    this.textModal = false;
    this.applicationAgree = false;
  }
  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.blockBtn = false;
    }
  }

  agreeCheck() {
    this.applicationAgree = !this.applicationAgree;
  }

}
