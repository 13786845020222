import { Component, OnInit, ViewChild, ElementRef, NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, FormControlName, NgModel } from "@angular/forms";
import { ApiService } from "../../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vipuskniki-vuzov',
  templateUrl: './vipuskniki-vuzov.component.html',
  styleUrls: ['./vipuskniki-vuzov.component.css']
})
export class VipusknikiVuzovComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  jobTypeId = 8;
  pageLangId: any;
  subjectCount: any;
  subjectCountArray = [];
  showNasPunkt = true;
  fromActualCategories = [];
  toActualCategories = [];
  KATOOblList = [];
  KATOCityList = [];
  KATOSeloList = [];
  KATORaionList = [];
  jobList = [];
  subjectList = [];
  isInvalid = false;
  jobId: number;
  langList = [];
  testOrg = [];
  testPeriod = [];
  KATOs = [];
  KATOsubject = [];
  pseudoId = [];
  lastChild = false;
  year = '0000';
  fullDate = '00-00-0000';
  phone = '+0 (000) 000-00-00';
  iin = "000000000000";
  applicationAgree: any;
  instituteList = [];
  instituteSpecialityList = [];
  oblList = [];
  subject = '';

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) {}

  ngOnInit(): void {

    if(localStorage.getItem('Lang') == 'ru'){
      this.pageLangId = 2;
    }else{
      this.pageLangId = 1;
    }

    this.applicationForm = new FormGroup({
      jobTypeId: new FormControl(this.jobTypeId),
      lastname: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      iin: new FormControl(),
      eduSpecialityId: new FormControl(),
      eduInstituteId: new FormControl(),
      // specialityId: new FormControl(),
      testOrgId: new FormControl(),
      testPeriodId: new FormControl(),
      testLangId: new FormControl(),
      eduInstituteOblId: new FormControl(),
      oblId: new FormControl(),
      subjects: new FormArray([]),
      // kato: new FormArray([])
    });

    this.getProfileData();

    this.fromListOfActualCategories();
    this.toListOfActualCategories();
    // this.getKATOOblList();
    this.getOblList();
    this.getInstituteSpeciality();
    // this.getSubjectsLang(0);

    this.getsubjectCount();
    // this.getTestOrgList();
    this.applicationForm.get('eduInstituteOblId').patchValue(-1);
    this.applicationForm.get('testOrgId').patchValue(-1);
    this.applicationForm.get('testPeriodId').patchValue(-1);
    this.applicationForm.get('testLangId').patchValue(-1);
    this.applicationForm.get('oblId').patchValue(-1);

    this.applicationForm.get('eduInstituteId').patchValue(-1);
    this.applicationForm.get('eduSpecialityId').patchValue(-1);


  }

  get subjects() : FormArray {
    return this.applicationForm.get("subjects") as FormArray;
  }

  get katoGetter() : FormArray {
    return this.applicationForm.get("kato") as FormArray;
  }

  getOblList(){
    this.api.oblList(this.jobTypeId)
      .subscribe((res: any) => {
        this.oblList = res.items;
      },(err: any) => {

      });
  }

  getInstitute(){
    this.api.eduInstitute(this.jobTypeId, this.applicationForm.get('eduInstituteOblId').value)
      .subscribe((res: any) => {
        this.instituteList = res.items;
      },(err: any) => {

      });
  }

  getInstituteSpeciality(){
    this.api.instituteSpeciality(this.jobTypeId, this.applicationForm.get('employmentTypeId').value)
      .subscribe((res: any) => {
        this.instituteSpecialityList = res.items;
      },(err: any) => {

      });
  }

  getTestOrgList(){
    this.api.testOrgListByObl(this.jobTypeId, this.applicationForm.get('oblId').value)
      .subscribe((res: any) => {
        this.testOrg = res.items;
      }, (err: any) => {

      });
  }

  getTestPeriodList(){
    this.api.testPeriodList(this.jobTypeId, this.applicationForm.get('testOrgId').value, 0)
      .subscribe((res: any) => {
        console.log(res.items.length);
        if(res.items.length == 0){
         this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'не осталось свободных мест тестирования в данном городе, дополнительные места не предусмотрены.', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Бұл қалада бос тестілеу орыны қалмады, қосымша орындар қарастырылмаған', NotificationType.Error, 5000);
        }
        this.testPeriod = res.items;
      }, (err: any) => {

      });
  }


  invalidCheck(){
    this.isInvalid = !this.isInvalid;
  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

  changeIIN(){
    this.applicationForm.get('firstname').setValue('');
    this.applicationForm.get('lastname').setValue('');
    this.applicationForm.get('patronymic').setValue('');
  }

  getProfileData(){
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.applicationForm.get('iin').setValue(res.data.iin);
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
        this.applicationForm.get('email').setValue(res.data.email);
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);

      }, (err: any) => {

      });
  }

  findData(iin: number){
    this.loading = true;
    this.api.personalData(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  fromListOfActualCategories(){
    this.api.getFromListOfActualCategories(this.jobTypeId)
      .subscribe((res: any) => {
        this.fromActualCategories = res.items;
      }, (err: any) => {

      });
  }

  toListOfActualCategories(){
    this.api.getToListOfActualCategories(this.jobTypeId)
      .subscribe((res: any) => {
        this.toActualCategories = res.items;
      }, (err: any) => {

      });
  }

  // getKATOsList(i, j, firstSelect){
  //   let id: number;
  //   if(firstSelect){
  //     id = this.applicationForm.get('eduInstituteOblId').value;
  //     this.lastChild = false;
  //   }else{
  //     id = this.KATOs[i][j].id;
  //     this.lastChild = !this.KATOs[i][j].hasChildren;
  //   }
  //   if(!this.lastChild || firstSelect){
  //     this.api.getKATOList(this.jobTypeId, id, this.pageLangId)
  //     .subscribe((res: any) => {
  //       if(firstSelect){
  //         console.log(1);
  //         this.KATOs = [];
  //         this.pseudoId = [];
  //         this.KATOs.push(res.data.items);
  //       };

  //       if(firstSelect == false && this.lastChild){
  //         console.log(2);
  //         this.KATOs.splice(i+1, 1);
  //         this.KATOs.push(res.data.items);
  //       };
  //       if(firstSelect == false && this.KATOs[i][j].hasChildren){
  //         console.log(3);
  //         this.KATOs.splice(i+1, 2);
  //         this.KATOs.push(res.data.items);
  //       };
  //     }, (err: any) => {

  //     });
  //   }
  //     if(!firstSelect && !this.KATOs[i][j].hasChildren){
  //       this.KATOs.splice(i+1, this.KATOs.length);
  //       console.log(4);
  //       this.getJobItems(id, false);
  //     }else{
  //       this.lastChild = false;
  //     };

  // }

  // getKATOOblList(){
  //   this.api.getKATOList(this.jobTypeId, 0, this.pageLangId)
  //     .subscribe((res: any) => {
  //       this.KATOOblList = res.data.items;
  //     }, (err: any) => {

  //     });
  // }

  getJobItems(nasPunkId, binSearch){
    if(this.showNasPunkt == true){
      this.api.getJobList(this.jobTypeId, nasPunkId, this.pageLangId)
      .subscribe((res: any) => {
        this.jobList = res.items;
      }, (err: any) => {

      });
    }
    if(binSearch == true){
      setTimeout(() => {
        this.applicationForm.get('jobId').setValue(this.jobId);
        this.loading = false;
      }, 1000);
    }

  }

  getsubjectCount(){
    this.api.getsubjectCount(this.jobTypeId)
      .subscribe((res: any) => {
        this.subjectCount = res.data.subjectCount;
        this.subjectCountLoop();
      }, (err: any) => {

      });
  }

  subjectCountLoop(){
    this.subjectCountArray = [];
    let subj = this.applicationForm.controls.subjects as FormArray;
    for(var i = ++this.subjectCountArray.length; i <= this.subjectCount; i++){
      this.api.getSubjectItems(this.jobTypeId, i)
          .subscribe((response: any) => {
            this.subjectList.push(response.items);
          }, (error: any) =>{

          });
      this.addSubject(i);
    }
    this.langList.reverse();

  }

  getSubjectsLang(i){
    console.log(this.subjects.controls[i].get('subjectId').value);
    this.api.getSubjectLang(this.jobTypeId, this.subjects.controls[i].get('subjectId').value)
      .subscribe((response: any) => {
          this.langList = response.items;
      }, (error: any) =>{

      });
  }

  newSubject(i): FormGroup {
    return this.fb.group({
      ord: new FormControl(i),
      subjectId: new FormControl(''),
      // langId: new FormControl('')
    })
  }

  setSubjectLangIdValue(status, i, value){
    this.subjects.controls[i].get('langId').setValue(value);
  }

  postForm(){
    let phone = this.applicationForm.get('phoneNumber').value;
    const regex = /^7\d{10}$/;
    if(phone == null || phone.trim().length == 0){
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка','Не указан контактный телефон', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Байланыс телефоны көрсетілмеген',NotificationType.Error, 5000);
      return;
    }else if(!regex.test(phone)){
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка','Передано некорректное значение номера контактного телефона (+7 (777) 777-77-77)', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Байланыс телефонының нөмірі қате берілді (+7 (777) 777-77-77)',NotificationType.Error, 5000);
      return;
    }
    this.loading = true;
    this.api.postNqtSoForm(this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        if(res.data.paymentTypeId == 2 && res.notice){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id, type: 6 } });
          this.translate.currentLang == 'ru' ? this._notifications.success('Заявление', res.notice.ru, { timeOut: 0 }) : this._notifications.success('Өтініш', res.notice.kk, { timeOut: 0 });
        }else{
          this.router.navigateByUrl('/profile/history');
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
        }
        // this.router.navigateByUrl('/profile/history');
      },(err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  addSubject(i) {
    this.subjects.push(this.newSubject(i));
    this.subjectCountArray.push(this.subjects);
  }

  addKato(i) {
    this.katoGetter.push(this.createKato(i));
  }

  setSubjectValue(status, i, value){
    console.log(value);
    this.subjects.controls[i].get('subjectId').setValue(value);

    this.getSubjectsLang(i);


  }

  createKato(katoId): FormGroup {
    return this.fb.group({
      katoId
    })
  }

  searchJob(bin){
    this.loading = true;
    this.api.searchJobByBin(bin)
      .subscribe((res: any) => {
        this.loading = false;
        this.KATOs = [];
        this.jobId = res.data.id;
        this.applicationForm.get('eduInstituteOblId').setValue(res.data.kato[0]);
        res.data.kato.forEach(element => {
          this.api.getKATOList(this.jobTypeId, element, this.pageLangId)
          .subscribe((resp: any) => {
            if(resp.data.items.length > 0){
              this.KATOs.push(resp.data.items);
            }
            this.KATOs.sort();
            this.KATOs.reverse();
          }, (err: any) => {

          });

        });

        console.log(res);
        this.katoGetter.controls = [];

        this.pseudoId = [];

        res.data.kato.forEach(elem => {
          this.pseudoId.push(elem);
        });

        this.getJobItems(res.data.kato[res.data.kato.length-1], true);

        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000) : this._notifications.create('Успешно', 'данные получены', NotificationType.Success, 5000);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

}
