import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { messagesConfig } from '../config/messagesRoutes';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private entSogData = "/assets/sog/ent.json"
  private pvoSogData = "/assets/sog/pvo.json"
  private docSogData = "/assets/sog/dok.json"
  private dirSogData = "/assets/sog/dir.json"
  private kazTestSogData = "/assets/sog/kaztest.json"
  private citizenData = "/assets/sog/citizen.json"

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private messageConfig:messagesConfig
    ) { }


    public getMessages(){
      return this.http.get(this.messageConfig.apiRoutes.items, this.tokenService.getHeaderAuth());
    }

    public getAppMessage(body:any){
      return this.http.post(this.messageConfig.appMessageBackend, body);
    }

    public getEntSog(){
      return this.http.get(this.entSogData);
    }

    public getPvoSog(){
      return this.http.get(this.pvoSogData);
    }

    public getDocSog(){
      return this.http.get(this.docSogData);
    }


    public getDirSog(){
      return this.http.get(this.dirSogData);
    }

    public getCitizen() {
      return this.http.get(this.citizenData);
    }

  public getKazTestSog(){
    return this.http.get(this.kazTestSogData);
  }


}
