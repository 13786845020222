import { Component, OnInit, AfterContentInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { TimerService } from 'src/app/service/timer.service';
import { ApiService } from './service/api.service';
import { MobileService } from './service/mobile.service';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit, AfterContentInit {

  interval: any;
  isMobile = false;

  constructor(public translate: TranslateService, private timer: TimerService, private api: ApiService,
    public location: LocationStrategy, private mobileService: MobileService, private router:Router,
    public titlePage: Title, private aroute:ActivatedRoute) {
      this.isMobile = mobileService.isMoble;

    translate.addLangs(['ru', 'kz']);

    let lang = localStorage.getItem('Lang');
    if( lang === null || lang === undefined){
      translate.setDefaultLang('ru');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/ru|kz/) ? browserLang : 'ru');
    }

    this.changeLang(localStorage.getItem('Lang'));
  }
  title = 'zayav-universal';
  pageTitle = '';

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    // if (this.timer.getAuth() && this.timer.getInterval() > 0) {
    //   setInterval(() => {
    //     if (this.timer.getAuth() && this.timer.getInterval() > 0) {
    //       this.api.isAlive().subscribe();
    //     }
    //   }, this.timer.getInterval() * 1000);
    // }
  }






  changeLang(lang) {
    this.translate.use(lang);
    localStorage.setItem('Lang', lang);
  }

  removeInterval() {
    console.log("Func is call")
    clearInterval(this.interval);
  }


}
