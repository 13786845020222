import { Component, OnInit, isDevMode,  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-prob-dir',
  templateUrl: './prob-dir.component.html',
  styleUrls: ['./prob-dir.component.css']
})
export class ProbDirComponent implements OnInit {
  items: any;
  pageOfItems: Array<any>;
  modal = false;
  kaspiOrderId = 0;
  quantity = 1;
  paymentUrl: string;
  loading = false;
  test: any;

  constructor(private api: ApiService, public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.test = JSON.parse(sessionStorage.getItem('info'));
    this.getTestTries();
  }

  getTestTries() {
    let token = localStorage.getItem('token');
    if (token != null) {
      this.api.getDirTries(token).subscribe({
        next: (res: any) => {
          this.items = res.items;
        }
      });
    }
  }

  link(p: any) {

    const isMobile = localStorage.getItem('isMobile');

    if (!isDevMode()) {
      if(isMobile){
        window.location.href = 'https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href;
      }else{
        window.open('https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href);
      }
    }
    if(isDevMode()) {
      if(isMobile){
        window.location.href = 'https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href;
      }else{
        window.open('https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href);
      }
    }

  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  createOrder() {
    this.loading = true;
    let token = localStorage.getItem('token');
    let body = {
      paymentProviderTypeId: 2,
      returnUrl: "",
      quantity: this.quantity
    };
    if (token != null) {
      this.api.createOrderProbDir(token, body).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }, error: (err) => {
          this.loading = false;
        }
      });
    }

  }

  closeModal() {
    this.kaspiOrderId = 0;
    this.quantity = 0;
    this.getTestTries();
    this.modal = false;
  }

}
