import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, FormControlName } from "@angular/forms";
import { ApiService } from "../../../../service/api.service";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from "../../../../service/token.service";

@Component({
  selector: 'app-edit-citizen',
  templateUrl: './edit-citizen.component.html',
  styleUrls: ['./edit-citizen.component.css']
})
export class  EditCitizenComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  disabilityDocPhoto: FormGroup;
  disabilityHelperInfo: FormGroup;
  jobTypeId = 5;
  pageLangId: any;
  subjectCount: any;
  subjectCountArray = [];
  showNasPunkt = true;
  fromActualCategories = [];
  toActualCategories = [];
  disabilityList = [];
  langList = [];
  testOrg = [];
  testPeriod = [];
  lastChild = false;
  year = '0000';
  fullDate = '00-00-0000';
  iin = "000000000000";
  phone = '+0 (000) 000-00-00';
  employmentTypeList: any;
  oblList: any;
  instituteSpecialityList: any;
  collegeSpecialityList: any;
  vuzObl = 0;
  raion = 0;
  vuzList: any;
  sexId: any;
  citizenship: any;
  raionList: any;
  appTypeId: any;
  isDisabilityHelperRequired = false;
  citizenshipList = [];
  isHasDisability = false;
  testTypeId: any;
  citizenshipId: any;
  eduType: any;
  birthDate: any;
  modalAgreement = false;
  sogData: any;
  blockBtn = true;
  testPeriodId: any;
  profileDataList = [];
  disabilityId: any;
  testLangId: any;
  citizenShortList: any = [];
  nationsList = [];
  testorgList = [];
  testOrgId: any;
  testOrgOblId: any;
  testPeriodList = [];
  appId: any;
  selectedFile: File | null = null;
  filename: any;
  disabilit: any;
  specialityName: any;

  constructor(private fb: FormBuilder,
              public api: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private _notifications: NotificationsService,
              public translate: TranslateService,
              private messageService: MessageService,
              public sanitizer: DomSanitizer,
              public token: TokenService

  ) {
    this.applicationForm = this.fb.group({
      jobTypeId: new FormControl(this.jobTypeId),
      isHasDisability: new FormControl(),
      lastname: new FormControl(),
      disabilityId: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      testOrgOblId: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      iin: new FormControl(),
      testOrgId: new FormControl(0),
      birthDate: new FormControl(),
      citizenshipId: new FormControl(),
      testPeriodId: new FormControl(),
      testLangId: new FormControl(0),
      nationId: new FormControl(),
      sexId: new FormControl(),
      specialityName: new FormControl(),
      applicationAgree: new FormControl({value: false, disabled: true}),
      isDisabilityHelperRequired: new FormControl(),
      disabilityDocPhoto: this.fb.group({
        filename: new FormControl(),
        data: new FormControl(),
      }),

    });



    this.disabilityHelperInfo = this.fb.group({
      iin: new FormControl(),
      phoneNumber: new FormControl(),
      specialityName: new FormControl(),
      lastname: new FormControl(),
      patronymic: new FormControl(),
      firstname: new FormControl(),
    });

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {this.appId = params.get('id'); });

    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }

    this.applicationForm.get('isHasDisability')?.valueChanges.subscribe((value) => {
      if (value === false) {
        this.clearDisability();
      }
    });

    this.applicationForm.get('testOrgOblId').valueChanges.subscribe((value) => {
      // Clear and reset second and third dropdowns
      this.applicationForm.get('testOrgId').setValue(0);
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testorgList = []; // Clear the list for testOrgId
      this.testPeriodList = []; // Clear the list for testPeriodId

      // Load data for the second dropdown based on the selected value
      if (value !== 0) {
        this.getTestOrgList(value);
      }
    });

    // Watch for changes in the second dropdown
    this.applicationForm.get('testOrgId').valueChanges.subscribe((value) => {
      // Clear and reset third dropdown
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testPeriodList = []; // Clear the list for testPeriodId

      // Load data for the third dropdown based on the selected value
      if (value !== 0) {
        this.getTestPeriodList(value);
      }
    });

    this.api.profileInfo()
      .subscribe((res: any) => {
        this.profileDataList = res.data;
        this.citizenship = res.data.citizenship;
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);


      }, (err: any) => {

      });


    this.api.getAppCitizenshort(this.appId)
      .subscribe((res: any) => {
        this.citizenShortList = res.data;
        this.applicationForm.get('iin').setValue(res.data.iin);
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
        this.applicationForm.get('email').setValue(res.data.email);
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);
        this.applicationForm.get('citizenshipId').setValue(res.data.citizenshipId);
        this.applicationForm.get('sexId').setValue(res.data.sexId);
        this.applicationForm.get('nationId').setValue(res.data.nationId);
        this.applicationForm.get('testLangId').setValue(this.citizenShortList.testLangId);
        this.applicationForm.get('testOrgOblId').setValue(this.citizenShortList.testOrgOblId);
        this.applicationForm.get('testOrgId').setValue(this.citizenShortList.testOrgId);
        this.applicationForm.get('testPeriodId').setValue(this.citizenShortList.testPeriodId);
        this.applicationForm.get('disabilityId').setValue(this.citizenShortList.disabilityId);
        this.applicationForm.get('isHasDisability').setValue(res.data.isHasDisability);
        this.applicationForm.get('isDisabilityHelperRequired').setValue(res.data.isDisabilityHelperRequired);


        // Set values for nested disabilityHelperInfo FormGroup
        this.disabilityHelperInfo?.get('iin').setValue(res.data.disabilityHelperInfo.iin);
        this.disabilityHelperInfo?.get('phoneNumber').setValue(res.data.disabilityHelperInfo.phoneNumber);
        this.disabilityHelperInfo?.get('specialityName').setValue(res.data.disabilityHelperInfo.specialityName);
        this.disabilityHelperInfo?.get('lastname').setValue(res.data.disabilityHelperInfo.lastname);
        this.disabilityHelperInfo?.get('patronymic').setValue(res.data.disabilityHelperInfo.patronymic);
        this.disabilityHelperInfo?.get('firstname').setValue(res.data.disabilityHelperInfo.firstname);



      });


    this.messageService.getCitizen().subscribe(res => this.sogData = res);


    this.getCitizenshipList();
    this.getntionList();
    this.getLangList();
    this.getOblList();
    // this.getTestOrgList();
    // this.getTestPeriodList();
    this.getDisabilityList();

  }

  getCitizenshipList() {
    this.api.citizenship(1)
      .subscribe((res: any) => {
        this.citizenshipList = res.items;
      });
  }

  getntionList() {
    this.api.getNation(1)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      });
  }

  getLangList() {
    this.api.getLang(1)
      .subscribe((res: any) => {
        this.langList = res.items;
      });
  }


  getOblList() {
    this.api.getObl(1)
      .subscribe((res: any) => {
        this.oblList = res.items;

      });
  }

  getTestOrgList(value: any) {
    this.api.getTestOrg(1, this.applicationForm.get('testOrgOblId').value)
      .subscribe((res: any) => {
        this.testorgList = res.items;
        // if (code === 0) {
        //   this.getTestPeriodList(0);
        //   this.applicationForm.get('testOrgId').setValue(0);
        // }else{
        //   this.applicationForm.get('testOrgId').setValue(res.data.testPeriodId);
        // }
      });
  }

  getTestPeriodList(value: any) {
    this.api.getTestPeriod(1, this.applicationForm.get('testOrgId').value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
        // if (code === 0) {
        //   this.applicationForm.get('testPeriodId').setValue(0);
        // } else {
        //   this.applicationForm.get('testPeriodId').setValue(res.data.testPeriodId);
        // }
      });
  }

  getDisabilityList() {
    this.api.disability(1)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
      });
  }

  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  agreeCheck(e: any) {
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }

  downloadFileDisab() {
    this.api.downloadFile(this.appId).subscribe({
      next: async (res: any) => {
        const contentType = res.headers.get('Content-Type') || 'application/octet-stream';
        const contentDisposition = res.headers.get('Content-Disposition');

        // Extract filename from Content-Disposition header if available
        let filename = 'downloaded_file';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }

        // Create a Blob and download link
        const blob = new Blob([res.body], { type: contentType });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename;
        document.body.appendChild(link); // Append to DOM to trigger download in some browsers
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      error: async (err: any) => {
        const errorMessage = JSON.parse(await err.error.text()).message;
        const notificationMessage = this.translate.currentLang === 'ru' ? 'Ошибка' : 'Қате';
        this._notifications.create(notificationMessage, errorMessage, NotificationType.Error, 5000);
      }
    });
  }
  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      const disabilityDocPhoto = this.applicationForm.get('disabilityDocPhoto');
      disabilityDocPhoto?.get('filename')?.setValue(this.selectedFile.name);
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        const base64Data = (reader.result as string).replace(/^data:.*;base64,/, '');
        disabilityDocPhoto?.get('data')?.setValue(base64Data);
        console.log(base64Data);
      };
    }
  }


  postForm() {
    if (this.applicationForm.get('isHasDisability')?.value === false){
      this.clearDisability();
    }
    let datas = {
      ...this.applicationForm.value,
      disabilityHelperInfo: this.disabilityHelperInfo.value
    };
    this.api.appItemCitizen(this.appId, datas)
      .subscribe((res: any) => {
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();
        }, 200);
      },(err: any) =>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  clearDisability() {
    this.applicationForm.patchValue({
      disabilityId: 0,
      isDisabilityHelperRequired: false,
      disabilityDocPhoto: {
        filename: null,
        data: null
      }
    });
    this.disabilityHelperInfo.patchValue({
      iin: '',
      phoneNumber: '',
      specialityName: '',
      lastname: '',
      patronymic: '',
      firstname: ''
    });
  }


}
