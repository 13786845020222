import { Component, OnInit, isDevMode } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import {NotificationsService, NotificationType} from 'angular2-notifications';

@Component({
  selector: 'app-p-pvo',
  templateUrl: './p-pvo.component.html',
  styleUrls: ['./p-pvo.component.css']
})
export class PPvoComponent implements OnInit {
  items = [];
  pageOfItems: Array<any>;
  modal = false;
  kaspiOrderId = 0;
  quantity = 1;
  paymentUrl: string;
  loading = false;
  id: any;
  test: any;
  moduleId: any;
  testList  = [];
  isCollapsed = true;
  previousLength = 0;
  deleteModal = false;
  appId: any;
  applications = [];

  constructor(
    private api: ApiService,
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
  ) {
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });
  }

  ngOnInit(): void {
    this.test = JSON.parse(sessionStorage.getItem('info'));
    this.getApplicationsList();
  }
  ngDoCheck() {
    if (this.testList?.length !== this.previousLength) {
      this.previousLength = this.testList.length;
      this.testList.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateB - dateA; // Сортировка от новых к старым
      });
    }
  }
  getApplicationsList(){
    this.testList = [];
    const requests = [
      this.getTests()
    ];

    let completedRequests: any = 0;

    const incrementAndSort = () => {
      completedRequests++;
      if (completedRequests === requests.length) {
        // Все запросы завершены, выполняем сортировку
        this.testList.sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB; // Сортировка от новых к старым
        });
      }
    };

    requests.forEach((request: any) => {
      request.subscribe({
        next: (res: any) => {
          res.items.forEach((item: any) => {
            this.testList.push(item); // Добавляем элементы в общий массив
          });
          incrementAndSort();
        },
        error: (err) => {
          console.error('Ошибка запроса:', err);
          incrementAndSort(); // Увеличиваем счётчик даже при ошибке
        },
      });
    });
  }

  getTests(){
    this.api.getTestTypegroup(1)
      .subscribe({
        next: (res: any) => {
          res.items.forEach((e: any) => {
            this.testList.push(e);
          });

        }, error: (err) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });
  }
  links(){
    this.router.navigate([`/profile/prob-test/1/${this.id}/`, this.id]);
  }
  showDeleteModal(applicationId: any, moduleId: any){
    this.deleteModal = true;
    this.moduleId = moduleId;

    this.appId = applicationId;
  }
  deleteProb(){
    this.api.deleteProbEnt(this.testList[0].id)
      .subscribe((res: any) => {
        this.deleteModal = false;
        this.getApplicationsList();
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.deleteModal = false;
        this.loading = false;
      });
  }

}







































  //   items: any;
//   pageOfItems: Array<any>;
//   modal = false;
//   kaspiOrderId = 0;
//   quantity = 1;
//   paymentUrl: string;
//   loading = false;
//
//   test: any;
//
//   constructor(private api: ApiService, public router: Router, public translate: TranslateService) {
//     //console.log(this.router.getCurrentNavigation().extras.state);
//   }
//
//   ngOnInit(): void {
//     this.test = JSON.parse(sessionStorage.getItem('info'));
//     this.getTestTries();
//   }
//
//   getTestTries() {
//     let token = localStorage.getItem('token');
//     if (token != null) {
//       this.api.getPvoTestTries(token).subscribe({
//         next: (res: any) => {
//           this.items = res.items;
//         }
//       });
//     }
//   }
//
//   link(p: any) {
//
//     const isMobile = localStorage.getItem('isMobile');
//
//     if (!isDevMode()) {
//       if(isMobile){
//         window.location.href = 'https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href;
//       }else{
//         window.open('https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href);
//       }
//     }
//     if(isDevMode()) {
//       if(isMobile){
//         window.location.href = 'https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href;
//       }else{
//         window.open('https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&url=' + window.location.href);
//       }
//     }
//
//
//   }
//
//   onChangePage(pageOfItems: Array<any>) {
//     this.pageOfItems = pageOfItems;
//   }
//
//   createOrder() {
//     this.loading = true;
//     let token = localStorage.getItem('token');
//     let body = {
//       paymentProviderTypeId: 2,
//       returnUrl: "",
//       quantity: this.quantity
//     };
//     if (token != null) {
//       this.api.createOrderProbPvo(token, body).subscribe({
//         next: (res: any) => {
//           this.loading = false;
//           this.kaspiOrderId = res.data.orderId;
//         }, error: (err) => {
//           this.loading = false;
//         }
//       })
//     }
//
//   }
//
//   closeModal() {
//     this.kaspiOrderId = 0;
//     this.quantity = 0;
//     this.getTestTries();
//     this.modal = false;
//   }
//
// }
