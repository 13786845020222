import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from '../../../../../service/message.service';

@Component({
  selector: 'app-zayav-prob-ent',
  templateUrl: './zayav-prob-ent.component.html',
  styleUrls: ['./zayav-prob-ent.component.css'],
})
export class ZayavProbEntComponent implements OnInit {
  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  showParentData = false;
  applicationAgree = false;
  applicationAgreeParent = false;
  textModal = false;
  blockBtn = false;
  rulesEnt = false;
  rulesProbUniversalData: any;
  phoneNumber: any;
  iin: any;
  test: any;
  items: any;
  id: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  testLanguageList = [];
  sabjPairList = [];
  testTypeEntList = [];
  collegList = [];
  eduList = [];
  varlist = [];

  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public router: Router,
    private rules: MessageService,

  ) {
    this.router = router;
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });

    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      oblId: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      profileSubjectPairId: new FormControl(0, Validators.required),
      testLanguageId: new FormControl(0, Validators.required),
      testTypeId: new FormControl(0, Validators.required),
      collegeSpecId: new FormControl(0, Validators.required),
      eduProgramId: new FormControl(0, Validators.required),
      applicationAgree: new FormControl({value: false, disabled: true})
    });
  }

  ngOnInit(): void {
    this.test = JSON.parse(sessionStorage.getItem('info'));

    this.rules.getProbUniversalSog().subscribe(res=> this.rulesProbUniversalData = res);

    this.getTestTypeForEnt();
    this.applicationForm.get('testTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('profileSubjectPairId').setValue(0);
      this.applicationForm.get('collegeSpecId').setValue(0);
      this.applicationForm.get('eduProgramId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.sabjPairList = [];
      this.collegList = [];
      this.eduList = [];
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('profileSubjectPairId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('collegeSpecId').valueChanges.subscribe((value) => {
      this.applicationForm.get('eduProgramId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.eduList = [];
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.applicationForm.get('eduProgramId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.testLanguageList = [];
      this.varlist = [];
    });
    this.getObl();

    this.api.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
    });


  }
  getTestTypeForEnt(){
    this.api.getTestTypeForEnt(this.id)
      .subscribe((res: any) => {
        this.testTypeEntList = res.items;
    });
  }
  changeEnt(){
    if (this.applicationForm.get('testTypeId').value == 62) {
      this.profileSubjPair();
    }else{
      this.collegSpec();
    }
  }
  profileSubjPair(){
    this.api.getProfileSubjPair(this.applicationForm.get('testTypeId').value)
      .subscribe((res: any) => {
        this.sabjPairList = res.items;
        this.testLanguageList = [];
        this.varlist = [];
        this.applicationForm.get('testLanguageId')!.setValue(0);
      });
  }

  profileLangPair(code: any){
    this.api.getProfileLangPair(this.applicationForm.get('testTypeId').value, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.testLanguageList = res.items;
        if (code === 0){
          this.applicationForm.get('testLanguageId')!.setValue(0);
        }
      });
  }

  collegSpec(){
    this.api.getCollege(this.applicationForm.get('testTypeId').value)
      .subscribe((res: any) => {
        this.collegList = res.items;
      });
  }

  eduProgram() {
    this.api.getEduProgramForPobEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('collegeSpecId').value)
      .subscribe((res: any) => {
        this.eduList = res.items;
      });
  }

  testLanguageTipo(){
    this.api.getTestLanguageTipo(this.applicationForm.get('testTypeId').value, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.testLanguageList = res.items;
      });
  }

  varInfoEntList(){
    if (this.applicationForm.get('testTypeId').value == 62 && this.applicationForm.get('testLanguageId').value != 0){
      this.api.varInfoEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('profileSubjectPairId').value, this.applicationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varlist = res.items;
        });
    } else {
      this.api.varInfoTypoEnt(this.applicationForm.get('testTypeId').value, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('testLanguageId').value)
        .subscribe((res: any) => {
          this.varlist = res.items;
        });
    }

  }

  getObl(){
    this.api.getObls(2)
      .subscribe((res: any) => {
        this.obls = res.items;
        this.testPeriodList = [];
        this.applicationForm.get('testOrgId')!.setValue(0);
        this.applicationForm.get('testPeriodId')!.setValue(0);
      });
  }
  getTestOrg(code: any){
    this.api.getTestOrgs(2, this.applicationForm.get('oblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        this.testPeriodList = [];
        this.applicationForm.get('testPeriodId')!.setValue(0);
        if (code === 0){
          this.applicationForm.get('testOrgId')!.setValue(0);
        }
      });
  }

  getTestPeriod(code: any){
    this.api.getTestPeriods(2, this.applicationForm.get('testOrgId')!.value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
        if (code === 0){
          this.applicationForm.get('testPeriodId')!.setValue(0);
        }
      });
  }
  postForm(){
    this.loading = true;
      this.api.postDataEntProb(this.applicationForm.value)
        .subscribe({
        next: (res: any) => {
          this.loading = false;
            this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
          if(res.data.paymentTypeId == 2 && res.data.testStatusId != 9){
            this.router.navigate([`profile/applications/payment`], {  queryParams: { id: res.data.id, type: 7}});
          }else{
            window.location.href = '/profile/prob-test/2/2';
          }
        },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });
  }

  agreeCheck(e: any){
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }
  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  closeAgreeModal(){
    this.textModal = false;
    this.applicationAgree = false;
    this.applicationAgreeParent = false;
  }
}
