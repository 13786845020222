import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  authorizeToken(token: string, isRegistrationComplete: string): boolean {
    localStorage.setItem('token', token);
    localStorage.setItem('isRegistrationComplete', isRegistrationComplete);
    return true;
  }

  getTok(): any {
    let headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
    return headers;
  }
  getHeaderAuth(): any {
    if (localStorage.getItem('token')){
      let headers = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

  getOnlineTestHeaderAuth(token): any {
    if(token){
      let headers = {
        headers: {
          'Online-Test-Token': token
        }
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

  getHeaderNotAuth(): any {
    return {};
  }
}
