import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-konkurs-results',
  templateUrl: './konkurs-results.component.html',
  styleUrls: ['./konkurs-results.component.css']
})
export class KonkursResultsComponent implements OnInit {

  @Input() app;

  loading = false;
  isCollapsed = true;

  appList: any;
  konkursYear: any;
  showGrantData = false;
  showErr = false;

  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.getKonkursResults();

  }

  uvedomlenie(certificateDownLoadUrl: any){
    let certifLink = certificateDownLoadUrl;
    window.open(certifLink, "_blank");
  }

  getKonkursResults(){
    // this.api.getKonkursResults()
    //   .subscribe((res: any) => {
    //     this.appList = res.items;
    //   },(err: any) => {
    //     this.showErr = true;
    // });
  }

  checkGrant(studentTestId, iin, konkursYear, testType){
    this.loading = true;
          this.api.downloadGrant(studentTestId, iin, konkursYear, testType)
            .subscribe((res: any) => {
              // let certifLink = res.data.certificateDownLoadUrl;
              // window.open(certifLink, "_blank");
              window.location.href = res.data.certificateDownLoadUrl;
              this.loading = false;
            });

      }

}
