<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- Header -->

<div *ngIf="!isMobile" class="text-xl z-10 relative justify-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16 flex nct-text">

    <div (click)="showSideMenu = !showSideMenu" class="hamburger">
        <svg xmlns="http://www.w3.org/2000/svg" id="light" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512"><g><path d="m21.5 24h-19c-1.379 0-2.5-1.122-2.5-2.5v-19c0-1.378 1.121-2.5 2.5-2.5h19c1.379 0 2.5 1.122 2.5 2.5v19c0 1.378-1.121 2.5-2.5 2.5zm-19-23c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z"/></g><g><path d="m16.5 8h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m16.5 12.5h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g><g><path d="m16.5 17h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z"/></g></svg>
    </div>

    <div class="">
        <a href="/auth"><img src="../../../assets/images/logo-wo-words.png" alt="" class="logo "></a>
    </div>

    <div class="nct-txt">
        {{ 'NCT' | translate }}
    </div>

</div>

<div class="container mx-auto flex">
    <div class="" *ngIf="!isMobile">
        <div [ngClass]="{'side-menu-open': showSideMenu}" class="w-auto h-full inline-block side-menu">
            <ul class="border-r mt-4 pr-4 border-black">
                <li [routerLink]="'/profile/im'" class="flex p-1 hover:bg-gray-300 cursor-pointer">
                    <i class="pi pi-user w-4 mr-2" style="padding: 4px;"></i>
                   {{ 'MyProfile' | translate }}
                </li>
                <li [routerLink]="'/profile/applications'" class="flex p-1 hover:bg-gray-300 cursor-pointer">
                    <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512"><path d="m106 512h300c24.814 0 45-20.186 45-45v-317h-105c-24.814 0-45-20.186-45-45v-105h-195c-24.814 0-45 20.186-45 45v422c0 24.814 20.186 45 45 45zm60-301h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h120c8.291 0 15 6.709 15 15s-6.709 15-15 15h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15z"/><path d="m346 120h96.211l-111.211-111.211v96.211c0 8.276 6.724 15 15 15z"/></svg>
                    {{ 'PostApplication' | translate }}
                </li>
                <!-- <li [routerLink]="'/profile/history'" class="flex relative p-1 hover:bg-gray-300 cursor-pointer">
                    <svg class="w-4 mr-2" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M381.7,225.9c0-97.6-52.5-130.8-101.6-138.2c0-0.5,0.1-1,0.1-1.6c0-12.3-10.9-22.1-24.2-22.1c-13.3,0-23.8,9.8-23.8,22.1   c0,0.6,0,1.1,0.1,1.6c-49.2,7.5-102,40.8-102,138.4c0,113.8-28.3,126-66.3,158h384C410.2,352,381.7,339.7,381.7,225.9z"/><path d="M256.2,448c26.8,0,48.8-19.9,51.7-43H204.5C207.3,428.1,229.4,448,256.2,448z"/></g></svg>
                    {{ 'Messages' | translate }}
                    <div class="red-dot">
                        2
                    </div>
                </li> -->
<!--                <li [routerLink]="'/profile/helper'" class="flex p-1 hover:bg-gray-300 cursor-pointer" *ngIf="isDev">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 mr-2" viewBox="0 0 24 24"><path fill="currentColor" d="M12 23q-2.8 0-5.15-1.275T3 18.325V20q0 .425-.288.713T2 21t-.712-.288T1 20v-4q0-.425.288-.712T2 15h4q.425 0 .713.288T7 16t-.288.713T6 17H4.525q1.2 1.8 3.163 2.9T12 21q3.525 0 6.063-2.35t2.887-5.775q.05-.4.35-.638T22 12q.425 0 .725.263t.25.637q-.175 2.125-1.1 3.962t-2.4 3.2t-3.387 2.15T12 23m0-20Q8.475 3 5.938 5.35T3.05 11.125q-.05.4-.35.638T2 12q-.425 0-.725-.262t-.25-.638q.175-2.125 1.1-3.962t2.4-3.2t3.388-2.15T12 1q2.8 0 5.15 1.275t3.85 3.4V4q0-.425.288-.712T22 3t.713.288T23 4v4q0 .425-.288.713T22 9h-4q-.425 0-.712-.288T17 8t.288-.712T18 7h1.475q-1.2-1.8-3.162-2.9T12 3m0 15q.525 0 .888-.363t.362-.887t-.363-.9t-.887-.375t-.888.363t-.362.887t.363.9T12 18m2-6.25q.875-.875 1.163-1.412t.287-1.288q0-1.4-1-2.225T12 6q-1 0-1.775.413T8.95 7.625q-.2.325-.062.7t.487.525t.675-.025t.575-.475t.6-.475T12 7.7q.675 0 1.138.388t.462 1.037q0 .425-.2.85t-.85 1q-.625.55-.937 1.075t-.438 1.2q-.05.375.2.663t.65.287q.375 0 .625-.288t.35-.662q.1-.425.338-.75t.662-.75"/></svg>-->
<!--                    {{ 'Помощник' | translate }}  -->
<!--                </li>-->
                <li [routerLink]="'/profile/history'" class="flex p-1 hover:bg-gray-300 cursor-pointer">
                    <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 551.13 551.13" viewBox="0 0 551.13 551.13"><path d="m275.531 172.228-.05 120.493c0 4.575 1.816 8.948 5.046 12.177l86.198 86.181 24.354-24.354-81.153-81.136.05-113.361z"/><path d="m310.011 34.445c-121.23 0-221.563 90.033-238.367 206.674h-71.644l86.114 86.114 86.114-86.114h-65.78c16.477-97.589 101.355-172.228 203.563-172.228 113.966 0 206.674 92.707 206.674 206.674s-92.707 206.674-206.674 206.674c-64.064 0-123.469-28.996-162.978-79.555l-27.146 21.192c46.084 58.968 115.379 92.808 190.124 92.808 132.955 0 241.119-108.181 241.119-241.119s-108.164-241.119-241.119-241.12z"/></svg>
                    {{ 'MyHistory' | translate }}
                </li>
                <li [routerLink]="'/profile/prob-test'" class="flex p-1 hover:bg-gray-300 cursor-pointer">
                    <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512"><path d="m106 512h300c24.814 0 45-20.186 45-45v-317h-105c-24.814 0-45-20.186-45-45v-105h-195c-24.814 0-45 20.186-45 45v422c0 24.814 20.186 45 45 45zm60-301h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h120c8.291 0 15 6.709 15 15s-6.709 15-15 15h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15z"/><path d="m346 120h96.211l-111.211-111.211v96.211c0 8.276 6.724 15 15 15z"/></svg>
                    {{ 'ProbTest' | translate }}
                </li>
                <li (click)="exit()" class="flex p-1 hover:bg-gray-300 cursor-pointer">
                    <i class="pi pi-power-off w-4 mr-2" style="padding: 4px;"></i>
                    {{ 'Exit' | translate }}
                </li>
            </ul>
        </div>
        <div (click)="showSideMenu = !showSideMenu" [ngClass]="{'side-menu-overlay': showSideMenu}" class="inline-block">
    </div>
    </div>

    <div class="w-full h-full p-4 mt-12 ml-4 border border-black" [ngClass]="isMobile ?'main-content-without-menu': 'main-content'">
        <div *ngIf="router.url == '/profile/history'">
            <div class="mb-2 w-full text-center">
                <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700" href="../../../assets/refund_instr_ru.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">Инструкция  по возврату денежных средств</p></a>
                <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700" href="../../../assets/refund_instr_kz.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">Төлемдерді жеке тұлғаларға қайтару нұсқаулығы</p></a>
            </div>
        </div>

<!--      <breadcrumb *ngIf="!isMobile">-->
<!--        <ol class="breadcrumb">-->
<!--          <ng-template ngFor let-route [ngForOf]="breadcrumbs" let-last="last">-->
<!--            <li *ngIf="!last" class="breadcrumb-item">-->
<!--              <a href="" [routerLink]="[route.url]">{{ route.label | translate }}</a>-->
<!--            </li>-->
<!--            <li *ngIf="last" class="breadcrumb-item active" aria-current="page">{{ route.label | translate }}</li>-->
<!--          </ng-template>-->
<!--        </ol>-->
<!--      </breadcrumb>-->
      <breadcrumb *ngIf="!isMobile" #parent>
        <ol class="breadcrumb">
          <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
            <li *ngIf="!route.terminal" class="breadcrumb-item">
              <a href="" [routerLink]="[route.url]">{{ route.displayName | translate }}</a>
            </li>
            <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">{{ route.displayName | translate }}</li>
          </ng-template>
        </ol>
      </breadcrumb>
        <router-outlet></router-outlet>
    </div>
</div>
