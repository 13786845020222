<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- <p class="text-center text-3xl font-hairline text-blue-500 mb-4">{{ 'ForNqtSetData' | translate }}:</p> -->
<!--  <p class="my-2 text-xl text-center"> {{ 'PayForTry' | translate}}</p> -->
<form action="" [formGroup]="applicationForm">
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'PersonalData' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="flex py-1">
        <div class="w-1/3">
            {{ 'IIN' | translate }}
        </div>
        <div class="w-2/3">
            <input readonly disabled (keyup)="changeIIN()" [mask]="iin" formControlName="iin" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>
    <!-- <div (click)="findData(applicationForm.get('iin').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
        {{ 'SearchByIIN' | translate }}
    </div> -->
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Surname' | translate }}
        </div>
        <div class="w-2/3">
            <input disabled readonly formControlName="lastname" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Name' | translate }}
        </div>
        <div class="w-2/3">
            <input disabled readonly formControlName="firstname" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'Patronymic' | translate }}
        </div>
        <div class="w-2/3">
            <input disabled readonly formControlName="patronymic" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'YourEmail' | translate }}
        </div>
        <div class="w-2/3">
            <input disabled readonly formControlName="email" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'PhoneNumber' | translate }}
        </div>
        <div class="w-2/3">
            <input [mask]="phone" formControlName="phoneNumber" class="border w-1/2 h-6 rounded" type="text">
        </div>
    </div>

    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'Concurs' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'DirClaim' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="eduTypeId" (change)="getSubjectsLang()">
                <option [selected] [disabled] value="-1">
                    {{ 'Выберите тип организации' | translate }}
                </option>
                <option *ngFor="let obl of eduType" [value]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
        </div>
    </div>


    <div class="">
        {{ 'DirKonkurs' | translate }}:
    </div>
    <div class="">
        <div class="">
            <input formControlName="bin2" class="w-full border rounded h-6 text-center" placeholder="БИН/БСН" type="text">
        </div>
        <div (click)="searchJob2(applicationForm.get('bin2').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
            {{ 'SearchByBIN' | translate }}
        </div>
    </div>
    <!-- <div class="">
        {{ 'Organization' | translate }}:
    </div> -->
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'DirPosition' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="firstLevelKato" (change)="getKATOsList2(0, $event.target.value, true)">
                <option [selected] [disabled] value="-1">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let obl of KATOOblList" [value]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
            <div class="" *ngFor="let kat of KATOs2; let i = index">
                <select class="border mt-2" (change)="getKATOsList2(i, $event.target.value,  false)">
                    <option [disabled] value="">
                        --
                    </option>
                    <option [selected]="pseudoId2[i+1] == subject.id" *ngFor="let subject of kat; let j = index" [value]="j">
                        <span *ngIf="translate.currentLang == 'kz'">{{ subject.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ subject.name.ru }}</span>
                    </option>
                </select>
            </div>
        </div>
    </div>
    <select formControlName="pretendedJobId" class="w-full border my-1" name="">
        <option selected disabled value="-1">
            {{ 'WorkPlace' | translate }}
        </option>
        <option [value]="item.id" *ngFor="let item of jobList2">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
        </option>
    </select>


    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'Work' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>

    <div class="">

        <div class="">
            <input formControlName="bin" class="w-full border rounded h-6 text-center" placeholder="БИН/БСН" type="text">
        </div>
        <div (click)="searchJob(applicationForm.get('bin').value)" class="w-full bg-blue-700 rounded border-dark border my-4 cursor-pointer text-center py-1 hover:bg-blue-500 text-lg text-white font-bold tracking-widest">
            {{ 'SearchByBIN' | translate }}
        </div>
    </div>
    <div class="">
        {{ 'Organization' | translate }}:
    </div>
    <div class="flex py-3">
        <div class="w-1/3">
            {{ 'WorkPlace' | translate }}:
        </div>
        <div class="w-2/3">
            <select class="border" formControlName="firstLevelKato" (change)="getKATOsList(0, $event.target.value, true)">
                <option [selected] [disabled] value="-1">
                    {{ 'SelectObl' | translate }}
                </option>
                <option *ngFor="let obl of KATOOblList" [value]="obl.id">
                    <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                </option>
            </select>
            <div class="" *ngFor="let kat of KATOs; let i = index">
                <select class="border mt-2" (change)="getKATOsList(i, $event.target.value,  false)">
                    <option [disabled] value="">
                        --
                    </option>
                    <option [selected]="pseudoId[i+1] == subject.id" *ngFor="let subject of kat; let j = index" [value]="j">
                        <span *ngIf="translate.currentLang == 'kz'">{{ subject.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ subject.name.ru }}</span>
                    </option>
                </select>
            </div>

    </div>
    </div>
    <select formControlName="currentJobId" class="w-full border my-1" name="">
        <option selected disabled value="-1">
            {{ 'WorkPlace' | translate }}
        </option>
        <option [value]="item.id" *ngFor="let item of jobList">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
        </option>
    </select>
    <div class="flex mb-4">
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
        <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'TestParams' | translate }}
        </div>
        <div class="w-1/5 h-1 bg-blue-300 mt-4">
        </div>
    </div>
    <div class="w-full">
        <select (change)="this.getTestOrgList();" formControlName="oblId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'SelectObl' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of oblList">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select (change)="this.getTestPeriodList();" formControlName="testOrgId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestPlace' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of testOrg">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
        <select formControlName="testPeriodId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestTime' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of testPeriod">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.testDate }} ({{ 'FreePlaceCount' | translate }}: {{ item.freePlaceCount }}) </span>
            </option>
        </select>
        <select formControlName="testLangId" class="w-full border my-1" name="">
            <option selected disabled value="-1">
                {{ 'TestLang' | translate }}
            </option>
            <option [value]="item.id" *ngFor="let item of langList">
                <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
                <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            </option>
        </select>
    </div>
    <button (click)="modalAgreement = true" class="btn btn-primary w-full mt-8">
        {{ "Continue" | translate }}
      </button>

      <div *ngIf="modalAgreement === true" class="overlay">
        <div class="modal-window">
          <div class="relative">
            <div class="modal-sub-header">
              {{ "PeredPod" | translate }}
            </div>
            <div
              (click)="modalAgreement = false"
              class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold"
            >
              X
            </div>
          </div>
          <div (scroll)="onScrollEnd($event)" class="modal-text">
                <div
                  *ngIf="translate.currentLang === 'ru'"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      sogData.agreement.contentRus
                    )
                  "
                ></div>
                <div
                  *ngIf="translate.currentLang === 'kz'"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(
                      sogData.agreement.contentKaz
                    )
                  "
                ></div>
          </div>

          <div class="text-center">
            <input
            formControlName="applicationAgree"
              (change)="agreeCheck($event)"
              type="checkbox"
              id="get-agree"
              class="get-agreement"
            />
            <label class="ml-2" for="get-agree">{{
              "RulesText-3" | translate
            }}</label>
          </div>

          <button
            [disabled]="!(applicationForm.get('applicationAgree').value)"
            (click)="postForm()"
            class="btn btn-primary w-full"
          >
            {{ "PostApplication" | translate }}
          </button>
        </div>
      </div>
</form>
