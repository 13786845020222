import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../service/api.service'
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Form, FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'src/app/service/message.service';

@Component({
  selector: 'app-im',
  templateUrl: './im.component.html',
  styleUrls: ['./im.component.css']
})
export class ImComponent implements OnInit {

  @Output() onChangedPassword = new EventEmitter<number>();
  @Output() onChangedEmail = new EventEmitter<number>();
  @Output() onEditProfile = new EventEmitter<number>();

  change(page: any) {
    console.log(page);
    this.onChangedPassword.emit(page);
  }

  changeEmail(page: any) {
    this.onChangedEmail.emit(page);
  }

  changePassForm: FormGroup;
  profileInfo: any;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  nationList: any;
  citizenshipList: any;
  sexList: any;
  loading = false;

  messages:any[];

  constructor(public router: Router,
    private fb: FormBuilder,
    private api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    public sanitizer:DomSanitizer,
    private messageService: MessageService) { }

  ngOnInit(): void {

    setInterval(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        this.router.navigate(['/auth']); // Выкидываем на логин, если токен пропал
      }
    }, 5000); // Проверяем каждые 5 секунд
    this.getProfile();

    this.messageService.getMessages().subscribe({
      next:(res:any)=>{
        this.messages = res.items;
      }
    });
  }

  getProfile(){

    this.api.profileInfo()
      .subscribe((res: any) => {
        console.log("get info")
        this.profileInfo = res.data;
        this.lastname = this.profileInfo.lastname;
        this.firstname = this.profileInfo.firstname;
        this.patronymic = this.profileInfo.patronymic;
        this.phoneNumber = this.profileInfo.phoneNumber;
        this.iin = this.profileInfo.iin;
        this.email = this.profileInfo.email;

        this.changePassForm = this.fb.group({
          email: [this.profileInfo.email]
        });
      });
  }

  showChangePassComponent() {
    this.loading = true;

    this.email = this.changePassForm.value;
    this.api.changePassword(this.email)
      .subscribe((res: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Пожалуйста', 'проверьте вашу почту', NotificationType.Success, 5000) : this._notifications.create('Пожалуйста', 'проверьте вашу почту', NotificationType.Success, 5000);
      }, (err: any) => {
        console.log(err);
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.loading = false;
      });
  }

  updateProfileInfo(){
    this.loading = true;
    this.api.updateProfileInfo().subscribe({
      next:(res)=>{
        this.loading = false;
        this.getProfile();
      },
      error:(err)=>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      }
    });

  }

}
