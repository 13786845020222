import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from '../../../../../service/message.service';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-zayav-prob-nqt',
  templateUrl: './zayav-prob-nqt.component.html',
  styleUrls: ['./zayav-prob-nqt.component.css'],
})
export class ZayavProbNqtComponent implements OnInit {
  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  test: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  items: any;
  id: any;
  employmentTypeId: any;
  testTypeId: any;
  employmentTypeList: any;
  subjectId: any;
  eduTypeId: any;
  headerTypeId: any;
  testLanguageId: any;
  testTypeValue: any;
  showForeignLanguage = false;
  textModal = false;
  rulesProbUniversalData: any;
  blockBtn = false;
  rulesEnt = false;
  applicationAgree = false;
  applicationAgreeParent = false;
  pSubjects = [];
  headerTypes = [];
  specList = [];
  varInfoList = [];
  entryList = [];
  subjectList = [];
  langList = [];
  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public router: Router,
    private rules: MessageService
  ) {
    this.router = router;
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      oblId: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      headerTypeId: new FormControl(0, Validators.required),
      testTypeId: new FormControl(0, Validators.required),
      testLanguageId: new FormControl(0, Validators.required),
      subjectId: new FormControl(0, Validators.required),
      eduTypeId: new FormControl(0, Validators.required),
      entryPointId: new FormControl(0, Validators.required),
      eduLanguageId: new FormControl(0, Validators.required),
      tgoLanguageId: new FormControl(0, Validators.required),
      collegeSpecId: new FormControl(0, Validators.required),
      specialityId: new FormControl(0, Validators.required),
      employmentTypeId: new FormControl(0, Validators.required),
      applicationAgree: new FormControl({value: false, disabled: true})
    });
  }

  ngOnInit(): void {

    this.test = JSON.parse(sessionStorage.getItem('info'));
    this.rules.getProbUniversalSog().subscribe(res=> this.rulesProbUniversalData = res);

    this.getEntryPointList();
    this.getObl();

    this.api.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
    });
    this.applicationForm.get('entryPointId').valueChanges.subscribe((value) => {
      this.applicationForm.get('employmentTypeId').setValue(0);
      this.applicationForm.get('eduTypeId').setValue(0);
      this.applicationForm.get('headerTypeId').setValue(0);
      this.applicationForm.get('specialityId').setValue(0);
      this.applicationForm.get('subjectId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.subjectList = [];
      this.headerTypes = [];
      this.pSubjects = [];
      this.specList = [];
      this.langList = [];
      this.varInfoList = [];
    });
    this.applicationForm.get('testLanguageId').valueChanges.subscribe((value) => {
      this.varInfoList = [];
    });
    this.applicationForm.get('headerTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];
    });
    this.applicationForm.get('testTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('headerTypeId').setValue(0);
    });
    this.applicationForm.get('subjectId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
    });
    this.applicationForm.get('eduTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('specialityId').setValue(0);
      this.applicationForm.get('subjectId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];
      this.subjectList = [];
      this.varInfoList = [];
    });
    this.applicationForm.get('specialityId').valueChanges.subscribe((value) => {
      this.applicationForm.get('subjectId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];
    });
  }

  testTypeChange(value: any, code: any) {
    this.langList = [];
    this.applicationForm.get('testTypeId').setValue(value);
      this.applicationForm.patchValue({
        eduLanguageId: 0,
        tgoLanguageId: 0,
        eduProgramId: 0,
        testLanguageId: 0,
        employmentTypeId: 0,
        collegeSpecId: 0,
        eduTypeId: 0,
        specialityId: 0,
        subjectId: 0
      });
      const selectedEntry = this.entryList.find((element: any) => element.id == value);
      if (selectedEntry) {
        this.applicationForm.patchValue({
          entryPointId: selectedEntry.id,
          testTypeId: selectedEntry.testTypeId
        });
    }
    this.testTypeValue = value;
    this.subjectList = [];
    this.varInfoList = [];
    this.applicationForm.get('subjectId').setValue(0);
    this.getEmploymentTypeList().then(() => {
      this.emplTypesChange(this.applicationForm.get('employmentTypeId').value || 0);
    });
  }
  emplTypesChange(value: number) {
    this.subjectList = [];
    this.applicationForm.get('eduTypeId')?.setValue(0);
    this.applicationForm.get('headerTypeId')?.setValue(0);
    this.applicationForm.get('specialityId')?.setValue(0);
    this.applicationForm.get('subjectId')?.setValue(0);
    this.applicationForm.get('testLanguageId')?.setValue(0);
    this.varInfoList = [];
    if (this.applicationForm.get('testTypeId').value == 59 || this.applicationForm.get('testTypeId').value == 61) {
      this.api.getSubjList(this.applicationForm.get('testTypeId').value, value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
          if (this.applicationForm.get('subjectId').value !== 0){
            this.getTipoSubjectLang().then(() => this.getVarInfo());
          }
        });
      if (this.applicationForm.get('testTypeId').value !== 59 && this.applicationForm.get('testTypeId').value !== 61) {
        this.getTipoSubjectLang().then(() => this.getVarInfo());
      }
    } else if (this.applicationForm.get('testTypeId').value == 55) {
      this.api.getPSubjects(this.applicationForm.get('testTypeId').value, value)
        .subscribe(
          (res: any) => {
            this.pSubjects = res.items;
            this.getSpec();
          },
          (err: any) => {}
        );
    } else {
      if ((this.applicationForm.get('testTypeId').value === 56 || this.applicationForm.get('testTypeId').value === 57) && this.applicationForm.get('employmentTypeId').value !== 0) {
        this.api.getHeaderTypeProb(this.applicationForm.get('testTypeId').value, value)
          .subscribe((res: any) => {
            this.headerTypes = res.items;
            if (this.applicationForm.get('headerTypeId').value !== 0){
              this.getTipoSubjectLang().then(() => this.getVarInfo());
            }
          });
      }
    }
    if (this.applicationForm.get('testTypeId').value !== 56 && this.applicationForm.get('testTypeId').value !== 57) {
      this.getTipoSubjectLang().then(() => this.getVarInfo());
    }
  }
  async getEntryPointList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getEntryPoint()
        .subscribe({
          next: (res: any) => {
            this.entryList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.entryList = [];
            resolve();
          }
        });
    });
  }
  async getEmploymentTypeList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getEmploymentType(this.applicationForm.get('testTypeId').value)
        .subscribe({
          next: (res: any) => {
            this.employmentTypeList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.employmentTypeList = [];
            resolve();
          }
        });
    });
  }
  async getTipoSubjectLang(): Promise<void> {
    return new Promise((resolve) => {
      if (this.applicationForm.get('testTypeId').value == 55) {
        this.api.getTipoSubjectLangProb(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('eduTypeId').value,
          this.applicationForm.get('specialityId').value,
          this.applicationForm.get('subjectId').value
        ).subscribe({
          next: (res: any) => {
            this.langList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.langList = [];
            resolve();
          }
        });
      } else {
        this.getLangOZPList();
      }
    });
  }
  getSubj(){
    if (this.applicationForm.get('testTypeId').value == 55){
      this.api.getTIpoSubjList(this.applicationForm.get('testTypeId').value,
        this.applicationForm.get('employmentTypeId').value,
        this.applicationForm.get('eduTypeId').value,
        this.applicationForm.get('specialityId').value,
        this.id)
        .subscribe((res: any) => {
          this.subjectList = res.items;
          if (this.applicationForm.get('subjectId').value !== 0){
            this.getTipoSubjectLang().then(() => this.getVarInfo());
          }
        });
    }else{
      this.api.getSubjList(this.applicationForm.get('testTypeId').value, this.applicationForm.get('employmentTypeId').value)
        .subscribe((res: any) => {
          this.subjectList = res. items;
          if (this.applicationForm.get('subjectId').value !== 0){
            this.getTipoSubjectLang().then(() => this.getVarInfo());
          }
        });
    }
  }
  getSpec(){
    this.api.getSpecial
    (
      this.applicationForm.get('testTypeId').value,
      this.applicationForm.get('employmentTypeId').value,
      this.applicationForm.get('eduTypeId').value
    )
      .subscribe((res: any) => {
        this.specList = res.items;
        this.getSubj();
      });
  }
  async getLangOZPList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getLangOZP(
        this.applicationForm.get('testTypeId').value,
        this.applicationForm.get('employmentTypeId').value,
        this.applicationForm.get('subjectId').value,

      ).subscribe({
        next: (res: any) => {
          this.langList = res.items || [];
          resolve();
        },
        error: (err) => {
          this.langList = [];
          resolve();
        }
      });
    });
  }
  async getVarInfo(): Promise<void> {
    return new Promise((resolve) => {
      if (this.applicationForm.get('testTypeId').value == 55) {
        this.api.varInfoNqt(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('eduTypeId').value,
          this.applicationForm.get('specialityId').value,
          this.applicationForm.get('subjectId').value,
          this.applicationForm.get('testLanguageId').value,
          this.headerTypeId
        ).subscribe({
          next: (res: any) => {
            this.varInfoList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.varInfoList = [];
            resolve();
          }
        });
      } else {
        this.api.varInfo(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('subjectId').value,
          this.applicationForm.get('testLanguageId').value,
          this.eduTypeId,
          this.headerTypeId
        ).subscribe({
          next: (res: any) => {
            this.varInfoList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.varInfoList = [];
            resolve();
          }
        });
      }
    });
  }
  getObl(){
    this.api.getObls(3)
      .subscribe((res: any) => {
        this.obls = res.items;
        this.getTestOrg(1);
        this.getTestPeriod(1);
        this.applicationForm.get('testOrgId')!.setValue(0);
        this.applicationForm.get('testPeriodId')!.setValue(0);
      });
  }
  getTestOrg(code: any){
    this.api.getTestOrgs(3, this.applicationForm.get('oblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        this.testPeriodList = [];
        this.applicationForm.get('testPeriodId')!.setValue(0);
        if (code === 0){
          this.applicationForm.get('testOrgId')!.setValue(0);
        }
      });
  }
  getTestPeriod(code: any){
    this.api.getTestPeriods(3, this.applicationForm.get('testOrgId')!.value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
        if (code === 0){
          this.applicationForm.get('testPeriodId')!.setValue(0);
        }
      });
  }
  postForm(){
    this.loading = true;
    this.api.postData(this.applicationForm.value)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.translate.currentLang === 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
          if (res.data.paymentTypeId === 2 && res.data.testStatusId !== 9){
            this.router.navigate([`profile/applications/payment`], {  queryParams: { id: res.data.id, type: 10}});
          }else{
            window.location.href = '/profile/prob-test/3/2';
          }
        },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang === 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });
  }
  agreeCheck(e: any){
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }
  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  closeAgreeModal(){
    this.textModal = false;
    this.applicationAgree = false;
    this.applicationAgreeParent = false;
  }
}
