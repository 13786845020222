import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-tipo-sf',
  templateUrl: './e-tipo-sf.component.html',
  styleUrls: ['./e-tipo-sf.component.css']
})
export class ETipoSfComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testOblList = [];
  testDateList = [];
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask: string = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  applicationAgree = false;
  applicationId: any;
  appShortInfo: any;
  incriment: number = 0;

  disabilityList: any;
  fileIdToDelete: any;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  CSID: any;
  NID: any;
  year = '0000';
  collegeSpecialityList = [];
  eduProgramList = [];
  specialDisciplines = [];
  dopSertifsList: any;
  dataofbday = 0;
  showParentData = false;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService){}

    ngOnInit(): void {
      this.loading = true;
      this.applicationId = this.route.snapshot.queryParamMap.get('id');
      this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

      // Получение данныъ из профиля
      this.api.profileInfo()
        .subscribe((res: any) => {
          this.getApplicationInfo();

          this.lastname = res.data.lastname;
          this.firstname = res.data.firstname;
          this.patronymic = res.data.patronymic;
          this.phoneNumber = res.data.phoneNumber;
          this.iin = res.data.iin;
          this.email = res.data.email;

          if (res.data.currentAge < 18) {
            this.showParentData = true;
          } else {
            this.showParentData = false;
          }

          if(res.data.sexId == 0){
            this.canEditSexId = true;
          }
          if(res.data.nationId == 0 || res.data.nationId == 99){
            this.canEditNationId = true;
          }
          if(res.data.citizenshipId == 0 || res.data.citizenshipId == 99){
            this.canEditCitizenshipId = true;
          }
          if(res.data.phoneNumber == 0){
            this.canEditPhoneNumber = true;
          }
          this.CSID = res.data.citizenshipId;
          this.NID = res.data.nationId;
          // this.applicationForm.get('sexId').patchValue(res.data.sexId);
          this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
          this.applicationForm.get('nationId').patchValue(res.data.nationId);
          this.applicationForm.get('birthDate').setValue(res.data.birthDate);
          this.getDateOfBirth(res.data.birthDate);
          // this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);


        });

        // Инициализация формы
        this.applicationForm = new FormGroup({
          seasonId: new FormControl(0),
          appTypeId: new FormControl(this.appTypeId),
          citizenshipId: new FormControl(0),
          nationId: new FormControl(0),
          foreignLangCertTypeId: new FormControl(0),
          foreignLangCertScore: new FormControl(0),
          foreignLangCertTestDate: new FormControl('0'),
          phoneNumber: new FormControl(),
          parentEmail: new FormControl(),
          sexId: new FormControl(),
          oblId: new FormControl(0),
          raionId: new FormControl(0),
          schoolId: new FormControl(0),
          birthDate: new FormControl(0),
          parentFullname: new FormControl(0),
          parentPhoneNumber: new FormControl(0),
          tipoSubjectId: new FormControl(0),
          testLangId: new FormControl(0),
          testOrgId: new FormControl(0),
          testOblId: new FormControl(0),
          testPeriodId: new FormControl(0),
          collegeSpecialityId: new FormControl(0),
          eduProgramId: new FormControl(0),
          anotherExamTypeId: new FormControl(0),
          testOrgOblId: new FormControl(0),
          schoolFinishYear: new FormControl(''),
          isHasDisability: new FormControl(0),
          isNeedHelperDueDisability: new FormControl(0),
          disabilityId: new FormControl(0),
          docFiles: new FormArray([]),

        });
        // язык для соритировки в запросах
        if(localStorage.getItem('Lang') == 'ru'){
          this.pageLangId = 2;
        }else{
          this.pageLangId = 1;
        }


    }

    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
      }

    getDateOfBirth(date: any){
      let today = new Date();
      this.dataofbday = this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
      // if(this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()) < 6574){
      //   this.showParentData = true;
      // }else{
      //   this.showParentData = false;
      // }
    }

    getApplicationInfo(){
      this.api.getApplicationInformationEnt(this.applicationId)
        .subscribe((res: any) => {
          this.appShortInfo = res.data;
          this.seasonId = this.appShortInfo.seasonId;
          this.getNationList();
          this.getCountryList();
          this.getForeignLangCertList();
          this.getOblList();
          this.getCollegeSpeciality();
          this.getHelper();
          this.addFiles();
          this.getSertifs();
          this.applicationForm.get('anotherExamTypeId').patchValue(this.appShortInfo.anotherExamTypeId);
          this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
          this.applicationForm.get('seasonId').patchValue(this.seasonId);
          this.applicationForm.get('parentFullname').patchValue(this.appShortInfo.parentFullname);
          this.applicationForm.get('parentPhoneNumber').patchValue(this.appShortInfo.parentPhoneNumber);
          this.applicationForm.get('parentEmail').patchValue(this.appShortInfo.parentEmail);

          if(this.appShortInfo.citizenshipId != 0 && this.CSID == 0){
            this.applicationForm.get('citizenshipId').patchValue(this.appShortInfo.citizenshipId);
          }

          if(this.appShortInfo.nationId != 0 && this.NID == 0){
            this.applicationForm.get('nationId').patchValue(this.appShortInfo.nationId);
          }

          this.applicationForm.get('foreignLangCertTypeId').patchValue(this.appShortInfo.foreignLangCertTypeId);
          this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
          for (this.incriment; this.incriment < 1; this.incriment++) {
            this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
          }
          console.log("Score ",this.applicationForm.get('foreignLangCertScore').value)

          // this.applicationForm.get('sexId').patchValue(0);
          this.applicationForm.get('sexId').setValue(this.appShortInfo.sexId);
          // console.log(this.appShortInfo.sexId);
          this.applicationForm.get('schoolFinishYear').patchValue(this.appShortInfo.schoolFinishYear);
          this.applicationForm.get('collegeSpecialityId').patchValue(this.appShortInfo.collegeSpecialityId);
          this.getEduProgram(1);
          this.applicationForm.get('testOblId').patchValue(this.appShortInfo.testOrgOblId);
          this.applicationForm.get('phoneNumber').patchValue(this.phoneNumber);
          this.applicationForm.get('tipoSubjectId').patchValue(this.appShortInfo.tipoSubjectId);
          this.applicationForm.get('schoolId').patchValue(this.appShortInfo.schoolId);
          this.applicationForm.get('foreignLangCertTestDate').patchValue(this.appShortInfo.foreignLangCertTestDate);
          // this.getLangList();

        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getSertifs(){
      this.api.getDopSertifs(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.dopSertifsList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }


    getSpecialDisciplines(code: any){
      if(code == 1){
        this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
        .subscribe((res: any) => {
          this.applicationForm.get('tipoSubjectId').setValue(this.appShortInfo.tipoSubjectId);
          this.specialDisciplines = res.items;
          this.getLangList(1);
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }else{
        this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
        .subscribe((res: any) => {
          this.applicationForm.get('tipoSubjectId').setValue(0);
          this.specialDisciplines = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }

    }

    getOblList(){
      this.api.getOblEnt(this.seasonId, this.appTypeId, this.applicationId)
        .subscribe((res: any) => {
          this.applicationForm.get('oblId').setValue(0);
          this.applicationForm.get('raionId').setValue(0);
          this.applicationForm.get('schoolId').setValue(0);
          this.oblList = res.items;
          this.getEduInfo();
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getEduInfo(){
      this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          if(res.data.oblId != 0){
            this.applicationForm.get('oblId').setValue(res.data.oblId);
            this.canEditOblId = true;
            this.api.getRaionEnt(this.seasonId, this.appTypeId, res.data.oblId)
            .subscribe((res: any) => {
              this.raionList = res.items;
              this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.appShortInfo.testOrgOblId, this.applicationId)
              .subscribe((res: any) => {
                this.testOrgList = res.items;
                  this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
                  this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
                this.getTestDate();

              },(err: any) => {
                this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
              });
            },(err: any) => {
              this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            });
            if(res.data.raionId != 0){
              this.applicationForm.get('raionId').setValue(res.data.raionId);
              this.canEditRaionId = true;
              this.getSchoolList();
              if(res.data.schoolId != 0){
                this.canEditSchoolId = true;
                this.applicationForm.get('schoolId').setValue(res.data.schoolId);
              }
            }
          }else{
            this.applicationForm.get('oblId').patchValue(this.appShortInfo.oblId);
            this.getRaionList();
            this.applicationForm.get('raionId').patchValue(this.appShortInfo.raionId);
            this.getSchoolList();
            this.applicationForm.get('schoolId').patchValue(this.appShortInfo.schoolId);
          }
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getLangList(code: any){
      if(code == 1){
        this.api.getTipoLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('tipoSubjectId').value)
        .subscribe((res: any) => {
          this.langList = res.items;
          this.applicationForm.get('testLangId').setValue(this.appShortInfo.testLangId);
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }else{
        this.api.getTipoLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('tipoSubjectId').value)
        .subscribe((res: any) => {
          this.applicationForm.get('testLangId').patchValue(0);
          this.langList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }

    }

    getTestOrg(){
      this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOblId').value, 0)
        .subscribe((res: any) => {
          this.applicationForm.get('testOrgId').setValue(0);
          this.applicationForm.get('testPeriodId').setValue(0);
          this.testDateList = [];
          this.testOrgList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getNationList(){
      this.api.getNationsEnt(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.nationsList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getCountryList(){
      this.api.getCountryEnt(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.countryList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getEduProgram(code: any){
      if(code == 1){
        this.api.eduProgram(this.seasonId, this.appTypeId, this.applicationForm.get('collegeSpecialityId').value)
        .subscribe((res: any) => {
          this.applicationForm.get('eduProgramId').setValue(this.appShortInfo.eduProgramId);
          this.eduProgramList = res.items;
          this.getSpecialDisciplines(1);
        }, (err: any) => {

        });
      }else{
        this.api.eduProgram(this.seasonId, this.appTypeId, this.applicationForm.get('collegeSpecialityId').value)
        .subscribe((res: any) => {
          this.applicationForm.get('eduProgramId').setValue(0);
          this.eduProgramList = res.items;
        }, (err: any) => {

        });
      }
    }

    getForeignLangCertList(){
      this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.foreignLangCertList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getCollegeSpeciality(){
      this.api.collegeSpeciality(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.collegeSpecialityList = res.items;
        }, (err: any) => {

        });
    }

    getRaionList(){
      this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationId)
        .subscribe((res: any) => {
          this.raionList = res.items;
          this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.appShortInfo.testOrgOblId, this.applicationId)
          .subscribe((res: any) => {
            this.testOrgList = res.items;
              this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
              this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
            this.getTestDate();

          },(err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          });
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getTestDate(){
      this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, this.applicationId)
        .subscribe((res: any) => {
          this.testDateList = res.items;
          this.applicationForm.get('testPeriodId').patchValue(this.appShortInfo.testPeriodId);
          this.loading = false;
        },(err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    getSchoolList(){
      this.api.getSchoolEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('raionId').value )
        .subscribe((res: any) => {
          this.schoolList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }




    checkSertif(id){
      if(id == 4){
        this.foreignLangCertScoreMask = '0.0';
      }
      if(id !=4){
        this.foreignLangCertScoreMask = '000';
      }
      if(id == 0){
        this.applicationForm.get('foreignLangCertScore').patchValue(0);
      }
    }

    scoreCheck(){
      if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
        this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
      }
    }

    EditApplication(){
      this.loading = true;
      this.scoreCheck();
      this.api.postEditApplicationEnt(this.applicationForm.value, this.applicationId)
        .subscribe((res: any) => {
          this.loading = false;
          this.router.navigateByUrl('/profile/history');
          setTimeout(() => {
            window.location.reload();
          }, 200);
        },(err: any) =>{
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    agreeCheck(){
      this.applicationAgree = !this.applicationAgree;
    }

    getHelper(){
      this.api.getDisabilityEnt(this.seasonId, this.appTypeId)
        .subscribe((res: any) => {
          this.disabilityList = res.items;
          let value = this.appShortInfo.isHasDisability;
          this.applicationForm.get('isHasDisability').setValue(value.toString());
          this.applicationForm.get('disabilityId').setValue(this.appShortInfo.disabilityId);
          this.applicationForm.get('isNeedHelperDueDisability').setValue(this.appShortInfo.isNeedHelperDueDisability.toString());
        }, (err: any) => {

        });
    }

    get files() : any { return this.applicationForm.get('docFiles')};

    addFiles(){
      let obj = null;
      this.appShortInfo.docFiles.forEach(element => {

        obj = this.files;

        obj.push(
          new FormGroup({
            filename: new FormControl(element.filename, []),
            id: new FormControl(element.id),
            data: new FormControl('')
          })
        );

      });
    }

    addFile() {
      let obj = null;

      obj = this.files;

      obj.push(
        new FormGroup({
          filename: new FormControl('', []),
          id: new FormControl(0),
          data: new FormControl()
        })
      );
    }

    changeListener($event, i: number) : void {
      let obj = null;

      obj = this.files;

      const file: File = $event.target.files[0];
      const myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {
        obj.controls[i].get('filename').setValue(file.name);
        let imgData = myReader.result;

        obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
      }
      myReader.readAsDataURL(file);
    }

    dwnldCheck(id: number): boolean {
      return this.files.controls[id].get('filename').value !== '';
    }

    removeFile(type: string){
      this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
      // this.fileDeleteModal = false;
      // this.editFileDeleteModal = false;
      let obj = null;

      obj = this.files;

      obj.removeAt(this.fileIdToDelete);
    }

}
