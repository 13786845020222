import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-tipo-sf',
  templateUrl: './tipo-sf.component.html',
  styleUrls: ['./tipo-sf.component.css']
})
export class TIPOSFComponent implements OnInit {

  seasonId: any;
  loading = false;
  appTypeId = 7;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  dopSertifsList: any;
  foreignLangCertScoreMask = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  year = '0000';
  applicationAgree = false;
  collegeSpecialityList = [];
  eduProgramList = [];
  specialDisciplines = [];

  disabilityList: any;
  fileIdToDelete: any;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;

  showParentData = false;
  applicationAgreeParent = false;
  dataofbday = 0;

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {

    this.seasonId = this.router.parseUrl(this.router.url).root.children.primary.segments[4].path; // Тут выбирается id сезона тестирования из массива сегментов url, править при изменении количества сегментов url
    // console.log(this.router.parseUrl(this.router.url).root.children.primary.segments); // console.log для понимания вышепроисходящего НЕ УДАЛЯТЬ

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;

        if(res.data.currentAge < 18){
          this.showParentData = true;
        }else{
          this.showParentData = false;
        }

        if(res.data.sexId == 0){
          this.canEditSexId = true;
        }
        if(res.data.nationId == 0 || res.data.nationId == 99){
          this.canEditNationId = true;
        }
        if(res.data.citizenshipId == 0 || res.data.citizenshipId == 99){
          this.canEditCitizenshipId = true;
        }
        if(res.data.phoneNumber == 0){
          this.canEditPhoneNumber = true;
        }
        this.applicationForm.get('sexId').patchValue(res.data.sexId);
        this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        this.getDateOfBirth(res.data.birthDate);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);



      });
      // Инициализация формы
      this.applicationForm = new FormGroup({
        seasonId: new FormControl(this.seasonId),
        appTypeId: new FormControl(this.appTypeId),
        citizenshipId: new FormControl(0, Validators.required),
        nationId: new FormControl(0, Validators.required),
        foreignLangCertTypeId: new FormControl(0),
        foreignLangCertScore: new FormControl('0'),
        foreignLangCertTestDate: new FormControl('0'),
        phoneNumber: new FormControl(0, Validators.required),
        sexId: new FormControl(0, Validators.required),
        oblId: new FormControl(0, Validators.required),
        raionId: new FormControl(0, Validators.required),
        schoolId: new FormControl(0, Validators.required),
        profileSubjectPairId: new FormControl(0),
        testLangId: new FormControl(0),
        testOrgOblId: new FormControl(0),
        testOrgId: new FormControl(0),
        testPeriodId: new FormControl(0),
        birthDate: new FormControl(0),
        parentFullname: new FormControl(),
        parentPhoneNumber: new FormControl(),
        parentEmail: new FormControl(),
        schoolFinishYear: new FormControl(''),
        tipoSubjectId: new FormControl(0),
        testOblId: new FormControl(0),
        collegeSpecialityId: new FormControl(0),
        eduProgramId: new FormControl(0),
        isHasDisability: new FormControl(0),
        isNeedHelperDueDisability: new FormControl(0),
        anotherExamTypeId: new FormControl(0),
        disabilityId: new FormControl(0),
        docFiles: new FormArray([]),

      });



      // язык для соритировки в запросах
      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }

      this.getNationList();
      this.getCountryList();
      this.getForeignLangCertList();
      this.getOblList();
      this.getCollegeSpeciality();
      this.getHelper();
      this.getSertifs();
      // this.getSubjectPair();
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
    }

  getDateOfBirth(date: any){
    let today = new Date();
    this.dataofbday = this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
    // if(this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()) < 6574){
    //   this.showParentData = true;
    // }else{
    //   this.showParentData = false;
    // }
  }

  getNationList(){
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList(){
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList(){
    this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getOblList(code:number = 0){
    this.api.getOblEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if(code === 0){
          this.applicationForm.get('oblId').setValue(0);
          this.applicationForm.get('raionId').setValue(0);
          this.applicationForm.get('schoolId').setValue(0);
        }

        this.oblList = res.items;
        this.getEduInfo();
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getRaionList(code:number = 0){
    this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value)
      .subscribe((res: any) => {
        if(code === 0){
          this.applicationForm.get('raionId').setValue(0);
          this.applicationForm.get('schoolId').setValue(0);
        }
        this.raionList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSchoolList(code:number = 0){
    this.api.getSchoolEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('raionId').value )
      .subscribe((res: any) => {
        if(code === 0){
          this.applicationForm.get('schoolId').setValue(0);
        }
        this.schoolList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCollegeSpeciality(){
    this.api.collegeSpeciality(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.collegeSpecialityList = res.items;
      }, (err: any) => {

      });
  }

  getEduProgram(){
    this.api.eduProgram(this.seasonId, this.appTypeId, this.applicationForm.get('collegeSpecialityId').value)
      .subscribe((res: any) => {
        this.applicationForm.get('eduProgramId').setValue(0);
        this.eduProgramList = res.items;
      }, (err: any) => {

      });
  }

  getEduInfo(){
    this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if(res.data.oblId != 0){
          this.applicationForm.get('oblId').setValue(res.data.oblId);
          this.canEditOblId = true;
          this.getRaionList();
          if(res.data.raionId != 0){
            this.applicationForm.get('raionId').setValue(res.data.raionId);
            this.canEditRaionId = true;
            this.getSchoolList();
            if(res.data.schoolId != 0){
              this.canEditSchoolId = true;
              this.applicationForm.get('schoolId').setValue(res.data.schoolId);
            }
          }
        }
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  // getSubjectPair(){
  //   this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
  //     .subscribe((res: any) => {
  //       this.subjectPairList = res.items;
  //     },(err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //     });
  // }

  getSertifs(){
    this.api.getDopSertifs(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.dopSertifsList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }


  scoreCheck(){
    if(this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9){
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
    }
  }


  getSpecialDisciplines(){
    this.api.getProfileSubjectTipoEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value)
      .subscribe((res: any) => {
        this.applicationForm.get('tipoSubjectId').setValue(0);
        this.specialDisciplines = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(){
    this.api.getTipoLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('eduProgramId').value, this.applicationForm.get('tipoSubjectId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
    });
  }

  getTestOrg(){
    this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOblId').value, 0)
      .subscribe((res: any) => {
        this.applicationForm.get('testOrgId').setValue(0);
        this.testOrgList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate(){
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, 0)
      .subscribe((res: any) => {
        this.testDateList = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id){
    if(id == 4){
      this.foreignLangCertScoreMask = '0.0';
    }
    if(id !=4){
      this.foreignLangCertScoreMask = '000';
    }
    if(id == 0){
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  agreeCheckParent(){
    this.applicationAgreeParent = !this.applicationAgreeParent;
  }

  postApplication(){
    this.loading = true;
    this.scoreCheck();
    if(this.dataofbday == 0){
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'вы не ввели дату рождения', NotificationType.Error, 5000) : this._notifications.create('Қате', 'сіз туған күніңізді енгізген жоқсыз', NotificationType.Error, 5000);
      this.loading = false;
    }else{
      this.api.postApplicationEnt(this.applicationForm.value)
      .subscribe((res: any) => {
        this.loading = false;
        if(res.data.paymentType.id == 2){
          this.router.navigate(['/profile/applications/payment'], { queryParams: { id: res.data.id } });
        }else{
          this.router.navigateByUrl('/profile/history');
        }
      },(err: any) =>{
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
    }

  }

  agreeCheck(){
    this.applicationAgree = !this.applicationAgree;
  }

  getHelper(){
    this.api.getDisabilityEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
      }, (err: any) => {

      });
  }

  get files() : any { return this.applicationForm.get('docFiles')};

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event, i: number) : void {
    let obj = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile(type: string){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

}
