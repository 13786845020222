import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ValidateEqualModule } from 'ng-validate-equal';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BreadcrumbModule } from 'angular-crumbs';
import { DatePipe } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { NgxPrintModule } from 'ngx-print';

import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotPassComponent } from './components/forgot-pass/forgot-pass.component';
import { AddApplicationComponent } from './components/add-application/add-application.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ImComponent } from './components/profile/im/im.component';
import { ApplicationsComponent } from './components/profile/applications/applications.component';
import { authConfig } from './config/authRoutes';
import { entConfig } from './config/entRoutes';
import { dirConfig } from './config/dirRoutes';
import { nqtConfig } from './config/nqtRoutes';
import { modoConfig } from './config/modoRoutes';
import { kaztestConfig } from './config/kaztestRoutes';
import { notificationConfig } from './config/notificationRoutes';
import { messagesConfig } from './config/messagesRoutes';
import { astConfig } from './config/astRoutes';
import { pvoConfig } from './config/pvoRoutes';
import { probtestConfig } from './config/probtestRoutes';
import { FromRegConfirmComponent } from './components/from-reg-confirm/from-reg-confirm.component';
import { NqtComponent } from './components/profile/applications/nqt/nqt.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangeEmailConfirmationComponent } from './components/change-email/change-email-confirmation/change-email-confirmation.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { SoComponent } from './components/profile/applications/nqt/so/so.component';
import { HistoryComponent } from './components/profile/history/history.component';
import { DoshkolaComponent } from './components/profile/applications/nqt/doshkola/doshkola.component';
import { MetodistiComponent } from './components/profile/applications/nqt/metodisti/metodisti.component';
import { TipoComponent } from './components/profile/applications/nqt/tipo/tipo.component';
import { DopComponent } from './components/profile/applications/nqt/dop/dop.component';
import { RukComponent } from './components/profile/applications/nqt/ruk/ruk.component';
import { ESoComponent } from './components/profile/applications/nqt/edit/e-so/e-so.component';
import { ERukComponent } from './components/profile/applications/nqt/edit/e-ruk/e-ruk.component';
import { EMetodistiComponent } from './components/profile/applications/nqt/edit/e-metodisti/e-metodisti.component';
import { EDoshkolaComponent } from './components/profile/applications/nqt/edit/e-doshkola/e-doshkola.component';
import { EDopComponent } from './components/profile/applications/nqt/edit/e-dop/e-dop.component';
import { ETipoComponent } from './components/profile/applications/nqt/edit/e-tipo/e-tipo.component';
import { EntComponent } from './components/profile/applications/ent/ent.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { VTGComponent } from './components/profile/applications/ent/vtg/vtg.component';
import { UslovnikiComponent } from './components/profile/applications/ent/uslovniki/uslovniki.component';
import { PerevodnikiComponent } from './components/profile/applications/ent/perevodniki/perevodniki.component';
import { VPLComponent } from './components/profile/applications/ent/vpl/vpl.component';
import { TIPOPFComponent } from './components/profile/applications/ent/tipo-pf/tipo-pf.component';
import { TIPOSFComponent } from './components/profile/applications/ent/tipo-sf/tipo-sf.component';
import { ZRBZHComponent } from './components/profile/applications/ent/zrbzh/zrbzh.component';
import { DiasporaComponent } from './components/profile/applications/ent/diaspora/diaspora.component';
import { SertifSATComponent } from './components/profile/applications/ent/sertif-sat/sertif-sat.component';
import { SertifASTComponent } from './components/profile/applications/ent/sertif-ast/sertif-ast.component';
import { PaymentComponent } from './components/profile/applications/payment/payment.component';
import { AppStatusComponent } from './components/profile/app-status/app-status.component';
import { EVtgComponent } from './components/profile/applications/ent/edit/e-vtg/e-vtg.component';
import { EVplComponent } from './components/profile/applications/ent/edit/e-vpl/e-vpl.component';
import { ETipoSfComponent } from './components/profile/applications/ent/edit/e-tipo-sf/e-tipo-sf.component';
import { ETipoPfComponent } from './components/profile/applications/ent/edit/e-tipo-pf/e-tipo-pf.component';
import { EUslovnikiComponent } from './components/profile/applications/ent/edit/e-uslovniki/e-uslovniki.component';
import { EZrbzhComponent } from './components/profile/applications/ent/edit/e-zrbzh/e-zrbzh.component';
import { EPerevodnikiComponent } from './components/profile/applications/ent/edit/e-perevodniki/e-perevodniki.component';
import { ESertifSatComponent } from './components/profile/applications/ent/edit/e-sertif-sat/e-sertif-sat.component';
import { ESertifAstComponent } from './components/profile/applications/ent/edit/e-sertif-ast/e-sertif-ast.component';
import { EDiasporaComponent } from './components/profile/applications/ent/edit/e-diaspora/e-diaspora.component';
import { EditSubjectsComponent } from './components/edit-subjects/edit-subjects.component';
import { SeasonsComponent } from './components/profile/applications/ent/seasons/seasons.component';
import { VipusknikiVuzovComponent } from './components/profile/applications/nqt/vipuskniki-vuzov/vipuskniki-vuzov.component';
import { EVipusknikiVuzovComponent } from './components/profile/applications/nqt/edit/e-vipuskniki-vuzov/e-vipuskniki-vuzov.component';
import { UploadFilesComponent } from './components/support/upload-files/upload-files.component';
import { NqtResultsComponent } from './components/profile/history/nqt-results/nqt-results.component';
import { EntResultsComponent } from './components/profile/history/ent-results/ent-results.component';
import { CertifResultsComponent } from './components/profile/history/certif-results/certif-results.component';
import { EUploadFilesComponent } from './components/support/e-upload-files/e-upload-files.component';
import { PvoComponent } from './components/profile/applications/pvo/pvo.component';
import { KtMagistracyComponent } from './components/profile/applications/pvo/kt-magistracy/kt-magistracy.component';
import { DoctoralStudiesComponent } from './components/profile/applications/pvo/doctoral-studies/doctoral-studies.component';
import { GMATComponent } from './components/profile/applications/pvo/gmat/gmat.component';
import { TvorComponent } from './components/profile/applications/pvo/tvor/tvor.component';
import { ArabComponent } from './components/profile/applications/pvo/arab/arab.component';
import { VoenComponent } from './components/profile/applications/pvo/voen/voen.component';
import { PvoResultsComponent } from './components/profile/history/pvo-results/pvo-results.component';
import { EKtMagistracyComponent } from './components/profile/applications/pvo/edit/e-kt-magistracy/e-kt-magistracy.component';
import { EVoenComponent } from './components/profile/applications/pvo/edit/e-voen/e-voen.component';
import { EGmatComponent } from './components/profile/applications/pvo/edit/e-gmat/e-gmat.component';
import { EArabComponent } from './components/profile/applications/pvo/edit/e-arab/e-arab.component';
import { EDoctoralStudiesComponent } from './components/profile/applications/pvo/edit/e-doctoral-studies/e-doctoral-studies.component';
import { ETvorComponent } from './components/profile/applications/pvo/edit/e-tvor/e-tvor.component';
import { KonkursResultsComponent } from './components/profile/history/konkurs-results/konkurs-results.component';
import { konkursConfig } from './config/konkursRoutes';
import { konkursPvoConfig } from './config/konkursPvoRoutes';
import { PerevodnikiPedComponent } from './components/profile/applications/ent/perevodniki-ped/perevodniki-ped.component';
import { PerevodnikiFromTvorComponent } from './components/profile/applications/ent/perevodniki-from-tvor/perevodniki-from-tvor.component';
import { EPerevodnikiFromTvorComponent } from './components/profile/applications/ent/edit/e-perevodniki-from-tvor/e-perevodniki-from-tvor.component';
import { EPerevodnikiPedComponent } from './components/profile/applications/ent/edit/e-perevodniki-ped/e-perevodniki-ped.component';
import { KonkursPvoResultsComponent } from './components/profile/history/konkurs-pvo-results/konkurs-pvo-results.component';
import { VipusknikiTipoComponent } from './components/profile/applications/nqt/vipuskniki-tipo/vipuskniki-tipo.component';
import { EVipusknikiTipoComponent } from './components/profile/applications/nqt/edit/e-vipuskniki-tipo/e-vipuskniki-tipo.component';
import { SertifIbComponent } from './components/profile/applications/ent/sertif-ib/sertif-ib.component';
import { ESertifIbComponent } from './components/profile/applications/ent/edit/e-sertif-ib/e-sertif-ib.component';
import { ToelfComponent } from './components/profile/applications/pvo/toelf/toelf.component';
import { EToelfComponent } from './components/profile/applications/pvo/edit/e-toelf/e-toelf.component';
import { GreComponent } from './components/profile/applications/pvo/gre/gre.component';
import { EGreComponent } from './components/profile/applications/pvo/edit/e-gre/e-gre.component';
import { MessagesComponent } from './components/profile/messages/messages.component';
import { SpecObrComponent } from './components/profile/applications/nqt/spec-obr/spec-obr.component';
import { ESpecObrComponent } from './components/profile/applications/nqt/edit/e-spec-obr/e-spec-obr.component';
import { DirTestComponent } from './components/profile/applications/dir-test/dir-test/dir-test.component';
import { DirResultsComponent } from './components/profile/history/dir-results/dir-results.component';
import { EDirTestComponent } from './components/profile/applications/dir-test/edit/e-dir-test/e-dir-test.component';
import { SchoolHeadComponent } from './components/edit-subjects/school-head/school-head.component';
import { DirComponent } from './components/profile/applications/dir-test/dir.component';
import { KaztestResultsComponent } from './components/profile/history/kaztest-results/kaztest-results.component';
import { KaztestComponent } from './components/profile/applications/kaztest/kaztest.component';
import { PageLaterComponent } from './components/page-later/page-later.component';
import { ModoComponent } from './components/edit-subjects/modo/modo.component';
import { JusticeAcademyComponent } from './components/profile/applications/pvo/justice-academy/justice-academy.component';
import { EJusticeAcademyComponent } from './components/profile/applications/pvo/edit/e-justice-academy/e-justice-academy.component';
import { PEntComponent } from './components/profile/applications/prob-test/p-ent/p-ent.component';
import { ProbTestComponent } from './components/profile/applications/prob-test/prob-test.component';
import { PKazTestComponent } from './components/profile/applications/prob-test/p-kaz-test/p-kaz-test.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { PPvoComponent } from './components/profile/applications/prob-test/p-pvo/p-pvo.component';
import { PNqtComponent } from './components/profile/applications/prob-test/p-nqt/p-nqt.component';
import { PersonalInfoComponent } from './components/profile/template/personal-info/personal-info.component';
import { DisabilityComponent } from './components/profile/template/disability/disability.component';
import { TestOrgComponent } from './components/profile/template/test-org/test-org.component';
import { GreTemplateComponent } from './components/profile/template/gre-template/gre-template.component';
import { KaztestZayavComponent } from './components/profile/applications/kaztest/components/kaztest-zayav/kaztest-zayav.component';
import { KaztestZayavResultsComponent } from './components/profile/history/kaztest-zayav-results/kaztest-zayav-results.component';
import { HelperComponent } from './components/helper/helper.component';
import { EntKonkursComponent } from './components/helper/ent-konkurs/ent-konkurs.component';
import { KonkursEnrollComponent } from './components/helper/konkurs-enroll/konkurs-enroll.component';
import { KonkursAnalyticsComponent } from './components/helper/konkurs-analytics/konkurs-analytics.component';
import { KonkursStatisticsComponent } from './components/helper/konkurs-statistics/konkurs-statistics.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PolicyComponent } from './components/policy/policy.component';
import { KazAppealComponent } from './components/profile/applications/kaztest/components/kaz-appeal/kaz-appeal.component';
import { KazAppealEditComponent } from './components/profile/applications/kaztest/components/kaz-appeal-edit/kaz-appeal-edit.component';
import { OrleuComponent } from './components/edit-subjects/orleu/orleu.component';
import { orleuConfig } from './config/orleuRoutes';
import { KazTestAppealResultComponent } from './components/profile/history/kaz-test-appeal-result/kaz-test-appeal-result.component';
import {TestingForCitizenshipComponent} from './components/profile/applications/Testing-citizenship/testing-for-citizenship.component';
import {CitizenResultsComponent} from './components/profile/history/citizen-results/citizen-results.component';
import {EditCitizenComponent} from './components/profile/applications/edit-cetezen/edit-citizen.component';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: true,
  };
};

const routes: Routes = [
  { path: '', redirectTo: '/language-select', pathMatch: 'full' },
  { path: 'page-error', component: PageLaterComponent },
  { path: 'language-select', component: LanguageSelectComponent },
  { path: 'password-reset-confirmation', component: PasswordResetComponent },
  { path: 'user-info', component: UserInfoComponent },
  { path: 'edit-subjects', component: EditSubjectsComponent },
  { path: 'school-head/edit-subjects', component: SchoolHeadComponent },
  { path: 'orleu/edit-subjects', component: OrleuComponent },
  { path: 'mobile-privacy-policy', component: PolicyComponent },
  { path: 'modo/edit-subjects', component: ModoComponent },
  {
    path: 'change-email-confirmation',
    component: ChangeEmailConfirmationComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [{ path: 'registration', component: RegistrationComponent }],
  },
  { path: 'forgot-pass', component: ForgotPassComponent },
  { path: 'register-confirmation', component: FromRegConfirmComponent },

  { path: 'add-application', component: AddApplicationComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {
        path: 'helper',
        component: HelperComponent,  data: { breadcrumb: 'selectEvent' },
        children: [
          {
            path: 'ent-konkurs',
            component: EntKonkursComponent,
            data: { breadcrumb: 'entKonkurs' },
            children: [
              { path: 'enroll', component: KonkursEnrollComponent, data: { breadcrumb: 'helpSpec' } },
              { path: 'analytics', component: KonkursAnalyticsComponent, data: { breadcrumb: 'helpSpec' } },
              { path: 'statistics', component: KonkursStatisticsComponent, data: { breadcrumb: 'helpSpec' } },
            ],
          },
        ],
      },
      {
        path: 'prob-test',
        component: ProbTestComponent,
        data: { breadcrumb: 'ProbTest' },
        children: [
          {
            path: '1',
            component: PPvoComponent,
            data: { breadcrumb: 'ProbPvo' },
          },
          {
            path: '2',
            component: PEntComponent,
            data: { breadcrumb: 'ProbEnt' },
          },
          {
            path: '3',
            component: PNqtComponent,
            data: { breadcrumb: 'ProbNqt' },
          },
          {
            path: '5',
            component: PKazTestComponent,
            data: { breadcrumb: 'ProbKazTest' }
          },
        ],
      },
      {
        path: 'im',
        component: ImComponent,
        data: { breadcrumb: 'MyProfile' },
        children: [
          {
            path: 'change-password',
            component: ChangePasswordComponent,
            data: { breadcrumb: 'ChangePass' },
          },
          {
            path: 'edit-profile',
            component: EditProfileComponent,
            data: { breadcrumb: 'EditProfile' },
          },
          {
            path: 'change-email',
            component: ChangeEmailComponent,
            data: { breadcrumb: 'EditLogin' },
          },
        ],
      },
      {
        path: 'competition',
        component: KonkursResultsComponent,
        data: { breadcrumb: 'Результаты конкурса' },
      },
      {
        path: 'history',
        component: HistoryComponent,
        children: [
          {
            path: 'edit',
            children: [
              {
                path: 'nqt',
                children: [
                  {
                    path: '1',
                    data: { breadcrumb: 'EditApp' },
                    component: EDoshkolaComponent,
                  },
                  {
                    path: '2',
                    data: { breadcrumb: 'EditApp' },
                    component: EMetodistiComponent,
                  },
                  {
                    path: '3',
                    data: { breadcrumb: 'EditApp' },
                    component: ESoComponent,
                  },
                  {
                    path: '5',
                    data: { breadcrumb: 'EditApp' },
                    component: EDopComponent,
                  },
                  {
                    path: '7',
                    data: { breadcrumb: 'EditApp' },
                    component: ERukComponent,
                  },
                  {
                    path: '6',
                    data: { breadcrumb: 'EditApp' },
                    component: ETipoComponent,
                  },
                  {
                    path: '8',
                    data: { breadcrumb: 'EditApp' },
                    component: EVipusknikiVuzovComponent,
                  },
                  {
                    path: '9',
                    data: { breadcrumb: 'EditApp' },
                    component: EVipusknikiTipoComponent,
                  },
                  {
                    path: '10',
                    data: { breadcrumb: 'EditApp' },
                    component: ESpecObrComponent,
                  },
                  {
                    path: '11',
                    data: { breadcrumb: 'EditApp' },
                    component: EDoshkolaComponent,
                  },
                  {
                    path: '12',
                    data: { breadcrumb: 'EditApp' },
                    component: EDoshkolaComponent,
                  },
                ],
              },
              { path: 'dir', component: EDirTestComponent },
              {
                path: 'kaztest/:testTypeId/:id',
                component: KaztestZayavComponent,
              },

              {
                path: 'editCitizen/:id',
                component: EditCitizenComponent
              },

              {
                path: 'kazappealedit/:id',
                component: KazAppealEditComponent,
              },
              {
                path: 'ent',
                children: [
                  {
                    path: '1',
                    data: { breadcrumb: 'EditApp' },
                    component: EVtgComponent,
                  },
                  {
                    path: '15',
                    data: { breadcrumb: 'EditApp' },
                    component: EVtgComponent,
                  },
                  {
                    path: '2',
                    data: { breadcrumb: 'EditApp' },
                    component: EVplComponent,
                  },
                  {
                    path: '3',
                    data: { breadcrumb: 'EditApp' },
                    component: EUslovnikiComponent,
                  },
                  {
                    path: 'transferred-student',
                    data: { breadcrumb: 'EditApp' },
                    component: EPerevodnikiComponent,
                  },
                  {
                    path: '7',
                    data: { breadcrumb: 'EditApp' },
                    component: ETipoSfComponent,
                  },
                  {
                    path: 'tipo-sf',
                    data: { breadcrumb: 'EditApp' },
                    component: ETipoPfComponent,
                  },
                  {
                    path: '5',
                    data: { breadcrumb: 'EditApp' },
                    component: EZrbzhComponent,
                  },
                  {
                    path: '4',
                    data: { breadcrumb: 'EditApp' },
                    component: EDiasporaComponent,
                  },
                  {
                    path: '6',
                    data: { breadcrumb: 'EditApp' },
                    component: EPerevodnikiPedComponent,
                  },
                  {
                    path: '14',
                    data: { breadcrumb: 'EditApp' },
                    component: EPerevodnikiFromTvorComponent,
                  },
                  {
                    path: '8',
                    data: { breadcrumb: 'EditApp' },
                    component: EDiasporaComponent,
                  },
                  {
                    path: '9',
                    data: { breadcrumb: 'EditApp' },
                    component: EDiasporaComponent,
                  },
                  {
                    path: '10',
                    data: { breadcrumb: 'EditApp' },
                    component: EDiasporaComponent,
                  },
                  {
                    path: 'SAT',
                    data: { breadcrumb: 'EditApp' },
                    component: ESertifSatComponent,
                  },
                  {
                    path: 'ACT',
                    data: { breadcrumb: 'EditApp' },
                    component: ESertifAstComponent,
                  },
                  {
                    path: 'IB',
                    data: { breadcrumb: 'EditApp' },
                    component: ESertifIbComponent,
                  },
                ],
              },
              {
                path: 'pvo',
                children: [
                  {
                    path: '1',
                    data: { breadcrumb: 'EditApp' },
                    component: EKtMagistracyComponent,
                  },
                  {
                    path: '2',
                    data: { breadcrumb: 'EditApp' },
                    component: EGmatComponent,
                  },
                  {
                    path: '3',
                    data: { breadcrumb: 'EditApp' },
                    component: ETvorComponent,
                  },
                  {
                    path: '4',
                    data: { breadcrumb: 'EditApp' },
                    component: EArabComponent,
                  },
                  {
                    path: '5',
                    data: { breadcrumb: 'EditApp' },
                    component: EVoenComponent,
                  },
                  {
                    path: '6',
                    data: { breadcrumb: 'EditApp' },
                    component: EDoctoralStudiesComponent,
                  },
                  {
                    path: '8',
                    data: { breadcrumb: 'EditApp' },
                    component: EToelfComponent,
                  },
                  {
                    path: '10',
                    data: { breadcrumb: 'EditApp' },
                    component: EJusticeAcademyComponent,
                  },
                ],
              },
            ],
          },
        ],
        data: { breadcrumb: 'AppHystory' },
      },
      {
        path: 'applications',
        component: ApplicationsComponent,
        data: { breadcrumb: 'SelectTestTypes' },
        children: [
          {
            path: 'payment',
            component: PaymentComponent,
            data: { breadcrumb: 'PayType' },
          },
          {
            path: '1',
            component: NqtComponent,
            data: { breadcrumb: 'NQT' },
            children: [
              {
                path: '3',
                data: { breadcrumb: 'DPOSO' },
                component: SoComponent,
              },
              {
                path: '1',
                data: { breadcrumb: 'DPDOVIO' },
                component: DoshkolaComponent,
              },
              {
                path: '11',
                data: { breadcrumb: 'MMKCDOVIO' },
                component: DoshkolaComponent,
              },
              {
                path: '10',
                data: { breadcrumb: 'MMKCODODO' },
                component: DopComponent,
              },
              {
                path: '5',
                data: { breadcrumb: 'DPODO' },
                component: DopComponent,
              },
              {
                path: '8',
                data: { breadcrumb: 'DVIOO' },
                component: DopComponent,
              },
              {
                path: '9',
                data: { breadcrumb: 'DVSO' },
                component: DopComponent,
              },
              {
                path: '7',
                data: { breadcrumb: 'DROO' },
                component: RukComponent,
              },
              {
                path: '2',
                data: { breadcrumb: 'DMMKC' },
                component: MetodistiComponent,
              },
              {
                path: '6',
                data: { breadcrumb: 'POTIPPO' },
                component: TipoComponent,
              },
              {
                path: '12',
                data: { breadcrumb: 'OOVPOPPN' },
                component: VipusknikiVuzovComponent,
              },
              {
                path: '13',
                data: { breadcrumb: 'OKPPN' },
                component: VipusknikiTipoComponent,
              },
              {
                path: '14',
                data: { breadcrumb: 'PSOO' },
                component: SpecObrComponent,
              },
              {
                path: '15',
                data: { breadcrumb: 'DPODO' },
                component: DopComponent,
              },
              {
                path: '16',
                data: { breadcrumb: 'MMK' },
                component: DoshkolaComponent,
              },
              {
                path: '17',
                data: { breadcrumb: 'DPRZRDO' },
                component: DoshkolaComponent,
              },
            ],
          },

          {
            path: '2',
            component: EntComponent,
            data: { breadcrumb: 'ENT' },
            children: [
              {
                path: 'seasons/:seasonId',
                component: SeasonsComponent,
                children: [
                  {
                    path: 'VTG',
                    data: { breadcrumb: 'OODKOSO' },
                    component: VTGComponent,
                  },
                  {
                    path: 'conditionally-credited',
                    data: { breadcrumb: 'LZVVUZ' },
                    component: UslovnikiComponent,
                  },
                  {
                    path: 'transferred-student',
                    data: { breadcrumb: 'SPITPNDGGOP' },
                    component: PerevodnikiComponent,
                  },
                  {
                    path: 'VPL',
                    data: { breadcrumb: 'VSPLK' },
                    component: VPLComponent,
                  },
                  {
                    path: 'per-ped',
                    data: { breadcrumb: 'SPNGOPOBPN' },
                    component: PerevodnikiPedComponent,
                  },
                  {
                    path: 'per-from-tvor',
                    data: { breadcrumb: 'SPITPNDGGOP' },
                    component: PerevodnikiFromTvorComponent,
                  },
                  {
                    path: 'tipo-pf',
                    data: {
                      breadcrumb:
                        'Выпускники технического и профессионального или послесреднего образования претендующие на полный срок обучения',
                    },
                    component: TIPOPFComponent,
                  },
                  {
                    path: 'tipo-sf',
                    data: { breadcrumb: 'VKPNSSO' },
                    component: TIPOSFComponent,
                  },
                  {
                    path: 'foreign-student',
                    data: { breadcrumb: 'VSOZR' },
                    component: ZRBZHComponent,
                  },
                  {
                    path: 'diaspora',
                    data: { breadcrumb: 'LKNNYGRK' },
                    component: DiasporaComponent,
                  },
                  {
                    path: 'SAT',
                    data: { breadcrumb: 'Обладатели сертификатов SAT' },
                    component: SertifSATComponent,
                  },
                  {
                    path: 'ACT',
                    data: { breadcrumb: 'Обладатели сертификатов ACT' },
                    component: SertifASTComponent,
                  },
                  {
                    path: 'IB',
                    data: { breadcrumb: 'Обладатели сертификатов IB' },
                    component: SertifIbComponent,
                  },
                ],
              },
            ],
          },
          {
            path: '3',
            component: KaztestComponent,
            data: { breadcrumb: 'KazTest' },
            children: [
              {
                path: '1',
                data: { breadcrumb: 'kaztestCert' },
                component: KaztestZayavComponent,
              },
              {
                path: '2',
                data: { breadcrumb: 'kaztestDiag' },
                component: KaztestZayavComponent,
              },
              {
                path: '3/:id',
                data: { breadcrumb: 'kazappeal' },
                component: KazAppealComponent,
              },
            ],
          },
          {
            path: '9',
            component: DirComponent,
            children: [{ path: 'dir', component: DirTestComponent }],
          },

          {
            path: '10',
            component: TestingForCitizenshipComponent,
            children: [{ path: 'testing-for-citizenship', component: TestingForCitizenshipComponent }],
          },


          {
            path: '4',
            component: PvoComponent,
            data: { breadcrumb: 'SelectTestTypes' },
            children: [
              {
                path: 'seasons/:seasonId',
                data: { breadcrumb: 'PVO' },
                component: SeasonsComponent,
                children: [
                  {
                    path: '1',
                    data: { breadcrumb: 'KTVM' },
                    component: KtMagistracyComponent,
                  },
                  {
                    path: '2',
                    data: { breadcrumb: 'KTVPMSAYO' },
                    component: GMATComponent,
                  },
                  {
                    path: '3',
                    data: { breadcrumb: 'KTVMDGOPTTP' },
                    component: TvorComponent,
                  },
                  {
                    path: '4',
                    data: { breadcrumb: 'KTVMDGOPTAY' },
                    component: ArabComponent,
                  },
                  {
                    path: '5',
                    data: { breadcrumb: 'VEPIYDNOVPO' },
                    component: VoenComponent,
                  },
                  {
                    path: '6',
                    data: { breadcrumb: 'VEVD' },
                    component: DoctoralStudiesComponent,
                  },
                  {
                    path: '8',
                    data: { breadcrumb: 'TOEFL' },
                    component: ToelfComponent,
                  },
                  {
                    path: '9',
                    data: { breadcrumb: 'TOEFL' },
                    component: GreComponent,
                  },
                  {
                    path: '7',
                    data: { breadcrumb: 'GRE' },
                    component: GreComponent,
                  },
                  {
                    path: '10',
                    data: { breadcrumb: 'KTVMAP' },
                    component: JusticeAcademyComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app-', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    PKazTestComponent,
    LanguageSelectComponent,
    RegistrationComponent,
    ForgotPassComponent,
    AddApplicationComponent,
    ProfileComponent,
    TestingForCitizenshipComponent,
    ImComponent,
    ApplicationsComponent,
    FromRegConfirmComponent,
    NqtComponent,
    PasswordResetComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangeEmailConfirmationComponent,
    EditProfileComponent,
    SoComponent,
    HistoryComponent,
    DoshkolaComponent,
    MetodistiComponent,
    TipoComponent,
    DopComponent,
    RukComponent,
    ESoComponent,
    ERukComponent,
    EMetodistiComponent,
    EDoshkolaComponent,
    EDopComponent,
    ETipoComponent,
    EntComponent,
    UserInfoComponent,
    EditCitizenComponent,
    VTGComponent,
    UslovnikiComponent,
    PerevodnikiComponent,
    VPLComponent,
    TIPOPFComponent,
    TIPOSFComponent,
    ZRBZHComponent,
    DiasporaComponent,
    SertifSATComponent,
    SertifASTComponent,
    PaymentComponent,
    AppStatusComponent,
    EVtgComponent,
    EVplComponent,
    ETipoSfComponent,
    ETipoPfComponent,
    EUslovnikiComponent,
    EZrbzhComponent,
    EPerevodnikiComponent,
    ESertifSatComponent,
    ESertifAstComponent,
    EDiasporaComponent,
    EditSubjectsComponent,
    KazTestAppealResultComponent,
    SeasonsComponent,
    VipusknikiVuzovComponent,
    EVipusknikiVuzovComponent,
    UploadFilesComponent,
    NqtResultsComponent,
    EntResultsComponent,
    CertifResultsComponent,
    EUploadFilesComponent,
    PvoComponent,
    CitizenResultsComponent,
    KtMagistracyComponent,
    DoctoralStudiesComponent,
    GMATComponent,
    TvorComponent,
    ArabComponent,
    VoenComponent,
    PvoResultsComponent,
    EKtMagistracyComponent,
    EVoenComponent,
    EGmatComponent,
    EArabComponent,
    EDoctoralStudiesComponent,
    ETvorComponent,
    KonkursResultsComponent,
    PerevodnikiPedComponent,
    PerevodnikiFromTvorComponent,
    EPerevodnikiFromTvorComponent,
    EPerevodnikiPedComponent,
    KonkursPvoResultsComponent,
    VipusknikiTipoComponent,
    EVipusknikiTipoComponent,
    SertifIbComponent,
    ESertifIbComponent,
    ToelfComponent,
    EToelfComponent,
    GreComponent,
    EGreComponent,
    MessagesComponent,
    SpecObrComponent,
    ESpecObrComponent,
    DirTestComponent,
    DirResultsComponent,
    EDirTestComponent,
    SchoolHeadComponent,
    DirComponent,
    KaztestResultsComponent,
    KaztestComponent,
    PageLaterComponent,
    ModoComponent,
    JusticeAcademyComponent,
    EJusticeAcademyComponent,
    PEntComponent,
    ProbTestComponent,
    PPvoComponent,
    PNqtComponent,
    PersonalInfoComponent,
    DisabilityComponent,
    TestOrgComponent,
    GreTemplateComponent,
    KaztestZayavComponent,
    KaztestZayavResultsComponent,
    HelperComponent,
    EntKonkursComponent,
    KonkursEnrollComponent,
    KonkursAnalyticsComponent,
    KonkursStatisticsComponent,
    PolicyComponent,
    KazAppealComponent,
    KazAppealEditComponent,
    OrleuComponent,
  ],
  imports: [
    FormsModule,
    JwPaginationModule,
    FontAwesomeModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    BrowserModule,
    BreadcrumbModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    ValidateEqualModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CKEditorModule,
    NgxPrintModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    // NgxPaginationModule,
    NgxLoadingModule.forRoot({}),
    QRCodeModule,
    RouterModule.forRoot(routes),
    SimpleNotificationsModule.forRoot({
      position: ['bottom', 'right'],
      showProgressBar: true,
      timeOut: 5000,
      pauseOnHover: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    authConfig,
    entConfig,
    DatePipe,
    notificationConfig,
    messagesConfig,
    nqtConfig,
    astConfig,
    pvoConfig,
    konkursConfig,
    konkursPvoConfig,
    dirConfig,
    kaztestConfig,
    modoConfig,
    probtestConfig,
    orleuConfig
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faCheckSquare);
  }
}
