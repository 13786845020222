import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-e-zayav-prob-nqt',
  templateUrl: './e-zayav-prob-nqt.component.html',
  styleUrls: ['./e-zayav-prob-nqt.component.css'],
})
export class EZayavProbNqtComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  // phoneNumber: any;
  iin: any;
  test: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  items: any;
  id: any;
  entryList = [];
  employmentTypeId: any;
  testTypeId: any;
  employmentTypeList: any;
  langList = [];
  varInfoList = [];
  subjectId: any;
  eduTypeId: any;
  headerTypeId: any;
  testLanguageId: any;
  subjectList: any;
  testTypeValue: any;
  showForeignLanguage = false;
  pSubjects = [];
  headerTypes = [];
  specList = [];
  // testPeriodId: any;
  // testOrgId: any;
  // testOrgOblId: any;
  testTypeGroupId: any;
  shortList = [];
  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });
    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      testTypeId: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      testOrgOblId: new FormControl(0, Validators.required),
      headerTypeId: new FormControl(0, Validators.required),
      testLanguageId: new FormControl(0, Validators.required),
      subjectId: new FormControl(0, Validators.required),
      eduTypeId: new FormControl(0, Validators.required),
      entryPointId: new FormControl(0, Validators.required),
      eduLanguageId: new FormControl(0, Validators.required),
      tgoLanguageId: new FormControl(0, Validators.required),
      collegeSpecId: new FormControl(0, Validators.required),
      specialityId: new FormControl(0, Validators.required),
      employmentTypeId: new FormControl(0, Validators.required),
    });
  }

  ngOnInit(): void {
    this.applicationForm.get('testLanguageId').valueChanges.subscribe((value) => {
      this.varInfoList = [];
    });
    this.applicationForm.get('headerTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];
    });

    this.applicationForm.get('employmentTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('headerTypeId').setValue(0);
      this.headerTypes = [];
    });

    this.applicationForm.get('testTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('headerTypeId').setValue(0);
    });

    this.applicationForm.get('subjectId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testLanguageId').setValue(0);
    });

    this.applicationForm.get('eduTypeId').valueChanges.subscribe((value) => {
      this.applicationForm.get('specialityId').setValue(0);
      this.applicationForm.get('subjectId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];
      this.subjectList = [];
      this.varInfoList = [];
    });

    this.applicationForm.get('specialityId').valueChanges.subscribe((value) => {
      this.applicationForm.get('subjectId').setValue(0);
      this.applicationForm.get('testLanguageId').setValue(0);
      this.langList = [];

    });
    this.applicationForm.get('testOrgOblId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testOrgId').setValue(0);
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testOrgList = [];
      this.testPeriodList = [];

      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.applicationForm.get('testOrgId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testPeriodList = [];
      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.api.getShortProbNqt(this.id)
      .subscribe((res: any) => {
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.testTypeGroupId = res.data.testTypeGroupId;
        this.iin = res.data.iin;
        this.email = res.data.email;
        this.shortList = res.data;
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);
        this.getObl();
        this.applicationForm.get('testOrgOblId').setValue(res.data.testOrgOblId);
        this.getTestOrg();
        this.applicationForm.get('testOrgId').setValue(res.data.testOrgId);
        this.getTestPeriod();
        this.applicationForm.get('testPeriodId').setValue(res.data.testPeriodId);

        this.getEntryPointList().then(() => {
          this.applicationForm.get('entryPointId').setValue(res.data.entryPointId);
          this.applicationForm.get('testTypeId').setValue(res.data.testTypeId);

          this.getEmploymentTypeList().then(() => {
            this.applicationForm.get('employmentTypeId').setValue(res.data.employmentTypeId);

            this.emplTypesChange(this.applicationForm.get('employmentTypeId').value, 1);

            this.applicationForm.get('eduTypeId').setValue(res.data.eduTypeId);
            this.applicationForm.get('headerTypeId').setValue(res.data.headerTypeId);
            this.applicationForm.get('specialityId').setValue(res.data.specialityId);
            this.applicationForm.get('subjectId').setValue(res.data.subjectId);
            this.applicationForm.get('testLanguageId').setValue(res.data.testLanguageId);
            this.getTipoSubjectLang();
            this.getVarInfo();

          });
        });
      });
  }
  testTypeChange(value: any, code: any) {
    this.langList = [];
    // value = this.applicationForm.get('entryPointId').value;
    if (code == 1) {
      this.applicationForm.patchValue({
        eduLanguageId: 0,
        tgoLanguageId: 0,
        eduProgramId: 0,
        employmentTypeId: 0,
        collegeSpecId: 0,
        eduTypeId: 0,
        headerTypeId: 0,
        specialityId: 0,
        subjectId: 0,
        testLanguageId: 0,
      });
      const selectedEntry = this.entryList.find((element: any) => element.id == +value);
      if (selectedEntry) {
        this.applicationForm.patchValue({
          testTypeId: selectedEntry.testTypeId
        });
      }
    }
    this.testTypeValue = this.applicationForm.get('testTypeId').value;
    this.subjectList = [];
    this.varInfoList = [];
    this.applicationForm.get('subjectId').setValue(0);
    this.getEmploymentTypeList().then(() => {
      this.emplTypesChange(this.applicationForm.get('employmentTypeId').value, code);
    });
  }
  emplTypesChange(value: number, code: number = 0) {
    this.subjectList = [];
    if (code == 0) {
      this.applicationForm.get('eduTypeId')?.setValue(0);
      this.applicationForm.get('headerTypeId')?.setValue(0);
      this.applicationForm.get('specialityId')?.setValue(0);
      this.applicationForm.get('subjectId')?.setValue(0);
      this.applicationForm.get('testLanguageId')?.setValue(0);
      this.varInfoList = [];
    }
    if (this.applicationForm.get('testTypeId').value == 59 || this.applicationForm.get('testTypeId').value == 61) {
      this.api.getSubjList(this.applicationForm.get('testTypeId').value, value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        });
    } else if (this.applicationForm.get('testTypeId').value == 55) {
      this.api.getPSubjects(this.applicationForm.get('testTypeId').value, value)
        .subscribe(
          (res: any) => {
            this.pSubjects = res.items;
            this.getSpec();
          },
          (err: any) => {}
        );
    } else {
      if ((this.applicationForm.get('testTypeId').value == 56 || this.applicationForm.get('testTypeId').value == 57) && this.applicationForm.get('employmentTypeId').value != 0) {
        this.api.getHeaderTypeProb(this.applicationForm.get('testTypeId').value, value)
          .subscribe((res: any) => {
              this.headerTypes = res.items;
          });
      }
    }
    if (this.applicationForm.get('testTypeId').value == 56 && code == 0) {
      this.getTipoSubjectLang().then(() => this.getVarInfo());
    }
    if (this.applicationForm.get('testTypeId').value != 56 && code == 0) {
      this.getTipoSubjectLang().then(() => this.getVarInfo());
    }
  }
  async getEntryPointList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getEntryPoint()
        .subscribe({
          next: (res: any) => {
            this.entryList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.entryList = [];
            resolve();
          }
        });
    });
  }
  async getEmploymentTypeList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getEmploymentType(this.applicationForm.get('testTypeId').value)
        .subscribe({
          next: (res: any) => {
            this.employmentTypeList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.employmentTypeList = [];
            resolve();
          }
        });
    });
  }
  async getTipoSubjectLang(): Promise<void> {
    return new Promise((resolve) => {
      if (this.applicationForm.get('testTypeId').value == 55) {
        this.api.getTipoSubjectLangProb(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('eduTypeId').value,
          this.applicationForm.get('specialityId').value,
          this.applicationForm.get('subjectId').value
        ).subscribe({
          next: (res: any) => {
            this.langList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.langList = [];
            resolve();
          }
        });
      } else {
        this.getLangOZPList();
      }
    });
  }
  getSubj(){
    if (this.applicationForm.get('testTypeId').value == 55){
      this.api.getTIpoSubjList(
        this.applicationForm.get('testTypeId').value,
        this.applicationForm.get('employmentTypeId').value,
        this.applicationForm.get('eduTypeId').value,
        this.applicationForm.get('specialityId').value
      )
        .subscribe((res: any) => {
          this.subjectList = res.items;
          // if (this.applicationForm.get('subjectId').value !== 0){
          //   this.getTipoSubjectLang().then(() => this.getVarInfo());
          // }
        });
    }else{
      this.api.getSubjList(this.applicationForm.get('testTypeId').value, this.applicationForm.get('employmentTypeId').value)
        .subscribe((res: any) => {
          this.subjectList = res. items;
          // if (this.applicationForm.get('subjectId').value !== 0){
          //   this.getTipoSubjectLang().then(() => this.getVarInfo());
          // }
        });
    }
  }
  getSpec(){
    this.api.getSpecial
    (
      this.applicationForm.get('testTypeId').value,
      this.applicationForm.get('employmentTypeId').value,
      this.applicationForm.get('eduTypeId').value,
    )
      .subscribe((res: any) => {
        this.specList = res.items;
        this.getSubj();
      });
  }
  async getLangOZPList(): Promise<void> {
    return new Promise((resolve) => {
      this.api.getLangOZP(
        this.applicationForm.get('testTypeId').value,
        this.applicationForm.get('employmentTypeId').value,
        this.applicationForm.get('subjectId').value,
      )
        .subscribe({
        next: (res: any) => {
          this.langList = res.items || [];
          resolve();
        },
        error: (err) => {
          this.langList = [];
          resolve();
        }
      });
    });
  }
  async getVarInfo(): Promise<void> {
    return new Promise((resolve) => {
      if (this.applicationForm.get('testTypeId').value == 55) {
        this.api.varInfoNqt(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('eduTypeId').value,
          this.applicationForm.get('specialityId').value,
          this.applicationForm.get('subjectId').value,
          this.applicationForm.get('testLanguageId').value,
          this.headerTypeId
        ).subscribe({
          next: (res: any) => {
            this.varInfoList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.varInfoList = [];
            resolve();
          }
        });
      } else {
        this.api.varInfo(
          this.applicationForm.get('testTypeId').value,
          this.applicationForm.get('employmentTypeId').value,
          this.applicationForm.get('subjectId').value,
          this.applicationForm.get('testLanguageId').value,
          this.eduTypeId,
          this.headerTypeId
        ).subscribe({
          next: (res: any) => {
            this.varInfoList = res.items || [];
            resolve();
          },
          error: (err) => {
            this.varInfoList = [];
            resolve();
          }
        });
      }
    });
  }
  getVarInfoNqt(){
    this.api.varInfoNqt
    (
      this.applicationForm.get('testTypeId').value,
      this.applicationForm.get('employmentTypeId').value,
      this.applicationForm.get('subjectId').value,
      this.applicationForm.get('testLanguageId').value,
      this.applicationForm.get('eduTypeId').value,
      this.applicationForm.get('specialityId').value,
      this.headerTypeId
    )
      .subscribe((res: any) => {
        this.varInfoList = res.items;
      });
  }
  getObl(){
    this.api.getObls(this.testTypeGroupId)
      .subscribe((res: any) => {
        this.obls = res.items;
      });
  }
  getTestOrg(){
    this.api.getTestOrgs(this.testTypeGroupId, this.applicationForm.get('testOrgOblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      });
  }

  getTestPeriod(){
    this.api.getTestPeriods(this.testTypeGroupId, this.applicationForm.get('testOrgId')!.value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
      });
  }
  postForm(){
    this.loading = true;
    this.api.editData(this.id, this.applicationForm.value)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
          this.router.navigateByUrl('/profile/prob-test/3/2');
        },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });
  }

}
