import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../../../../service/api.service"
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-citizen-results',
  templateUrl: './citizen-results.component.html',
  styleUrls: ['./citizen-results.component.css']
})
export class CitizenResultsComponent implements OnInit {

  @Input() app;

  @Output() showDeleteCitizen = new EventEmitter<any>();
  // @Output() editAppType = new EventEmitter<any>();
  // @Output() cancelAppeal = new EventEmitter<any>();

  loading: any;
  themeSubjects: any = [];
  isCollapsed = true;


  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    // this.PrintElem();
  }

  // getTheme(id: any, collapsed: any){
  //   this.loading = true;
  //   if(collapsed == false){
  //     this.api.getCitizenItems(id)
  //       .subscribe((res: any) => {
  //         this.themeSubjects = res.items;
  //         this.themeSubjects.sort((a, b) => a.ord > b.ord ? 1 : -1);
  //         this.loading = false;
  //       }, (err: any) => {
  //         this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
  //         this.loading = false;
  //       });
  //   }
  //
  // }

  checkCertificate(id, iin, year, testType){
    this.loading = true;
    this.api.checkCertificatecheck(id, iin, year, testType)
      .subscribe((res: any) => {
        console.log(res);
        if(res.data.isExists){
          this.api.downloadCertificate(id, iin, year, testType)
            .subscribe((res: any) => {
              // let certifLink = res.data.certificateDownLoadUrl;
              // window.open(certifLink, "_blank");

              window.location.href = res.data.certificateDownLoadUrl;
              this.loading = false;
            });
        }else{
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'сертификат еще не доступен', NotificationType.Error, 5000) : this._notifications.create('Қате', 'Сертификатыңыз әлі дайын емес', NotificationType.Error, 5000);
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
      });
  }

  // uvedomlenie(certificateDownLoadUrl: any){
  //   let certifLink = certificateDownLoadUrl;
  //   window.open(certifLink, "_blank");
  // }
  //
  // showEditEnt(id: number, appTypeId: number){
  //   this.router.navigate(['profile/history/edit/ent/' + appTypeId], { queryParams: { id: id, appType: appTypeId} });
  // }
  //
  showPayment(id: number){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 2} });
  }
  //
  showDeleteAppCitizenlModal(){
    this.showDeleteCitizen.emit();
  }
  //
  // showAppTypeModal(){
  //   this.editAppType.emit();
  // }

  showCitizenEdit(id:number){
    this.router.navigate(['profile/history/edit/editCitizen', id]);
  }
  //
  // showAppealModal(){
  //   this.cancelAppeal.emit();
  // }



}
