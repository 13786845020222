import { isDevMode, Injectable } from '@angular/core';

@Injectable()
export class nqtConfig {
  nqtBackend: any;
  apiRoutes: any;
  dirBackend: any;

  constructor() {
    if (isDevMode()) {
      this.nqtBackend = 'https://app.debug.testcenter.kz/nqt/student/api/v1'; // Путь для localhost
      this.dirBackend = 'https://app.debug.testcenter.kz/school-head/student/app/api/v1'; // Путь для localhost
    }
    if (!isDevMode()) {
      this.nqtBackend = 'nqt/student/api/v1'; // Путь для боевого
      this.dirBackend = 'school-head/student/app/api/v1'; // Путь для боевого
    }

    this.apiRoutes = { // ссылки для запросов
      getPersonalData: this.nqtBackend + '/personal-data',
      jobType: this.nqtBackend + '/app/job-type/items',
      postNqtSoForm: this.nqtBackend + '/app/items',
      entryPoint: this.nqtBackend + '/entry-point/items',
      jobSearch: this.nqtBackend + '/job/search',
    };

  }
}
