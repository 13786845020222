import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../../service/api.service';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-e-zayav-prob-dir',
  templateUrl: './e-zayav-prob-dir.component.html',
  styleUrls: ['./e-zayav-prob-dir.component.css']
})
export class EZayavProbDirComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  textModal = false;
  applicationAgreeParent = false;
  phoneNumber: any;
  iin: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  blockBtn = false;
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  shortList: any;
  applicationAgree = false;
  id: any;
  testPeriodId: any;
  testOrgOblId: any;
  testOrgId: any;
  testTypeGroupId: any;

  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });
    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
      testOrgOblId: new FormControl(0, Validators.required)
    });
  }

  ngOnInit(): void {
    this.applicationForm.get('testOrgOblId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testOrgId').setValue(0);
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testOrgList = [];
      this.testPeriodList = [];

      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.applicationForm.get('testOrgId').valueChanges.subscribe((value) => {
      this.applicationForm.get('testPeriodId').setValue(0);
      this.testPeriodList = [];

      if (value !== 0) {
        this.getTestOrg();
      }
    });
    this.api.getShortProbEnt(this.id)
      .subscribe((res: any) => {
        this.shortList = res.data;
        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.testPeriodId = res.data.testPeriodId;
        this.testOrgId = res.data.testOrgId;
        this.testOrgOblId = res.data.testOrgOblId;
        this.testTypeGroupId = res.data.testTypeGroupId;
        this.iin = res.data.iin;
        this.email = res.data.email;
        this.applicationForm.get('email').patchValue(res.data.email);
        this.applicationForm.get('testOrgOblId').setValue(this.shortList.testOrgOblId);
        this.applicationForm.get('testOrgId').setValue(this.shortList.testOrgId);
        this.applicationForm.get('testPeriodId').setValue(this.shortList.testPeriodId);
        this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
        this.getObl();
        this.getTestOrg();
        this.getTestPeriod();
      });
  }
  getObl() {
    this.api.getObls(this.testTypeGroupId).subscribe((res: any) => {
      this.obls = res.items;
    });
  }
  getTestOrg(){
    this.api.getTestOrgs(this.testTypeGroupId, this.applicationForm.get('testOrgOblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      });
  }

  getTestPeriod(){
    this.api.getTestPeriods(this.testTypeGroupId, this.applicationForm.get('testOrgId')!.value, this.id)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
      });
  }
  postForm(){
    this.loading = true;
    this.api.editData(this.id, this.applicationForm.value)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
          this.router.navigateByUrl('/profile/prob-test/4/2');
        },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        }
      });

  }

}
