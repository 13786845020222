import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-e-vtg',
  templateUrl: './e-vtg.component.html',
  styleUrls: ['./e-vtg.component.css']
})
export class EVtgComponent implements OnInit {


  seasonId: any;
  loading = false;
  appTypeId: any;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  email: any;
  pageLangId: any;
  nationsList = [];
  countryList = [];
  foreignLangCertList = [];
  oblList = [];
  raionList = [];
  schoolList = [];
  subjectPairList = [];
  langList = [];
  testOrgList = [];
  testOblList = [];
  testDateList = []
  phone = '+0 (000) 000-00-00';
  foreignLangCertScoreMask: string = '000';
  foreignLangCertScoreMaskIelts: string = '0.0';
  applicationAgree = false;
  applicationId: any;
  appShortInfo: any;
  incriment: number = 0;
  dopSertifsList: any;


  disabilityList: any;
  fileIdToDelete: number;

  canEditCitizenshipId = false;
  canEditSexId = false;
  canEditNationId = false;
  canEditPhoneNumber = false;
  canEditOblId = false;
  canEditRaionId = false;
  canEditSchoolId = false;
  CSID: any;
  NID: any;
  dataofbday = 0;
  showParentData = false;

  mathSubjects = [];
  ALevelMathSubjects = [];

  subjectList = [];

  barArray: any = [false, true, true, true, true, true];

  isEmergency = false;

  prefixAlevel = [
    {score:1, label: 'U'},
    {score:2, label: 'E'},
    {score:3, label: 'D'},
    {score:4, label: 'C'},
    {score:5, label: 'B'},
    {score:6, label: 'A'},
    {score:7, label: 'A*'}
  ]

  constructor(public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;
    this.applicationId = this.route.snapshot.queryParamMap.get('id');
    this.appTypeId = this.route.snapshot.queryParamMap.get('appType');

    // Получение данныъ из профиля
    this.api.profileInfo()
      .subscribe((res: any) => {
        this.getApplicationInfo();

        this.lastname = res.data.lastname;
        this.firstname = res.data.firstname;
        this.patronymic = res.data.patronymic;
        this.phoneNumber = res.data.phoneNumber;
        this.iin = res.data.iin;
        this.email = res.data.email;
        if (res.data.currentAge < 18) {
          this.showParentData = true;
        } else {
          this.showParentData = false;
        }
        if (res.data.sexId == 0) {
          this.canEditSexId = true;
        }
        if (res.data.nationId == 0 || res.data.nationId == 99) {
          this.canEditNationId = true;
        }
        if (res.data.citizenshipId == 0 || res.data.citizenshipId == 99) {
          this.canEditCitizenshipId = true;
        }
        if (res.data.phoneNumber == 0) {
          this.canEditPhoneNumber = true;
        }
        this.CSID = res.data.citizenshipId;
        this.NID = res.data.nationId;
        // this.applicationForm.get('sexId').patchValue(res.data.sexId);
        this.applicationForm.get('citizenshipId').patchValue(res.data.citizenshipId);
        this.applicationForm.get('nationId').patchValue(res.data.nationId);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        this.getDateOfBirth(res.data.birthDate);
        // this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);


      });

    // Инициализация формы
    this.applicationForm = new FormGroup({
      seasonId: new FormControl(0),
      appTypeId: new FormControl(this.appTypeId),
      citizenshipId: new FormControl(0, Validators.required),
      nationId: new FormControl(0, Validators.required),
      foreignLangCertTypeId: new FormControl(0),
      foreignLangCertScore: new FormControl("0"),
      phoneNumber: new FormControl(0, Validators.required),
      sexId: new FormControl(0, Validators.required),
      oblId: new FormControl(0, Validators.required),
      raionId: new FormControl(0, Validators.required),
      schoolId: new FormControl(0, Validators.required),
      profileSubjectPairId: new FormControl(0),
      testLangId: new FormControl(0),
      testOrgOblId: new FormControl(0),
      foreignLangCertTestDate: new FormControl(0),
      birthDate: new FormControl(0),
      parentFullname: new FormControl(0),
      parentEmail: new FormControl(0),
      parentPhoneNumber: new FormControl(0),
      testOrgId: new FormControl(0),
      testPeriodId: new FormControl(0),
      anotherExamTypeId: new FormControl(0),
      isHasDisability: new FormControl(0, Validators.required),
      isNeedHelperDueDisability: new FormControl(0),
      disabilityId: new FormControl(0, Validators.required),
      docFiles: new FormArray([]),
      anotherExamFiles: new FormArray([]),
      satAnotherExamCertificateData: new FormGroup({
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0),
        reasoningScore: new FormControl(0),
        isSubjectsExists: new FormControl(0),
        subjects: new FormArray([]),
      }),
      astAnotherExamCertificateData: new FormGroup({
        englishScore: new FormControl(0),
        mathScore: new FormControl(0),
        readingScore: new FormControl(0),
        scienceScore: new FormControl(0),
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0)
      }),
      ibAnotherExamCertificateData: new FormGroup({
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0),
        mathSubject: new FormGroup({
          id: new FormControl(0),
          score: new FormControl(0)
        }),
        subjects: new FormArray([])
      }),
      aLevelAnotherExamCertificateData: new FormGroup({
        testDate: new FormControl(0),
        registrationNumber: new FormControl(0),
        mathSubject: new FormGroup({
          id: new FormControl(0),
          score: new FormControl(0)
        }),
        subjects: new FormArray([])
      }),
    });
    // язык для соритировки в запросах
    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }


    // console.log(this.applicationId);

  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  getDateOfBirth(date: any) {
    let today = new Date();
    // this.dataofbday = this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
    // if(this.getNumberOfDays(date,today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()) < 6574){
    //   this.showParentData = true;
    // }else{
    //   this.showParentData = false;
    // }
  }

  getApplicationInfo() {
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.appShortInfo = res.data;
        this.seasonId = this.appShortInfo.seasonId;
        this.getNationList();
        this.getCountryList();
        this.getForeignLangCertList();
        this.getOblList();
        this.getSubjectPair();
        this.getHelper();
        this.addFiles();
        this.addCertifFile();
        this.getSertifs();
        this.getIBMathSubjects();
        this.getALevelMathSubjects();

        this.applicationForm.get('anotherExamTypeId').patchValue(this.appShortInfo.anotherExamTypeId);
        this.applicationForm.get('appTypeId').patchValue(this.appTypeId);
        this.applicationForm.get('seasonId').patchValue(this.seasonId);
        this.applicationForm.get('parentFullname').patchValue(this.appShortInfo.parentFullname);
        this.applicationForm.get('parentPhoneNumber').patchValue(this.appShortInfo.parentPhoneNumber);
        this.applicationForm.get('parentEmail').patchValue(this.appShortInfo.parentEmail);

        for(let i = 0; i < 2; i++){
          this.subjects.push(this.subjectNew());
        }

        for(let i = 0; i < 2; i++){
          this.subjectsIb.push(this.subjectNew());
        }

        for(let i = 0; i < 2; i++){
          this.subjectsALevel.push(this.subjectNew());
        }

        if (this.appShortInfo.citizenshipId != 0 && this.CSID == 0) {
          this.applicationForm.get('citizenshipId').patchValue(this.appShortInfo.citizenshipId);
        }

        if (this.appShortInfo.nationId != 0 && this.NID == 0) {
          this.applicationForm.get('nationId').patchValue(this.appShortInfo.nationId);
        }

        this.applicationForm.get('foreignLangCertTypeId').patchValue(this.appShortInfo.foreignLangCertTypeId);
        this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
        for (this.incriment; this.incriment < 1; this.incriment++) {
          this.applicationForm.get('foreignLangCertScore').patchValue(this.appShortInfo.foreignLangCertScore);
        }

        if (this.appShortInfo.anotherExamTypeId == 1) {

          this.appShortInfo.satAnotherExamCertificateData.subjects.forEach((item:any, index:any )=>{
            const data = this.subjects.at(index);
            if(data){
              data.patchValue(item);
            }
          })

          // // sat
          // for (let i = 0; i < 2; i++) {
          //   this.subjects.at(i);

          //   this.subjects.push(this.subject(this.appShortInfo.satAnotherExamCertificateData.subjects[i].id, this.appShortInfo.satAnotherExamCertificateData.subjects[i].score));
          // }

          this.applicationForm.get('satAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
          this.applicationForm.get('satAnotherExamCertificateData').get('isSubjectsExists').patchValue(this.appShortInfo.satAnotherExamCertificateData.isSubjectsExists);
          this.applicationForm.get('satAnotherExamCertificateData').get('reasoningScore').patchValue(this.appShortInfo.satAnotherExamCertificateData.reasoningScore);
          this.applicationForm.get('satAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.satAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('satAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
        }
        if (this.appShortInfo.anotherExamTypeId == 2) {
          // ib

          this.appShortInfo.ibAnotherExamCertificateData.subjects.forEach((item:any, index:any )=>{
            const data = this.subjectsIb.at(index);
            if(data){
              data.patchValue(item);
            }
          })


          // for (let i = 0; i < 2; i++) {
          //   this.subjectsIb.push(this.subject(this.appShortInfo.ibAnotherExamCertificateData.subjects[i].id, this.appShortInfo.ibAnotherExamCertificateData.subjects[i].score));
          // }

          this.applicationForm.get('ibAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.ibAnotherExamCertificateData.testDate);
          this.applicationForm.get('ibAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.ibAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('ibAnotherExamCertificateData').get('mathSubject').get('id').patchValue(this.appShortInfo.ibAnotherExamCertificateData.mathSubject.id);
          this.applicationForm.get('ibAnotherExamCertificateData').get('mathSubject').get('score').patchValue(this.appShortInfo.ibAnotherExamCertificateData.mathSubject.score);
          // this.applicationForm.get('ibAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.satAnotherExamCertificateData.testDate);
        }

        if (this.appShortInfo.anotherExamTypeId == 4) {
          // ib

          this.appShortInfo.aLevelAnotherExamCertificateData.subjects.forEach((item:any, index:any )=>{
            const data = this.subjectsALevel.at(index);
            if(data){
              data.patchValue(item);
            }
          })


          // for (let i = 0; i < 2; i++) {
          //   this.subjectsALevel.push(this.subject(this.appShortInfo.aLevelAnotherExamCertificateData.subjects[i].id, this.appShortInfo.aLevelAnotherExamCertificateData.subjects[i].score));
          // }

          this.applicationForm.get('aLevelAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.aLevelAnotherExamCertificateData.testDate);
          this.applicationForm.get('aLevelAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.aLevelAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('aLevelAnotherExamCertificateData').get('mathSubject').get('id').patchValue(this.appShortInfo.aLevelAnotherExamCertificateData.mathSubject.id);
          this.applicationForm.get('aLevelAnotherExamCertificateData').get('mathSubject').get('score').patchValue(this.appShortInfo.aLevelAnotherExamCertificateData.mathSubject.score);
        }

        if (this.appShortInfo.anotherExamTypeId == 3) {
          // act
          this.applicationForm.get('astAnotherExamCertificateData').get('englishScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.englishScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('mathScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.mathScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('readingScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.readingScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('registrationNumber').patchValue(this.appShortInfo.astAnotherExamCertificateData.registrationNumber);
          this.applicationForm.get('astAnotherExamCertificateData').get('scienceScore').patchValue(this.appShortInfo.astAnotherExamCertificateData.scienceScore);
          this.applicationForm.get('astAnotherExamCertificateData').get('testDate').patchValue(this.appShortInfo.astAnotherExamCertificateData.testDate);
        }


        // this.applicationForm.get('sexId').patchValue(0);
        this.applicationForm.get('sexId').setValue(this.appShortInfo.sexId);
        // console.log(this.appShortInfo.sexId);
        this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
        this.applicationForm.get('phoneNumber').patchValue(this.appShortInfo.phoneNumber);
        this.applicationForm.get('profileSubjectPairId').patchValue(this.appShortInfo.profileSubjectPairId);
        this.applicationForm.get('schoolId').patchValue(this.appShortInfo.schoolId);
        this.applicationForm.get('foreignLangCertTestDate').patchValue(this.appShortInfo.foreignLangCertTestDate);


        this.getLangList(0);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  schoolChange(e:any){
    this.schoolList.forEach((elem:any)=>{
      if(+elem.id === +e){
        this.isEmergency = elem.isEmergency;
      }
    })

    this.applicationForm.get('testOrgId').patchValue(0);
    this.applicationForm.get('testPeriodId').patchValue(0);
  }

  getIBMathSubjects() {
    this.api.getMathSubjects()
      .subscribe((res: any) => {
        this.mathSubjects = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getALevelMathSubjects(){
    this.api.getALevelMathSubjects()
      .subscribe((res: any) => {
        this.ALevelMathSubjects = res.items;
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  subject(id = 0, score): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      score: new FormControl(score)
    });
  }

  subjectNew(): FormGroup {
    return new FormGroup({
      id: new FormControl(0),
      score: new FormControl()
    });
  }

  checkSubject() {
    if (this.applicationForm.get('satAnotherExamCertificateData').get('isSubjectsExists').value == false) {
      return true;
    }
  }

  dwnldCertifCheck(id: number): boolean {
    return this.certifFiles.controls[id].get('filename').value !== '';
  }

  clearFiles() {

    this.applicationForm.get('anotherExamFiles').value.forEach(element => {
      this.removeCertifFile('0');
    });



    // if (this.appShortInfo.satAnotherExamCertificateData == undefined && this.applicationForm.get('anotherExamTypeId').value == 1) {
    //   for (let i = 0; i < 2; i++) {
    //     this.subjects.push(this.subjectNew());
    //   }

    // }


  }

  removeCertifFile(type: string) {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.certifFiles;

    obj.removeAt(type);
  }

  getSertifs() {
    this.api.getDopSertifs(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.dopSertifsList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getNationList() {
    this.api.getNationsEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getCountryList() {
    this.api.getCountryEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.countryList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getForeignLangCertList() {
    this.api.getForeignLangCertEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.foreignLangCertList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getOblList() {
    this.api.getOblEnt(this.seasonId, this.appTypeId, this.applicationId)
      .subscribe((res: any) => {
        this.applicationForm.get('oblId').setValue(0);
        this.applicationForm.get('raionId').setValue(0);
        this.applicationForm.get('schoolId').setValue(0);
        this.oblList = res.items;
        this.testOblList = res.items;
        this.getEduInfo();
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getRaionList(isEdit = false) {
    if(!isEdit){
      this.applicationForm.get('raionId').setValue(0);
      this.applicationForm.get('schoolId').setValue(0);
      this.raionList = [];
      this.schoolList = [];
    }

    this.api.getRaionEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationId)
      .subscribe((res: any) => {
        this.raionList = res.items;
        this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.appShortInfo.testOrgOblId, this.applicationId)
          .subscribe((res: any) => {
            this.testOrgList = res.items;
            this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
            this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
            this.getTestDate();

          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
          });
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSchoolList(isEdit = false) {
    if(!isEdit){
      this.applicationForm.get('schoolId').setValue(0);
      this.schoolList = [];
    }

    this.api.getSchoolEnt(this.seasonId, this.appTypeId, this.applicationForm.get('oblId').value, this.applicationForm.get('raionId').value, this.applicationId)
      .subscribe((res: any) => {
        this.schoolList = res.items;
        if(isEdit){
          this.schoolList.forEach((elem:any)=>{
            if(+elem.id === +this.applicationForm.get('schoolId').value){
              this.isEmergency = elem.isEmergency;
            }
          })
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getEduInfo() {
    this.api.getEduInfoEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        if (res.data.oblId != 0) {
          this.applicationForm.get('oblId').setValue(res.data.oblId);
          this.canEditOblId = true;
          this.api.getRaionEnt(this.seasonId, this.appTypeId, res.data.oblId)
            .subscribe((res: any) => {
              this.raionList = res.items;
              this.api.getTestOblEnt(this.seasonId, this.appTypeId, this.appShortInfo.testOrgOblId, this.applicationId)
                .subscribe((res: any) => {
                  this.testOrgList = res.items;
                  this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
                  this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
                  this.getTestDate();

                }, (err: any) => {
                  this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
                });
            }, (err: any) => {
              this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            });
          if (res.data.raionId != 0) {
            this.applicationForm.get('raionId').setValue(res.data.raionId);
            this.canEditRaionId = true;
            this.getSchoolList(true);
            if (res.data.schoolId != 0) {
              this.canEditSchoolId = true;
              this.applicationForm.get('schoolId').setValue(res.data.schoolId);
            }
          }
        } else {
          this.applicationForm.get('oblId').patchValue(this.appShortInfo.oblId);
          this.getRaionList(true);
          this.applicationForm.get('raionId').patchValue(this.appShortInfo.raionId);
          this.getSchoolList(true);
          this.applicationForm.get('schoolId').patchValue(this.appShortInfo.schoolId);
        }
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getSubjectPair() {
    this.api.getProfileSubjectEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.subjectPairList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getLangList(code:number) {
    this.api.getLangEnt(this.seasonId, this.appTypeId, this.applicationForm.get('profileSubjectPairId').value)
      .subscribe((res: any) => {
        this.langList = res.items;
        if(code === 0){
          this.getProfileSubject();
          this.applicationForm.get('testLangId').patchValue(this.appShortInfo.testLangId);
        }else{
          this.getProfileSubject(1);
          this.applicationForm.get('testLangId').patchValue(0);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getProfileSubject(code = 0) {
    if( this.applicationForm.get('profileSubjectPairId').value != 0){

      if(code === 1){
        // this.applicationForm.get('satAnotherExamCertificateData').reset();
        // this.applicationForm.get('astAnotherExamCertificateData').reset();
        // this.applicationForm.get('ibAnotherExamCertificateData').reset();
        // this.applicationForm.get('aLevelAnotherExamCertificateData').reset();

        this.applicationForm.get('ibAnotherExamCertificateData').get('mathSubject').reset();
        this.applicationForm.get('aLevelAnotherExamCertificateData').get('mathSubject').reset();

        this.subjects.clear()
        this.subjectsIb.clear()
        this.subjectsALevel.clear()

        for(let i = 0; i < 2; i++){
          this.subjects.push(this.subjectNew());
        }

        for(let i = 0; i < 2; i++){
          this.subjectsIb.push(this.subjectNew());
        }

        for(let i = 0; i < 2; i++){
          this.subjectsALevel.push(this.subjectNew());
        }
      }

      if(this.applicationForm.get('anotherExamTypeId').value == 1){
        this.api.getSatProfileSubject(this.applicationForm.get('profileSubjectPairId').value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }
      if(this.applicationForm.get('anotherExamTypeId').value == 2){
        this.api.getIbSubjects(this.applicationForm.get('profileSubjectPairId').value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }
      if(this.applicationForm.get('anotherExamTypeId').value == 4){
        this.api.getALevelSubjects(this.applicationForm.get('profileSubjectPairId').value)
        .subscribe((res: any) => {
          this.subjectList = res.items;
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
      }
    }

  }

  getTestOrg() {
    this.api.getTestOrgEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getRct(id: number) {
    this.api.getTestOblEnt(this.seasonId, this.appTypeId, id, this.applicationId)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        this.applicationForm.get('testOrgId').patchValue(0);
        this.applicationForm.get('testPeriodId').patchValue(0);
        if (this.applicationForm.get('testOrgOblId').value != 0) {
          // this.applicationForm.get('testOrgOblId').patchValue(this.appShortInfo.testOrgOblId);
          // this.applicationForm.get('testOrgId').patchValue(this.appShortInfo.testOrgId);
        }
        this.getTestDate();

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  getTestDate() {
    this.api.getTestOrgItemsEnt(this.seasonId, this.appTypeId, this.applicationForm.get('testOrgId').value, this.applicationId, this.isEmergency)
      .subscribe((res: any) => {
        this.testDateList = res.items;
        this.applicationForm.get('testPeriodId').patchValue(this.appShortInfo.testPeriodId);
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  checkSertif(id) {
    if (id == 4) {
      this.foreignLangCertScoreMask = '0.0';
    }
    if (id != 4) {
      this.foreignLangCertScoreMask = '000';
    }
    if (id == 0) {
      this.applicationForm.get('foreignLangCertScore').patchValue(0);
    }
  }

  scoreCheck() {
    if (this.applicationForm.get('foreignLangCertTypeId').value == 4 && this.applicationForm.get('foreignLangCertScore').value > 9) {
      this.applicationForm.get('foreignLangCertScore').patchValue(this.applicationForm.get('foreignLangCertScore').value / 10);
    }
  }

  EditApplication() {
    this.scoreCheck();
    this.loading = true;
    this.api.postEditApplicationEnt(this.applicationForm.value, this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.router.navigateByUrl('/profile/history');
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  agreeCheck() {
    this.applicationAgree = !this.applicationAgree;
  }

  getHelper() {
    this.api.getDisabilityEnt(this.seasonId, this.appTypeId)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
        let value = this.appShortInfo.isHasDisability;
        this.applicationForm.get('isHasDisability').setValue(value.toString());
        this.applicationForm.get('disabilityId').setValue(this.appShortInfo.disabilityId);
        this.applicationForm.get('isNeedHelperDueDisability').setValue(this.appShortInfo.isNeedHelperDueDisability.toString());
      }, (err: any) => {

      });
  }

  get files(): any { return this.applicationForm.get('docFiles') };
  get certifFiles(): any { return this.applicationForm.get('anotherExamFiles') };
  get subjects(): FormArray { return this.applicationForm.get('satAnotherExamCertificateData').get('subjects') as FormArray }
  get subjectsIb(): FormArray { return this.applicationForm.get('ibAnotherExamCertificateData').get('subjects') as FormArray  }
  get subjectsALevel() : FormArray { return this.applicationForm.get('aLevelAnotherExamCertificateData').get('subjects') as FormArray }

  closeBar(index: number) {
    if (index == 0) {
      this.barArray[index] = true;
      this.barArray[index + 1] = false;
    } else {
      if (this.applicationForm.get('anotherExamTypeId').value == 0 && index == 2) {
        this.barArray[index] = true;
        if (this.appShortInfo.canChangeTestPeriod) {
          this.barArray[index + 2] = false;
        } else {
          this.barArray[index + 3] = false;
        }
      } else {
        if (index == 3 && !this.appShortInfo.canChangeTestPeriod) {
          this.barArray[index] = true;
          this.barArray[index + 2] = false;
        }
        else {
          this.barArray[index] = true;
          this.barArray[index + 1] = false;
        }

      }
    }
  }

  addCertifFile() {
    let obj = null;

    if (this.appShortInfo.anotherExamFiles.length > 0) {
      this.appShortInfo.anotherExamFiles.forEach(element => {

        obj = this.certifFiles;

        obj.push(
          new FormGroup({
            filename: new FormControl(element.filename, [Validators.required]),
            id: new FormControl(element.id),
            data: new FormControl('')
          })
        );

      });
    } else {
      obj = this.certifFiles;

      obj.push(
        new FormGroup({
          filename: new FormControl('', [Validators.required]),
          id: new FormControl(0),
          data: new FormControl()
        })
      );
    }

  }

  changeCertifListener($event, i: number): void {
    let obj = null;

    obj = this.certifFiles;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }


  addFiles() {
    let obj = null;
    this.appShortInfo.docFiles.forEach(element => {

      obj = this.files;

      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          data: new FormControl('')
        })
      );

    });
  }

  addFile() {
    let obj = null;

    obj = this.files;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event, i: number): void {
    let obj = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile(type: string) {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    // this.fileDeleteModal = false;
    // this.editFileDeleteModal = false;
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

}
