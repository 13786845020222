<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div *ngIf="router?.url == '/profile/prob-test/6/' + id" class="">
  <div *ngIf="deleteModal" class="">
    <div class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed">
    </div>
    <div class="text-center border-2 p-4 realy-delete fixed z-30 w-1/3 bg-white">
      <p class="mb-8">
        {{ 'SureDeleteApplication' | translate }}?
      </p>
      <div class="flex justify-around w-64 mx-auto">
        <button (click)="deleteProb()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {{ 'Yes' | translate }}
        </button>
        <button (click)="deleteModal = !deleteModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          {{ 'No' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
