import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const excludedUrls = ['/auth', '/registration', '/forgot-pass'];
    //
    // if (excludedUrls.some(url => req.url.includes(url))) {
    //   return next.handle(req);
    // }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 || error.status === 403) && !localStorage.getItem('token') ) {
          // localStorage.removeItem('token');
          this.router.navigate(['/auth']);
        }
        return throwError(error);
      })
    );
  }
}
