<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="block space-y-4 md:flex md:space-y-0 md:space-x-4 ">
  <button (click)="modal = true"
          class="mx-auto w-full mb-8 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded"
          type="button">
    {{ 'Buy' | translate}}
  </button>
</div>


<div *ngIf="modal == true" class="overlay">
  <div class="modal-window">
    <div class="relative">
      <div class="modal-sub-header">
        {{ 'OnlinePay' | translate }}
      </div>
    </div>

    <div class="modal-text">
      <div class="flex kaspi-content">
        <div class="w-1/2 kaspi-width">
          <p>
            {{ 'KaspiInstructions-1' | translate }}:
          </p>
          <ol class="kaspi-list">
            <li>
              {{ 'KaspiInstructions-2' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-3' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-4' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-5' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-6' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-7' | translate }}
            </li>
            <li>
              {{ 'KaspiInstructions-81' | translate }}
            </li>
          </ol>
        </div>
        <div class="w-1/2 kaspi-width">
          <div *ngIf="kaspiOrderId != 0" class="kaspi-qr">
            <div>
              <img src="../../../../../../assets/kaspi.jpeg" class="kaspiimg" alt="">
            </div>
            <div class="obshee">
              <qrcode class="qrcode" [qrdata]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId"
                      [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
            </div>
          </div>
          <div class="text-center my-4">
            <a target="_blank" [href]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId">
              <img src="../../../../../../assets/images/Gold.svg" *ngIf="kaspiOrderId != 0"
                   style="margin: 0 auto;cursor: pointer;">
            </a>
          </div>
          <div class="text-center" *ngIf="kaspiOrderId == 0">
            <p class="mb-3 font-bold">{{'ProbPrice' | translate: {value: test.costOfAttempt} }}</p>
            <p class="text-sm font-semibold">{{'AddNumberTestingAttempts' | translate}}</p>
            <p class="mb-3 text-sm font-semibold">{{'AddNumberTestingAttemptsContinue' | translate: {value: test.variantCount} }}</p>
            <div class="mb-3" data-te-input-wrapper-init>
              <input type="number" [(ngModel)]="quantity" required
                     class="w-full text-center bg-gray-200 rounded mb-2"
                     id="exampleFormControlInputNumber" [min]="1" [max]="5" maxlength="5"
                     placeholder="{{'NumberTestingAttempts' | translate}}" />
            </div>
            <button *ngIf="kaspiOrderId == 0 && quantity >0 && quantity<=5" (click)="createOrder()"
                    class="create-order bg-blue-800 hover:bg-blue-700">
              {{ 'FormAnOrder' | translate }}
            </button>
          </div>
          <div class="nomer-zakaza text-center font-bold" *ngIf="kaspiOrderId != 0">
            {{ 'YourOrderNumber' | translate }}: {{ kaspiOrderId }}
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="kaspiOrderId != 0">
            <button (click)="closeModal()"
                    class="mx-auto w-full mt-10 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{'Close' | translate}}</button>
        </span>
    <span *ngIf="kaspiOrderId == 0">
        <button (click)="closeModal()"
                class="mx-auto w-full mt-10 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{'Cancel' | translate}}</button>
       </span>
  </div>
</div>

<div class="relative overflow-x-auto sm:rounded-lg shadow-inner">
  <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400 mb-4">
    <thead class="text-xs text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      <th scope="col" class="px-6 py-3">
        {{'DateAdd' | translate}}
      </th>
      <th scope="col" class="px-6 py-3">
        {{'TestStatus' | translate}}
      </th>
      <th scope="col" class="px-6 py-3">
        {{'Link' | translate}}
      </th>
    </tr>
    </thead>
    <tbody *ngFor="let item of pageOfItems">
    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 text-gray-900">
      <td class="py-1">
        {{item.createdAt}}
      </td>
      <td class="py-1">
                    <span *ngIf="translate.currentLang=='kz'">
                        {{item.testStatusName.kk}}
                    </span>
        <span *ngIf="translate.currentLang=='ru'">
                        {{item.testStatusName.ru}}
                    </span>
      </td>
      <td class="py-1">
                    <span *ngIf="translate.currentLang=='kz'">
                        <button (click)="link(item)"
                                class="mx-auto w-full hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{item.actionName.kk}}</button>
                    </span>
        <span *ngIf="translate.currentLang=='ru'">
                        <button (click)="link(item)"
                                class="mx-auto w-full hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{item.actionName.ru}}</button>
                    </span>
      </td>
    </tr>
    </tbody>
  </table>
  <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
</div>
