import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, FormControlName } from "@angular/forms";
import { ApiService } from "../../../../service/api.service";
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/service/message.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-testing-for-citizenship',
  templateUrl: './testing-for-citizenship.component.html',
  styleUrls: ['./testing-for-citizenship.component.css']
})
export class  TestingForCitizenshipComponent implements OnInit {

  loading = false;
  applicationForm: FormGroup;
  disabilityHelperInfo: FormGroup;
  disabilityDocPhoto: FormGroup;
  jobTypeId = 5;
  pageLangId: any;
  subjectCount: any;
  subjectCountArray = [];
  showNasPunkt = true;
  fromActualCategories = [];
  toActualCategories = [];
  disabilityList = [];
  langList = [];
  testOrg = [];
  testPeriod = [];
  lastChild = false;
  year = '0000';
  fullDate = '00-00-0000';
  iin = "000000000000";
  phone = '+0 (000) 000-00-00';
  employmentTypeList: any;
  oblList: any;
  instituteSpecialityList: any;
  collegeSpecialityList: any;
  vuzObl = 0;
  raion = 0;
  vuzList: any;
  sexId: any;
  citizenship: any;
  raionList: any;
  appTypeId: any;
  citizenshipList = [];
  testTypeId: any;
  citizenshipId: any;
  eduType: any;
  birthDate: any;
  modalAgreement = false;
  sogData: any;
  blockBtn = true;
  testPeriodId: any;
  profileDataList = [];
  disabilityId: any;
  testLangId: any;
  nationsList = [];
  testorgList = [];
  isDisabilityHelperRequired = false;
  testOrgId: any;
  testPeriodList = [];
  selectedFile: File | null = null;
  selectedFileDisab: File | null = null;
  filename: any;
  specialityName: any;

  constructor(private fb: FormBuilder,
              public api: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private _notifications: NotificationsService,
              public translate: TranslateService,
              private messageService: MessageService,
              public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {

    if (localStorage.getItem('Lang') == 'ru') {
      this.pageLangId = 2;
    } else {
      this.pageLangId = 1;
    }


    this.messageService.getCitizen().subscribe(res => this.sogData = res);

    this.api.profileInfo()
      .subscribe((res: any) => {
        this.profileDataList = res.data;
        this.citizenship = res.data.citizenship;
        this.applicationForm.get('iin').setValue(res.data.iin);
        this.applicationForm.get('firstname').setValue(res.data.firstname);
        this.applicationForm.get('lastname').setValue(res.data.lastname);
        this.applicationForm.get('birthDate').setValue(res.data.birthDate);
        this.applicationForm.get('patronymic').setValue(res.data.patronymic);
        this.applicationForm.get('email').setValue(res.data.email);
        this.applicationForm.get('phoneNumber').setValue(res.data.phoneNumber);
        this.applicationForm.get('citizenshipId').setValue(res.data.citizenshipId);
        this.applicationForm.get('sexId').setValue(res.data.sexId);
        this.applicationForm.get('nationId').setValue(res.data.nationId);

      }, (err: any) => {

      });

    this.applicationForm = this.fb.group({
      jobTypeId: new FormControl(this.jobTypeId),
      isHasDisability: new FormControl(0),
      lastname: new FormControl(),
      disabilityId: new FormControl(),
      isDisabilityHelperRequired: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      iin: new FormControl(),
      testOrgId: new FormControl(0),
      birthDate: new FormControl(),
      citizenshipId: new FormControl(),
      testPeriodId: new FormControl(0),
      testLangId: new FormControl(0),
      nationId: new FormControl(),
      sexId: new FormControl(),
      oblId: new FormControl(0),
      applicationAgree: new FormControl({value: false, disabled: true}),
      disabilityDocPhoto: this.fb.group({
        filename: new FormControl(),
        data: new FormControl()
      }),
      disabilityHelperInfo: this.fb.group({
        iin: new FormControl(),
        phoneNumber: new FormControl(),
        specialityName: new FormControl(),
        lastname: new FormControl(),
        patronymic: new FormControl(),
        firstname: new FormControl(),
      })

    });

    this.getCitizenshipList();
    this.getntionList();
    this.getOblList();
    this.getLangList();
    this.getDisabilityList();
  }


  getCitizenshipList() {
    this.api.citizenship(1)
      .subscribe((res: any) => {
        this.citizenshipList = res.items;
      });
  }

  getntionList() {
    this.api.getNation(1)
      .subscribe((res: any) => {
        this.nationsList = res.items;
      });
  }

  getLangList() {
    this.api.getLang(1)
      .subscribe((res: any) => {
        this.langList = res.items;

      });
  }

  getOblList() {
    this.api.getObl(1)
      .subscribe((res: any) => {
        this.oblList = res.items;
        this.getTestOrgList(1);
        this.getTestPeriodList(1);

      });
  }

  getTestOrgList(code: any) {
    this.api.getTestOrg(1, this.applicationForm.get('oblId').value)
      .subscribe((res: any) => {
        this.testorgList = res.items;
        if (code == 0) {
          this.applicationForm.get('testOrgId').setValue(this.testOrgId);
        } else {
          this.applicationForm.get('testOrgId').setValue(0);
        }
        this.getTestPeriodList(1);
      });
  }

  getTestPeriodList(code: any) {
    this.api.getTestPeriod(1, this.applicationForm.get('testOrgId').value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
        if (code == 0) {
          this.applicationForm.get('testPeriodId').setValue(this.testPeriodId);
        } else {
          this.applicationForm.get('testPeriodId').setValue(0);

        }
      });
  }

  getDisabilityList() {
    this.api.disability(1)
      .subscribe((res: any) => {
        this.disabilityList = res.items;
      });
  }

  onScrollEnd(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.applicationForm.get('applicationAgree').enable();
    }
  }

  agreeCheck(e: any) {
    this.applicationForm.get('applicationAgree').setValue(e.target.checked);
  }


  // onFileSelectedDisab(event: Event) {
  //   const fileInput = event.target as HTMLInputElement;
  //   if (fileInput.files && fileInput.files.length > 0) {
  //     this.selectedFileDisab = fileInput.files[0];
  //     this.filename = this.selectedFileDisab.name;
  //     this.applicationForm.get('disabilityDocPhoto.photo.filename')?.setValue(this.filename);
  //
  //   }
  // }

  // onFileSelected(event: Event) {
  //   const fileInput = event.target as HTMLInputElement;
  //   if (fileInput.files && fileInput.files.length > 0) {
  //     this.selectedFile = fileInput.files[0];
  //     this.filename = this.selectedFile.name;
  //     this.applicationForm.get('disabilityDocPhoto.photo.filename')?.setValue(this.filename);
  //
  //   }
  // }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      const disabilityDocPhoto = this.applicationForm.get('disabilityDocPhoto');

      // Установка имени файла
      disabilityDocPhoto?.get('filename')?.setValue(this.selectedFile.name);

      // Чтение файла как base64
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        const base64Data = (reader.result as string).replace(/^data:.*;base64,/, '');
        disabilityDocPhoto?.get('data')?.setValue(base64Data);
      };
    }
  }


  postForm() {
    const formData = this.applicationForm.value;
    // console.log(formData);
    // if (this.selectedFile) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(this.selectedFile);
    //   reader.onload = () => {
    //     const base64String = (reader.result as string);
    //     const cleanBase64 = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
    //     formData.disabilityDocPhoto.photo.data = reader.result;
    //     this.filename = this.selectedFile.name;
    //     formData.disabilityDocPhoto.photo.data = cleanBase64;
        this.api.getAppCitizen(formData)
          .subscribe((res: any) => {
              this.router.navigateByUrl('/profile/history');
          },(err: any) => {
              this.loading = false;
              this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
            });
      // };
    // }
  }

}
