import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {MessageService} from '../../../../../service/message.service';

@Component({
  selector: 'app-zayav-prob-dir',
  templateUrl: './zayav-prob-dir.component.html',
  styleUrls: ['./zayav-prob-dir.component.css']
})
export class ZayavProbDirComponent implements OnInit {
  loading = false;
  applicationForm: FormGroup;
  lastname: any;
  firstname: any;
  patronymic: any;
  phoneNumber: any;
  iin: any;
  test: any;
  email: any;
  phone = '+0 (000) 000-00-00';
  obls = [];
  testOrgList = [];
  testPeriodList = [];
  items: any;
  id: any;

  constructor(
    public api: ApiService,
    private _notifications: NotificationsService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public router: Router,
    private messagService: MessageService

  ) {
    this.router = router;
    this.route.paramMap.subscribe(params => {this.id = params.get('id');
    });

    this.applicationForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(0, Validators.required),
      oblId: new FormControl(0, Validators.required),
      testOrgId: new FormControl(0, Validators.required),
      testPeriodId: new FormControl(0, Validators.required),
    });
  }

  ngOnInit(): void {
    this.test = JSON.parse(sessionStorage.getItem('info'));

    this.getObl();

    this.api.profileInfo().subscribe((res: any) => {
      this.lastname = res.data.lastname;
      this.firstname = res.data.firstname;
      this.patronymic = res.data.patronymic;
      this.phoneNumber = res.data.phoneNumber;
      this.iin = res.data.iin;
      this.email = res.data.email;
      this.applicationForm.get('email').patchValue(res.data.email);
      this.applicationForm.get('phoneNumber').patchValue(res.data.phoneNumber);
    });
  }
  getObl(){
    this.api.getObls(this.id)
      .subscribe((res: any) => {
        this.obls = res.items;
        this.testPeriodList = [];
        this.applicationForm.get('testOrgId')!.setValue(0);
        this.applicationForm.get('testPeriodId')!.setValue(0);
      });
  }
  getTestOrg(code: any){
    this.api.getTestOrgs(this.id, this.applicationForm.get('oblId')!.value)
      .subscribe((res: any) => {
        this.testOrgList = res.items;
        this.testPeriodList = [];
        this.applicationForm.get('testPeriodId')!.setValue(0);
        if(code === 0){
          this.applicationForm.get('testOrgId')!.setValue(0);
        }
      });
  }

  getTestPeriod(code: any){
    this.api.getTestPeriods(this.id, this.applicationForm.get('testOrgId')!.value)
      .subscribe((res: any) => {
        this.testPeriodList = res.items;
        if(code === 0){
          this.applicationForm.get('testPeriodId')!.setValue(0);
        }
      });
  }
  postForm(){
    // this.loading = true;
    // this.api.postData(4, this.applicationForm.value)
    //   .subscribe({
    //     next: (res: any) => {
    //       this.loading = false;
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'подано успешно', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті қабылданды', NotificationType.Success, 5000);
    //       if(res.data.paymentTypeId == 2 && res.data.testStatusId != 9){
    //         this.router.navigate([`profile/applications/payment`], {  queryParams: { id: res.data.id, type: 13}});
    //       }else{
    //         window.location.href = '/profile/prob-test/4/2';
    //
    //       }
    //     },
    //     error: (err: any) => {
    //       this.loading = false;
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
    //     }
    //   });
  }
}
