<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ "PType" | translate }}
</p>
<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ 'probNct' | translate}}
</p>
<form [formGroup]="applicationForm" action="">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ "PersonalData" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "IIN" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ iin }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Surname" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ lastname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Name" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ firstname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Patronymic" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ patronymic }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "YourEmail" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ email }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "PhoneNumber" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input formControlName="phoneNumber" [mask]="phone" placeholder="+7 (000) 000-00-00" class="w-full pl-1 bg-gray-200 rounded" type="text"/>
    </div>
  </div>
  <div class="flex mt-4 mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ "TestParams" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "SelectTestTypes" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select (change)="changeEnt()" formControlName="testTypeId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectTestTypes" | translate }}
        </option>
        <option *ngFor="let item of testTypeEntList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 62" class="flex rounded-mdp-4">
      {{ "SelectProfileSubjects" | translate }}
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 62" class="flex rounded-mdp-4">
      <select (change)="profileLangPair()" formControlName="profileSubjectPairId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectProfileSubjects" | translate }}
        </option>
        <option *ngFor="let item of sabjPairList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 62 && applicationForm.get('profileSubjectPairId').value != 0" class="flex rounded-mdp-4">
      {{ "TestLangs" | translate }}
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 62 && applicationForm.get('profileSubjectPairId').value != 0" class="flex rounded-mdp-4">
      <select (change)="varInfoEntList()" formControlName="testLanguageId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "TestLangs" | translate }}
        </option>
        <option *ngFor="let item of testLanguageList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63" class="flex rounded-mdp-4">
      {{ "colledgeList" | translate }}
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63" class="flex rounded-mdp-4">
      <select (change)="eduProgram()" formControlName="collegeSpecId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "colledgeList" | translate }}
        </option>
        <option *ngFor="let item of collegList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.code }} - {{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.code }} - {{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63 && applicationForm.get('collegeSpecId').value != 0" class="flex rounded-mdp-4">
      {{ "GOP" | translate }}
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63 && applicationForm.get('collegeSpecId').value != 0" class="flex rounded-mdp-4">
      <select (change)="testLanguageTipo()" formControlName="eduProgramId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "GOP" | translate }}
        </option>
        <option *ngFor="let item of eduList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.code }} - {{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.code }} - {{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63 && applicationForm.get('eduProgramId').value != 0" class="flex rounded-mdp-4">
      {{ "TestLangs" | translate }}
    </div>
    <div *ngIf="applicationForm.get('testTypeId').value == 63 && applicationForm.get('eduProgramId').value != 0" class="flex rounded-mdp-4">
      <select (change)="varInfoEntList()" formControlName="testLanguageId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "TestLangs" | translate }}
        </option>
        <option *ngFor="let item of testLanguageList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div style="width: 200%" class="flex justify-center mt-8">
      <table *ngIf="varlist?.length > 0" class="text-sm text-center text-gray-500 dark:text-gray-400 border-collapse">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;" colspan="5">
          <span [ngSwitch]="translate.currentLang">
            <span *ngSwitchCase="'kz'">Нұсқалар саны</span>
            <span *ngSwitchCase="'ru'">Количество вариантов</span>
          </span>
          </th>
        </tr>
        <tr>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;">№</th>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;">
          <span [ngSwitch]="translate.currentLang">
            <span *ngSwitchCase="'kz'">пән</span>
            <span *ngSwitchCase="'ru'">предмет</span>
          </span>
          </th>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;">
          <span [ngSwitch]="translate.currentLang">
            <span *ngSwitchCase="'kz'">Барлығы</span>
            <span *ngSwitchCase="'ru'">Всего</span>
          </span>
          </th>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;">
          <span [ngSwitch]="translate.currentLang">
            <span *ngSwitchCase="'kz'">Қолданылған</span>
            <span *ngSwitchCase="'ru'">Использовано</span>
          </span>
          </th>
          <th style="padding: 5px; border: 1px solid black; background: #ffffff;">
          <span [ngSwitch]="translate.currentLang">
            <span *ngSwitchCase="'kz'">Қолданылмаған</span>
            <span *ngSwitchCase="'ru'">не использовано</span>
          </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of varlist" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td style="padding: 5px; border: 1px solid black; background: #ffffff;">{{ item.ord }}</td>
          <td style="padding: 5px; border: 1px solid black; background: #ffffff;">
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }}</span>
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }}</span>
          </td>
          <td style="padding: 5px; border: 1px solid black; background: #ffffff;">{{ item.totalVarCount }}</td>
          <td style="padding: 5px; border: 1px solid black; background: #ffffff;">{{ item.usedVarCount }}</td>
          <td style="padding: 5px; border: 1px solid black; background: #ffffff;">{{ item.notUsedVarCount }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex mt-4 mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ "TestParams" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "SelectObl" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select (change)="getTestOrg()" formControlName="testOrgOblId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectObl" | translate }}
        </option>
        <option *ngFor="let item of obls" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "SelectTestSite" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select (change)="getTestPeriod()" formControlName="testOrgId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectTestSite" | translate }}
        </option>
        <option *ngFor="let item of testOrgList" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "SelectTestDate" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select formControlName="testPeriodId" class="w-full pl-1 bg-gray-200 rounded">
        <option disabled [value]="0">
          {{ "SelectTestDate" | translate }}
        </option>
        <option *ngFor="let item of testPeriodList" [value]="item.id">
          {{ item.testDate }} ({{'FreePlaceCount' | translate}}:{{item.freePlaceCount}})
        </option>
      </select>
    </div>
  </div>
</form>
<div class="mt-4 flex">
  <button (click)="postForm()" class="bg-blue-500  hover:bg-blue-700 w-1/2 mx-2 rounded-lg cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
    {{ 'Edit' | translate }}
  </button>
  <button [routerLink]="'/profile/prob-test/2/2'" class="bg-red-500 mx-2 hover:bg-red-700 w-1/2 rounded-lg cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
    {{ 'Cancel' | translate }}
  </button>
</div>
