<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<p
  class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4"
>
  {{ "KazTest" | translate }}
</p>

<p
  class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4"
>
  {{ (testTypeId === 1 ? 'kaztestCert' : 'kaztestDiag') | translate}}
</p>
<form [formGroup]="applicationForm" action="">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "PersonalData" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>

  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "IIN" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ iin }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Surname" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ lastname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Name" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ firstname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Patronymic" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ patronymic }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "YourEmail" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ email }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "DateOfBirth" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input
        disabled
        class="w-full pl-1 bg-gray-200 rounded"
        type="date"
        formControlName="birthDate"
        (change)="getDateOfBirth($event.target.value)"
      />
    </div>

    <div class="flex rounded-mdp-4">
      {{ "Citizenship" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select
        formControlName="citizenshipId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "IndicateСitizenship" | translate }}
        </option>
        <option
          [disabled]="!canEditCitizenshipId"
          *ngFor="let item of countryList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Nationality" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select
        formControlName="nationId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "IndicateNationality" | translate }}
        </option>
        <option
          [disabled]="!canEditNationId"
          *ngFor="let item of nationsList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Sex" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <div class="flex justify-around text-center">
        <div class="mr-4">
          <input disabled
            value="1"
            [checked]="applicationForm.get('sexId').value == 1"
            formControlName="sexId"
            class="mr-2 bg-gray-200 rounded pol-radio"
            id="pol1"
            type="radio"
          />
          <label for="pol1">{{ "Male" | translate }}</label>
        </div>
        <div class="">
          <input disabled
            value="2"
            [checked]="applicationForm.get('sexId').value == 2"
            formControlName="sexId"
            class="mr-2 bg-gray-200 rounded pol-radio"
            id="pol2"
            type="radio"
          />
          <label for="pol2">{{ "Female" | translate }}</label>
        </div>
      </div>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "PhoneNumber" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input
        formControlName="phoneNumber"
        [mask]="phone"
        placeholder="+7 (000) 000-00-00"
        class="w-full pl-1 bg-gray-200 rounded"
        type="text"
      />
    </div>
  </div>

  <div class="flex mt-4 mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "TestParams" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>

  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
        {{ "SelectCategory" | translate }}
      </div>
      <div class="flex rounded-mdp-4">
        <select
        formControlName="categoryId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "SelectCategory" | translate }}
        </option>
        <option
          *ngFor="let item of categories"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
        {{ "SelectObl" | translate }}
      </div>
      <div class="flex rounded-mdp-4">
        <select (change)="changeObl($event)"
        formControlName="oblId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "SelectObl" | translate }}
        </option>
        <option
          *ngFor="let item of obls"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
      </div>

      <div class="flex rounded-mdp-4">
        {{ "SelectTestSite" | translate }}
      </div>
      <div class="flex rounded-mdp-4">
        <select (change)="changeTestOrg($event)"
        formControlName="testOrgId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "SelectTestSite" | translate }}
        </option>
        <option
          *ngFor="let item of testOrgs"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
      </div>
      <div class="flex rounded-mdp-4">
        {{ "SelectTestDate" | translate }}
      </div>
      <div class="flex rounded-mdp-4">
        <select
        formControlName="testPeriodId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "SelectTestDate" | translate }}
        </option>
        <option
          *ngFor="let item of testPeriod"
          [value]="item.id"
        >
          {{ item.testDate }} ({{'FreePlaceCount' | translate}}:{{item.freePlaceCount}})

        </option>
      </select>
      </div>
      <div class="flex rounded-mdp-4">
        {{ "photo" | translate }}
      </div>
      <div class="flex rounded-mdp-4" *ngIf="!isEdit">
        <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
        (click)="addPhoto()">{{ 'addFile' | translate }}</button>
        <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
      </div>
  </div>
  <span class="add-files-scroll" *ngIf="visibleLoad">
    <div [formGroup]="photo" class="w-full mt-4 controls-border">
    <div>
            <span> {{ 'NameFile' | translate }}: <input disabled class="mb-4 p-2 w-full" type="text" readonly
                    formControlName="filename"></span>
            <div class="f-btn-group">
                <div class="relative">
                    <input (change)="changePhoto($event)"
                        class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"  accept=".jpg, .jpeg" />
                    <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                        <p>
                            <span *ngIf="photoCheck(); else elseBlock"> {{ 'ReplaceFile' | translate
                                }}</span>
                            <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                        </p>
                    </div>
                    <p *ngIf="!photoCheck()" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' |
                        translate }}</p>
                </div>
                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded cursor-pointer" *ngIf="photoCheck()" (click)="viewPhoto = true">{{'view' | translate}}</div>
            </div>
            <div class="s-btn-group">
                <button type="button" title="Удалить файл"
                    class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    (click)="removePhoto()">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                        data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img"
                        viewBox="0 0 448 512">
                        <path fill="currentColor"
                            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</span>
</form>

<!--<div *ngIf="!isEdit">-->
<!--  <p *ngIf="translate.currentLang == 'ru'" class="text-center my-2 mt-4">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_kaztest_ru.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>-->
<!--  <p *ngIf="translate.currentLang == 'kz'" class="text-center my-2 mt-4">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_kaztest_kz.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>-->
<!--</div>-->

<!--<div class="text-center mt-4" *ngIf="!isEdit">-->
<!--  <input (change)="changeAgree($event)" type="checkbox" id="get-agree" class="get-agreement">-->
<!--  <label class="ml-2" for="get-agree">{{ 'RulesText-3' | translate }}</label>-->
<!--</div>-->


<div class="mt-4">
<!--  <button [disabled]="!applicationAgree" (click)="postForm()" *ngIf="!isEdit"-->
<!--          class="btn btn-primary w-full">-->
<!--          {{ 'PostApplication' | translate }}-->
<!--  </button>-->

  <button (click)="textModal = true" *ngIf="!isEdit"
          class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8 mobile-style-3">
    {{ 'Continue' | translate }}
  </button>

  <button (click)="postForm()" *ngIf="isEdit" [disabled]="applicationForm.invalid"
  class="btn btn-primary w-full">
  {{ 'Edit' | translate }}
</button>
</div>



<div id="myModal" class="modal" *ngIf="viewPhoto">
    <span class="close" (click)="viewPhoto = false">&times;</span>
    <img [src]="photoSrc"  class="modal-content" id="img01" (click)="viewPhoto = false">
    <div id="caption" (click)="viewPhoto = false"></div>
</div>

<div *ngIf="textModal == true" class="overlay">
  <div class="modal-window">
    <div class="relative">
      <div class="modal-sub-header">
        {{ 'PeredPod' | translate }}
      </div>
      <div (click)="closeAgreeModal()" class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold">
        X
      </div>
    </div>
    <div (scroll)="onScrollEnd($event)" class="modal-text">
            <span>
                <div>
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(ruleskazTestSogData.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(ruleskazTestSogData.contentKaz)"></div>
                </div>
            </span>
    </div>

    <div *ngIf="!rulesKazTest" class="text-center">
      <div >
        <input (change)="rulesKazTest = true" type="checkbox" id="get-agree" class="get-agreement">
        <label class="ml-2" for="get-agree">{{ 'RulesText-4' | translate }}</label>
      </div>
    </div>

    <div *ngIf="rulesKazTest">
      <div class="help-text">
        *{{ 'ReadT' | translate }}
      </div>

      <div class="text-center">
        <input [disabled]="blockBtn == true" (change)="agreeCheck()" type="checkbox" id="get-agree"
               class="get-agreement">
        <label class="ml-2" for="get-agree">{{ 'RulesKazTest' | translate }}</label>
      </div>
    </div>
    <div class="w-full text-center">
<!--      <button (click)="postForm()" [disabled]="!applicationAgreeParent"-->
<!--              class="btn btn-primary w-full">-->
<!--        {{ 'PostApplication' | translate }}-->
<!--      </button>-->
      <button (click)="postForm()" [disabled]="!applicationAgree"
              class="btn btn-primary w-full">
        {{ 'PostApplication' | translate }}
      </button>
    </div>
  </div>

</div>
