import { Component, OnInit, isDevMode } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ApiService } from 'src/app/service/api.service';
import { KaztestService } from '../kaztest/service/kaztest.service';

declare var halyk: { pay: (arg0: any) => any; };

interface EPayModel{
  invoiceId: number;
  invoiceIdAlt: string;
  backLink: string;
  failureBackLink: string;
  postLink: string;
  failurePostLink: string;
  language: string;
  description: string;
  terminal: string;
  amount: number;
  currency: string;
  email: string;
  phone: string;
  cardSave: boolean;
  auth: any;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  loading = false;
  applicationId: any;
  paymentUrl: string;
  testStatusId: any;
  paymentForm: FormGroup;
  type: any;
  step = 2;
  kaspiOrderId = 0;
  onlineOrderId = 0;
  soglWindow = false;

  paymentObject: EPayModel = {
    invoiceId: 0,
    invoiceIdAlt: '',
    backLink: isDevMode() ?  'https://app.debug.testcenter.kz/profile/history' : 'https://app.testcenter.kz/profile/history',
    failureBackLink: isDevMode() ?  'https://app.debug.testcenter.kz/profile/history' : 'https://app.testcenter.kz/profile/history',
    postLink: isDevMode() ? 'https://cabinet.debug.testcenter.kz/api/site/v1/orders/halyk/success' : 'https://cabinet.testcenter.kz/api/site/v1/orders/halyk/success',
    failurePostLink: isDevMode() ? 'https://cabinet.debug.testcenter.kz/api/site/v1/orders/halyk/failure' : 'https://cabinet.testcenter.kz/api/site/v1/orders/halyk/failure',
    language: 'kaz',
    description: '',
    terminal: '75340f88-b951-4386-ab95-e944341763d3',
    amount: 0,
    currency: 'KZT',
    email: '',
    phone: '',
    cardSave: false,
    auth: null
  };

  constructor(
    public api: ApiService,
    private kazTestApi:KaztestService,
    private router: Router,
    private route: ActivatedRoute,
    private _notifications: NotificationsService,
    public translate: TranslateService
    ) {
      this.paymentObject.language = this.translate.currentLang == 'kz' ? 'kaz' : 'rus';

    }

  ngOnInit(): void {

    this.soglWindow = true;

    this.applicationId = this.route.snapshot.queryParamMap.get('id');

    this.type = +this.route.snapshot.queryParamMap.get('type');

    switch (this.type){
      case 13:
        this.getDataProbDir();
        break;
      case 12:
        this.getDataProbKazTest();
        break;
      case 11:
        this.getDataProbCitizen();
        break;
      case 10:
        this.getDataProbOzp();
        break;
      case 9:
        this.getDataProbPvo();
        break;
      case 7:
        this.getDataProbEnt();
        break;
      case 4:
        this.getDataPvo();
        break;
      case 5:
        this.getDataDir();
        break;
      case 8:
        this.getDataKaztest();
        break;
      case 3:
        this.getDataAppeal();
        break;
      case 2:
        this.getDataCitizen();
        break;
      case 6:
        this.getDataNqt();
        break;
      default:
        this.getDataEnt();
    }


    this.paymentForm = new FormGroup({
      paymentProviderTypeId: new FormControl(),
      returnUrl: new FormControl('https://app.testcenter.kz/profile/history')
    });

  }
  isVisible(): boolean {
    return [7, 9, 10, 11, 12, 13].includes(this.type);
  }
  getDataProbPvo(){
    this.api.getShortProbEnt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataProbKazTest(){
    this.api.getShortProbEnt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataProbDir(){
    this.api.getShortProbEnt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataProbCitizen(){
    this.api.getShortProbEnt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }
  getDataProbOzp(){
    this.api.getShortProbNqt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataProbEnt(){
    this.api.getShortProbEnt(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataNqt(){
    this.api.getNqtShort(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataCitizen(){
    this.api.getCitizenShortItems(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataKaztest(){
    this.kazTestApi.getShort(this.applicationId).subscribe(
      (res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      }
    );
  }

  getDataPvo(){
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {
      });
  }

  getDataDir(){
    this.api.getDirShort(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {
      });
  }

  getDataEnt(){
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0){
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        }else{
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
        }
      },(err: any) => {
      });
  }

  getDataAppeal(){
    this.api.appealsShortInfo(this.applicationId)
      .subscribe((res: any) => {
        this.testStatusId = res.data.testStatusId;
        if (res.data.paymentProviderTypeId != 0) {
          this.paymentForm.get('paymentProviderTypeId').setValue(res.data.paymentProviderTypeId);
        } else {
          this.paymentForm.get('paymentProviderTypeId').setValue(1);
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 1) {
          this.kaspiOrderId = res.data.orderId;
        }
      });
  }

  pay(){
    this.step = 3;
  }

  createOrder(){
    switch (this.type){
      case 13:
        this.createOrderProbDir();
        break;
      case 12:
        this.createOrderProbKazTest();
        break;
      case 11:
        this.createOrderProbCitizen();
        break;
      case 10:
        this.createOrderProbOzp();
        break;
      case 9:
        this.createOrderProbPvo();
        break;
      case 7:
        this.createOrderProbEnt();
        break;
      case 4:
        this.createOrderPvo();
        break;
      case 5:
        this.createOrderDir();
        break;
      case 8:
        this.createOrderKaztest();
        break;
      case 3:
        this.createOrderKaztestAppeal();
        break;
      case 2:
        this.citizenOrder();
        break;
      case 6:
        this.orderCreateNqt();
        break;
      default:
        this.createOrderEnt();
    }
  }

  createOrderProbOzp(){
    this.loading = true;
    this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }
  createOrderProbPvo(){
    this.loading = true;
    this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderProbKazTest(){
    this.loading = true;
    this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderProbDir(){
    this.loading = true;
    this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderProbCitizen(){
    this.loading = true;
    this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }
  createOrderProbEnt(){
    this.loading = true;
  this.api.orderCreateProbEnt(this.applicationId, this.paymentForm.value)
.subscribe((res: any) => {
  if (this.paymentForm.get('paymentProviderTypeId').value == 1){
  this.loading = false;
  this.kaspiOrderId = res.data.orderId;
}
if (this.paymentForm.get('paymentProviderTypeId').value == 2){
  this.loading = false;
  this.onlineOrderId = res.data.orderId;
  this.paymentUrl = res.data.paymentUrl;
  this.paymentObject.invoiceId = res.data.orderId;
  this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
  this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
  this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
  this.paymentObject.email = res.data.email;
  this.paymentObject.phone = res.data.phoneNumber;
  this.paymentObject.auth = res.data.accessToken;
  halyk.pay(this.paymentObject);
}
},(err: any) => {
  this.loading = false;
});
}
  orderCreateNqt(){
    this.loading = true;
    this.api.createOrderNqt(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderKaztest(){
    this.loading = true;
    this.kazTestApi.createOrder(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по Казтест ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderEnt(){
    this.loading = true;
    this.api.createOrder(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ЕНТ ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderPvo(){
    this.loading = true;
    this.api.orderCreatePvo(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ПВО ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  createOrderKaztestAppeal(){
  this.loading = true;
  this.api.orderKaztestAppeal(this.applicationId, this.paymentForm.value)
    .subscribe((res: any) => {
      if (this.paymentForm.get('paymentProviderTypeId').value == 1){
        this.kaspiOrderId = res.data.orderId;
        this.loading = false;
      }
      if (this.paymentForm.get('paymentProviderTypeId').value == 2){
        this.loading = false;
        this.onlineOrderId = res.data.orderId;
        this.paymentUrl = res.data.paymentUrl;
        this.paymentObject.invoiceId = res.data.orderId;
        this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
        this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
        this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
        this.paymentObject.email = res.data.email;
        this.paymentObject.phone = res.data.phoneNumber;
        this.paymentObject.auth = res.data.accessToken;
        halyk.pay(this.paymentObject);
      }
    },(err: any) => {
      this.loading = false;
    });
}

  citizenOrder(){
    this.loading = true;
    this.api.citizenOrderСreate(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }
  createOrderDir(){
    this.loading = true;
    this.api.orderCreateDir(this.applicationId, this.paymentForm.value)
      .subscribe((res: any) => {
        if (this.paymentForm.get('paymentProviderTypeId').value == 1){
          this.kaspiOrderId = res.data.orderId;
          this.loading = false;
        }
        if (this.paymentForm.get('paymentProviderTypeId').value == 2){
          this.loading = false;
          this.onlineOrderId = res.data.orderId;
          this.paymentUrl = res.data.paymentUrl;
          this.paymentObject.invoiceId = res.data.orderId;
          this.paymentObject.invoiceIdAlt = "" + res.data.orderId;
          this.paymentObject.description = `Оплата по ДИР ${res.data.lastname} ${res.data.firstname} ${res.data.patronymic}`;
          this.paymentObject.amount = res.data.amount ? res.data.amount : 1;
          this.paymentObject.email = res.data.email;
          this.paymentObject.phone = res.data.phoneNumber;
          this.paymentObject.auth = res.data.accessToken;
          halyk.pay(this.paymentObject);
        }
      },(err: any) => {
        this.loading = false;
      });
  }

  goToPayment(){
    this.router.navigate([this.paymentUrl]);
  }

  getTestStatus(){
    switch (this.type){
      case 13:
        this.getTestStatusProbDir();
        break;
      case 12:
        this.getTestStatusProbKazTest();
        break;
      case 11:
        this.getTestStatusProbCitizen();
        break;
      case 10:
        this.getTestStatusProbOzp();
        break;
      case 9:
        this.getTestStatusProbPvo();
        break;
      case 7:
        this.getTestStatusProbEnt();
        break;
      case 4:
        this.getTestStatusPvo();
        break;
      case 5:
        this.getTestStatusDir();
        break;
      case 8:
        this.getTestStatusKaztest();
        break;
      case 3:
        this.getTestStatusAppeal();
        break;
      case 2:
        this.getTestStatusCitizen();
        break;
      case 6:
        this.getTestStatusNqt();
        break;
      default:
        this.getTestStatusEnt();
    }
  }

  leavePayment(){
    if (this.type == 7){
      this.router.navigateByUrl('/profile/prob-test/2/2');
    }
    if (this.type == 9){
      this.router.navigateByUrl('/profile/prob-test/1/2');
    }
    if (this.type == 10){
      this.router.navigateByUrl('/profile/prob-test/3/2');
    }
    if (this.type == 11){
      this.router.navigateByUrl('/profile/prob-test/6/2');
    }
    if (this.type == 12){
      this.router.navigateByUrl('/profile/prob-test/5/2');
    }
    if (this.type == 13){
      this.router.navigateByUrl('/profile/prob-test/4/2');
    }
}

  getTestStatusProbOzp(){
    this.loading = true;
    this.api.getShortInfoProbNqt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusProbPvo(){
    this.loading = true;
    this.api.getShortInfoProbEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusProbEnt(){
    this.loading = true;
    this.api.getShortInfoProbEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusProbDir(){
    this.loading = true;
    this.api.getShortInfoProbEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusProbKazTest(){
    this.loading = true;
    this.api.getShortInfoProbEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }
  getTestStatusProbCitizen(){
    this.loading = true;
    this.api.getShortInfoProbEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }
  getTestStatusNqt(){
    this.loading = true;
    this.api.getShortInfoNqt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusAppeal(){
    this.loading = true;
    this.kazTestApi.getShortAppeal(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusKaztest(){
    this.loading = true;
    this.kazTestApi.getShort(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusPvo(){
    this.loading = true;
    this.api.getShortInfoPvo(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusCitizen(){
    this.loading = true;
    this.api.getCitizenShortItems(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }
  getTestStatusDir(){
    this.loading = true;
    this.api.getDirShort(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

  getTestStatusEnt(){
    this.loading = true;
    this.api.getApplicationInformationEnt(this.applicationId)
      .subscribe((res: any) => {
        this.loading = false;
        this.testStatusId = res.data.testStatusId;
      },(err: any) => {
      });
  }

}
