<div *ngIf="router.url == '/profile/applications/4'" class="flex flow">
    <div class="vajnayainfo">
        <!-- <p class="mb-1">
            {{ 'pvo-text' | translate }}
        </p> -->
        <p class="mb-1 text-center uppercase font-bold">
            {{ 'pvo-textv' | translate }}
        </p>
        <!-- <p class="mb-1">
           - {{ 'pvo-text2' | translate }}
        </p> -->
        <p class="mb-1">
           - {{ 'pvo-text3' | translate }}
        </p>
        <p class="mb-1">
           - {{ 'pvo-text4' | translate }}
        </p>
    </div>
    <!-- <div class="mb-4 flex bg-blue-200 p-4">
        <button class="znak-blue mr-4">
            !
        </button>
        <div class="text-blue-600">
            <div class="text-blue-600">
                <p>
                    {{ 'ForPVOINFO' | translate }}
                </p>
                <p>
                    {{ 'ForPVOINFO2' | translate }}
                </p>
                <p>
                    {{ 'ForPVOINFO3' | translate }}
                </p>
                <p>
                    {{ 'ForPVOINFO4' | translate }}
                </p>
            </div>
        </div>

     </div> -->
    <div class="my-2 w-full text-center">
        <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700" href="./../../../../../assets/vpk.html" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'VirPK' | translate }}</p></a>
        <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700" href="./../../../../../assets/vpk.html" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'VirPK' | translate }}</p></a>
    </div>
    <div *ngFor="let season of seasonsList" [routerLink]="'/profile/applications/4/seasons/' + season.id" class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
        <span *ngIf="translate.currentLang == 'kz'">{{ season.name.kk }}</span>
        <span *ngIf="translate.currentLang == 'ru'">{{ season.name.ru }}</span>
    </div>
    <!-- <div [routerLink]="'/profile/applications/4/seasons/3/5'" [queryParams]="{appTypeId: '5'}" class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
        {{ 'PvoVoen' | translate }}
    </div> -->

    <div class="mt-2 w-full text-center">
        <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700" href="./../../../../../assets/instr_pvo_2023_rus.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsPvo' | translate }}</p></a>
        <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700" href="./../../../../../assets/instr_pvo_2023_kaz.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsPvo' | translate }}</p></a>
    </div>



    <p class="mb-2">
        {{ 'OrgTestQuestionsPVO' | translate }} : +7 (7172) 799889 (ішкі номер 133, 134)
    </p>
    <br>


</div>
<!-- <p *ngIf="router.url == '/profile/applications/4'" class="mb-2">
    {{ 'PvoSubTxt2' | translate }}
</p> -->
<p *ngIf="router.url == '/profile/applications/4'" class="mb-2">
    {{ 'PvoSubTxt' | translate }}
</p>

<router-outlet *ngIf="router.url != '/profile/applications/pvo'"></router-outlet>
