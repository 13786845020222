<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ "KazTest" | translate }}
</p>

<p class="font-hairline text-center uppercase tracking-widest text-2xl text-blue-500 my-4">
  {{ (testTypeId === 3 ? 'kaztestCert' : 'kaztestDiag') | translate}}
</p>
<form [formGroup]="applicationForm" action="">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    <div
      class="w-4/5 text-center text-3xl font-hairline uppercase tracking-widest"
    >
      {{ "PersonalData" | translate }}
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
  </div>

  <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
    <div class="flex rounded-mdp-4">
      {{ "IIN" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ iin }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Surname" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ lastname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Name" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ firstname }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Patronymic" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ patronymic }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "YourEmail" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ email }}
    </div>
    <div class="flex rounded-mdp-4">
      {{ "DateOfBirth" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input
        disabled
        class="w-full pl-1 bg-gray-200 rounded"
        type="date"
        formControlName="birthDate"
      />
      <!--       (change)="getDateOfBirth($event.target.value)"-->

    </div>

    <div class="flex rounded-mdp-4">
      {{ "Citizenship" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select
        formControlName="citizenshipId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "IndicateСitizenship" | translate }}
        </option>
        <option
          [disabled]="!canEditCitizenshipId"
          *ngFor="let item of countryList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Nationality" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <select
        formControlName="nationId"
        class="w-full pl-1 bg-gray-200 rounded"
      >
        <option disabled [value]="0">
          {{ "IndicateNationality" | translate }}
        </option>
        <option
          [disabled]="!canEditNationId"
          *ngFor="let item of nationsList"
          [value]="item.id"
        >
          <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
          <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
        </option>
      </select>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "Sex" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <div class="flex justify-around text-center">
        <div class="mr-4">
          <input disabled
            value="1"
            [checked]="applicationForm.get('sexId').value == 1"
            formControlName="sexId"
            class="mr-2 bg-gray-200 rounded pol-radio"
            id="pol1"
            type="radio"
          />
          <label for="pol1">{{ "Male" | translate }}</label>
        </div>
        <div class="">
          <input disabled
            value="2"
            [checked]="applicationForm.get('sexId').value == 2"
            formControlName="sexId"
            class="mr-2 bg-gray-200 rounded pol-radio"
            id="pol2"
            type="radio"
          />
          <label for="pol2">{{ "Female" | translate }}</label>
        </div>
      </div>
    </div>
    <div class="flex rounded-mdp-4">
      {{ "PhoneNumber" | translate }}
    </div>
    <div class="flex rounded-mdp-4">
      <input
        formControlName="phoneNumber"
        [mask]="phone"
        placeholder="+7 (000) 000-00-00"
        class="w-full pl-1 bg-gray-200 rounded"
        type="text"
      />
    </div>
  </div>

<!--  -->
<!--  -->
  <table class="table table-bordered pc-version mt-5">
    <tr class="text-center bg-blue-300">
      <td class="w-1/2">
        {{ 'Block' | translate }}
      </td>
      <td class="w-1/2">
        {{ 'Actions' | translate }}
      </td>
    </tr>
    <tr *ngFor="let sub of subjects; index as num">
      <td>
        <span *ngIf="translate.currentLang == 'ru'">{{ sub?.name.ru }}</span>
        <span *ngIf="translate.currentLang == 'kz'">{{ sub?.name.kk }}</span>
      </td>
      <td class="text-center">
        <p *ngIf="sub?.canAddAppealDescription == false">Количество поданных апелляции во время тестирования: {{ sub.onlineTestAppealCount }} </p>
        <button (click)="showWindow(sub?.id)" *ngIf="sub?.canAddAppealDescription == true" class=" w-1/2 bg-blue-400 zayav-button ml-3 tracking-wide hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
          {{ 'Заполнить обоснования' | translate }}
        </button>
      </td>
    </tr>
  </table>

  <div class="flex">
    <button *ngIf="!this.showAnwerCard" (click)="showAnswer()" class=" bg-blue-600 ml-3 tracking-wide w-full text-white font-hairline py-1 px-3 rounded">
      <b>ПОКАЗАТЬ КАРТУ АНАЛИЗА</b>
    </button>
    <button *ngIf="this.showAnwerCard" (click)="showAnswer()" class=" bg-blue-600 ml-3 tracking-wide w-full text-white font-hairline py-1 px-3 rounded">
      <b>СВЕРНУТЬ КАРТУ АНАЛИЗА</b>
    </button>
  </div>

  <div *ngIf="showAnwerCard">
    <div *ngFor="let s of subjects" class="table-container overflow-x-auto">
      <h3>{{s.name.ru}}</h3>
      <table class="">
        <thead>
        <tr>
          <th>№ задания</th>
          <th *ngFor="let task of s.questions">{{ task.questionNum }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Результат тестирования</td>
          <td *ngFor="let score of s.questions">{{ score.testScore }}</td>
        </tr>
        <tr>
          <td>Поданная апелляция</td>
          <td *ngFor="let appeal of s.questions">
            <p *ngIf="appeal.isAppealExists == true">+</p>
            <p *ngIf="appeal.isAppealExists == false"></p>
          </td>
        </tr>
        <tr>
          <td>Результат апелляции</td>
          <td *ngFor="let appealResult of s.questions">{{ appealResult.appealScore }}</td>
        </tr>
        <tr>
          <td>Сумма баллов</td>
          <td *ngFor="let total of s.questions">{{ total.testScore + total.appealScore }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mt-6">
    <button (click)="openTextAgree()" *ngIf="!isEdit"
            class="btn btn-primary w-full">
      {{ 'PostApplication' | translate }}
    </button>
  </div>


</form>

<div *ngIf="showWindowModal == true" class="overlay">
  <div class="modal-window">
    <div class="relative">
      <div class="modal-sub-header">
        {{ 'Для подачи заявления на апелляцию необходимо заполнить обоснование' | translate }}
      </div>
      <div (click)="closeAgreeModal()" class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold">
        X
      </div>
    </div>
    <div class="">
      <textarea placeholder="Введите обоснование" [(ngModel)]="appealReasonDescription" rows="25" class="h-full w-full border-2"></textarea>
    </div>
    <div class="flex">
      <button (click)="cancel()"
              class="btn btn-primary w-1/2 mr-1">
        {{ 'Отмена' | translate }}
      </button>
      <button (click)="clearReason()"
              class="btn btn-primary w-1/2 mr-1">
        {{ 'Очистить обоснование' | translate }}
      </button>
      <button (click)="closeAgreeModal()"
              class="btn btn-primary w-1/2 ml-1">
        {{ 'Сохранить' | translate }}
      </button>
    </div>

  </div>
</div>

<div *ngIf="textModal == true" class="overlay">
  <div class="modal-window">
    <div class="relative">
      <div class="modal-sub-header">
        {{ 'PeredPod' | translate }}
      </div>
      <div (click)="closeAgreeModalPostApp()" class="text-red-500 absolute right-0 top-0 cursor-pointer font-bold">
        X
      </div>
    </div>
    <div (scroll)="onScrollEnd($event)" class="modal-text">
            <span>
                <div>
                    <div *ngIf="translate.currentLang === 'ru'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(ruleskazTestSogData.contentRus)"></div>
                    <div *ngIf="translate.currentLang === 'kz'" [innerHTML]="sanitizer.bypassSecurityTrustHtml(ruleskazTestSogData.contentKaz)"></div>
                </div>
            </span>
    </div>

    <div *ngIf="!rulesKazTest" class="text-center">
      <div >
        <input (change)="rulesKazTest = true" type="checkbox" id="get-agree" class="get-agreement">
        <label class="ml-2" for="get-agree">{{ 'RulesText-4' | translate }}</label>
      </div>
    </div>

    <div *ngIf="rulesKazTest">
      <div class="help-text">
        *{{ 'ReadT' | translate }}
      </div>

      <div class="text-center">
        <input [disabled]="blockBtn == true" (change)="agreeCheck()" type="checkbox" id="get-agree"
               class="get-agreement">
        <label class="ml-2" for="get-agree">{{ 'RulesKazTest' | translate }}</label>
      </div>
    </div>
    <div class="w-full text-center">
      <!--      <button (click)="postForm()" [disabled]="!applicationAgreeParent"-->
      <!--              class="btn btn-primary w-full">-->
      <!--        {{ 'PostApplication' | translate }}-->
      <!--      </button>-->

      <button [disabled]="!applicationAgree" (click)="postApp()" *ngIf="!isEdit"
              class="btn btn-primary w-full">
        {{ 'PostApplication' | translate }}
      </button>

<!--      <button (click)="postApp()" *ngIf="isEdit" [disabled]="applicationForm.invalid"-->
<!--              class="btn btn-primary w-full">-->
<!--        {{ 'Edit' | translate }}-->
<!--      </button>-->
    </div>
  </div>

</div>

<!--<div>-->
<!--  <p *ngIf="translate.currentLang == 'ru'" class="text-center my-2 mt-4">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_kaz.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>-->
<!--  <p *ngIf="translate.currentLang == 'kz'" class="text-center my-2 mt-4">{{ 'RulesText-1' | translate }} <a class="text-blue-500" href="./../../../../../../assets/sog_rus.pdf" target="_blank">{{ 'RulesText-2' | translate }}</a></p>-->
<!--</div>-->

<!--<div class="text-center mt-4" *ngIf="!isEdit">-->
<!--  <input (change)="changeAgree($event)" type="checkbox" id="get-agree" class="get-agreement">-->
<!--  <label class="ml-2" for="get-agree">{{ 'RulesText-3' | translate }}</label>-->
<!--</div>-->



