<div class="no-data" *ngIf="showErr">
    {{ 'PvoKonkursErrorData' | translate }}
</div>
<div *ngFor="let app of appList; index as i" class="bg-blue-100 border-blue-500 border-2 rounded mb-4 p-3">
    <div class="flex mb-2 w-full justify-between">
        <div class="text-lg text-blue-500 font-bold uppercase">
            <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
            <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>
    </div>
    <div class="px-3 text-gray-600" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="card">
            <div class="card-body">
              <div *ngIf=" app.showGrantData == true" class="mb-2 text-xl text-center uppercase">
                <span class="grant-text" *ngIf="app.hasGrant == true">Решением Республиканской конкурсной комиссии Вам присужден образовательный грант</span>
                <span class="grant-text-no" *ngIf="app.hasGrant == false">Решением Республиканской конкурсной комиссии Вам не присужден образовательный грант</span>
              </div>
                <div class="mb-2">
                    {{ 'IKT' | translate }} : {{ app.formattedId }}
                </div>

                <div class="mb-2">
                    {{ 'IIN' | translate }} : {{ app.iin}}
                </div>

                <div class="mb-2">
                    {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
                </div>
                <div class="mb-2">
                    {{ 'PzDate' | translate }} : {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
                </div>

                <div class="mb-2">
                    GPA : {{ app.gpa }}
                </div>

                <div *ngIf="app.foreignLangCertificates.length > 0" class="mb-1">
                    <p>{{ 'InternationalEnglishCertificate' | translate }} :</p>
                    <div *ngFor="let cert of app.foreignLangCertificates" class="">
                        <ul class="ml-3 mb-1">
                            <li>{{"vidCert" | translate}}:
                                <span *ngIf="translate.currentLang == 'kz'">{{ cert.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ cert.name.ru }}</span>
                            </li>
                            <li>
                                {{"Ball" | translate}}: {{ cert.score }}
                            </li>
                            <li>
                                {{"numberCert" | translate}}: {{ cert.regNumber }}
                            </li>
                            <li>
                                {{"TestDate" | translate}}: {{ cert.testDate }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="app.greCertificate as gre" class="mb-1">
                    <p>{{ 'greCertificate' | translate }} :</p>
                    <ul class="ml-3">
                        <li>
                            {{"greRegCert" | translate}}: {{ gre.regNumber }}
                        </li>
                        <li>
                            {{"TestDate" | translate}}: {{ gre.testDate }}
                        </li>
                        <li>
                            {{"greCertDate" | translate}}: {{ gre.printDate }}
                        </li>
                        <li>
                            {{"greQuantitativeReasoning" | translate}}: {{ gre.quantitativeReasoningScore }}
                        </li>
                        <li>
                            {{"greAnalyticalWriting" | translate}}: {{ gre.analyticalWritingScore }}
                        </li>
                        <li>
                            {{"greVerbalReasoning" | translate}}: {{ gre.verbalReasoningScore }}
                        </li>
                    </ul>
                </div>



                <div class="mb-1">
                    {{ 'DirectionTraining' | translate }} :
                    <span *ngIf="translate.currentLang == 'kz'">{{ app.masterType.name.kk }}</span>
                    <span *ngIf="translate.currentLang == 'ru'">{{ app.masterType.name.ru }}</span>
                </div>

                <div class="">
                    <div class="font-bold uppercase text-lg text-center my-2">
                        {{ 'SelectedGOP' | translate }}
                    </div>
                    <table class="table table-bordered">
                        <tr  class="text-gray-600">
                            <td class="text-center">
                                №
                            </td>
                            <td class="text-center uppercase">
                                <span class="font-bold">
                                    {{ app.eduProgram.code }} -
                                    <span *ngIf="translate.currentLang == 'kz'">{{ app.eduProgram.name.kk }}</span>
                                    <span *ngIf="translate.currentLang == 'ru'">{{ app.eduProgram.name.ru }}</span>
                                </span>
                            </td>
                            <td class="text-center">
                                {{"note" | translate}}
                            </td>
                        </tr>
                        <tr *ngFor="let choise of app.choices" [ngClass]="{'green-cell': choise.hasGrant}" class="text-gray-600">
                            <td class="w-1/12 text-center">
                                {{ choise.choiceOrder }}
                            </td>
                            <td>
                                <span *ngIf="translate.currentLang == 'kz'">{{ choise.institute.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ choise.institute.name.ru }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="choise.useQuotaGrants.result == true">{{ "genCompl" | translate }}</span>
                                <span *ngIf="choise.useQuotaGrants.result == false">{{ "onlyGenCompl" | translate
                                    }}</span>
                            </td>
                        </tr>
<!--                      <tr *ngFor="let choise of app.choices, let i = index"  >-->
<!--                        <td *ngIf="choise.hasGrant == true && app.showGrantData == true" colspan="2">-->
<!--                          <span *ngIf="translate.currentLang == 'kz'">{{ choise.quotaName.kk }}</span>-->
<!--                          <span *ngIf="translate.currentLang == 'ru'">{{ choise.quotaName.ru }}</span>-->
<!--                        </td>-->
<!--                      </tr>-->
                    </table>

                  <div class="font-bold uppercase text-lg text-center my-2">
                    {{ 'Квота получения гранта' | translate }}
                  </div>

                  <table class="table table-bordered">
                    <tr *ngFor="let choise of app.choices, let i = index" class="text-gray-600">
                      <td *ngIf="choise.hasGrant == true && app.showGrantData == true"  colspan="2">
                        <span *ngIf="translate.currentLang == 'kz'">{{ choise.quotaName.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ choise.quotaName.ru }}</span>
                      </td>
                    </tr>
                  </table>

                  <div *ngIf="app.showGrantData == true && app.hasGrant == true" class="justify-end flex w-full zayav-buttons">
                    <button (click)="checkGrant(app.studentTestId, app.iin, app.konkursYear, 2)" class="bg-green-400 zayav-button tracking-wide ml-3 w-64 hover:bg-green-700 text-white font-hairline py-1 px-3 rounded">
                      {{ 'Получить свидетельство' | translate }}
                    </button>
                  </div>
                </div>

                <div *ngIf="app.eduProgram.isCreative" class="">
                    <div class="font-bold uppercase text-lg text-center my-2">
                        {{ 'CreativeResults' | translate }}
                    </div>
                    <table class="table table-bordered">
                        <tr class="text-gray-600">
                            <td class="w-1/2 text-center">
                                {{ 'Creative' | translate }} 1
                            </td>
                            <td>
                                {{ app.creativeScore1 }}
                            </td>
                        </tr>
                        <tr class="text-gray-600">
                            <td class="w-1/2 text-center">
                                {{ 'Creative' | translate }} 2
                            </td>
                            <td>
                                {{ app.creativeScore2 }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <div (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
        class="text-center arr-btn cursor-pointer">
        <svg *ngIf="isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z" />
        </svg>
        <svg *ngIf="!isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z" />
        </svg>
    </div>
</div>
