<div *ngIf="router.url == '/profile/applications/2'" class="flex flow">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!--    <div class="mb-4 w-full flex bg-blue-200 p-4 text-lg">-->
<!--        <button class="znak-blue mr-4">-->
<!--            !-->
<!--        </button>-->
<!--        <div class="text-blue-600">-->
<!--            <div class="uppercase font-bold">-->
<!--                <span *ngIf="translate.currentLang == 'ru'">-->

<!--                    <p class="text-red-800">Прием заявлений на январское ЕНТ будет проводиться с 20 по 30 декабря.</p>-->


<!--                </span>-->
<!--                <span *ngIf="translate.currentLang == 'kz'">-->


<!--                    <p class="text-red-800"> Қаңтар айындағы ҰБТ-ға өтініш 20-30 желтоқсан аралығында қабылданады.</p>-->


<!--                </span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->


    <div *ngFor="let season of seasonsList" (click)="click(season)" class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
        <span *ngIf="translate.currentLang == 'kz'">{{ season.name.kk }}</span>
        <span *ngIf="translate.currentLang == 'ru'">{{ season.name.ru }}</span>
    </div>


    <!-- <div class="hover:bg-blue-200 type-box border border-dark p-3 m-2 rounded-lg cursor-pointer">
        <span>{{ 'DelayPr' | translate }}</span>
    </div> -->



    <div class="mt-2 w-full text-center">
        <a *ngIf="translate.currentLang == 'ru'" class="underline text-blue-700" href="./../../../../../assets/instr_ent.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsEnt' | translate }}</p></a>
        <a *ngIf="translate.currentLang == 'kz'" class="underline text-blue-700" href="./../../../../../assets/instr_entkz.pdf" target="_blank"><p class="text-center text-2xl text-blue-600 mb-4">{{ 'InstructionsEnt' | translate }}</p></a>
    </div>
    <p class="mb-2">
        {{ 'OrgTestQuestionsENT' | translate }} : +7 (7172) 799889 (ішкі номер 186, 132, 193)
    </p>

</div>

<router-outlet *ngIf="router.url != '/profile/applications/ent'"></router-outlet>
