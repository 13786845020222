<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-app-status [step]="step"></app-app-status>

<div class="">
    <div class="font-hairline uppercase tracking-widest text-2xl text-blue-500 my-4">
        {{ 'PaymentMethod' | translate }}:
    </div>
    <form [formGroup]="paymentForm" action="">
        <div class="">
            <fieldset form="paymentForm">
                <div class="flex text-center">
                    <label class="w-full mb-0" for="kaspi">
                        <div [ngClass]="{'payment-selected': paymentForm.get('paymentProviderTypeId').value == 1}"
                            class="payment-page">
                            <input formControlName="paymentProviderTypeId" id="kaspi" [value]="1" type="radio"> {{
                            'KaspiApp' | translate }}
                        </div>
                    </label>
                    <label class="w-1/2 mb-0" for="online-payment">
                        <div [ngClass]="{'payment-selected': paymentForm.get('paymentProviderTypeId').value == 2}"
                            class="payment-page online-pay-page">
                            <input formControlName="paymentProviderTypeId" id="online-payment" [value]="2" type="radio">
                            {{ 'OnlinePay' | translate }}
                        </div>
                    </label>
                </div>
            </fieldset>
            <div class="payment-content">
                <div *ngIf="paymentForm.get('paymentProviderTypeId').value == 1" class="flex kaspi-content">
                    <div class="w-1/2 kaspi-width">
                        <div class="">
                            <p>
                                {{ 'ChoseKaspi' | translate }}.
                            </p>
                            <p>
                                {{ 'KaspiInstructions-1' | translate }}:
                            </p>
                            <ol class="kaspi-list">
                                <li>
                                    {{ 'KaspiInstructions-2' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-3' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-4' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-5' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-6' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-7' | translate }}
                                </li>
                                <li>
                                    {{ 'KaspiInstructions-8' | translate }}
                                </li>
                              <li>
                                {{ 'KaspiInstructions-9' | translate }}
                              </li>
                            </ol>
                        </div>


                    </div>
                    <div class="w-1/2 kaspi-width">

                        <div *ngIf="kaspiOrderId != 0 && testStatusId == 8" class="kaspi-qr">
                            <div>
                                <img src="../../../../../assets/kaspi.jpeg" class="kaspiimg" alt="">
                            </div>
                            <div class="obshee">
                                <qrcode class="qrcode" [qrdata]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId"
                                    [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                            </div>
                        </div>
                        <div class="text-center my-4">
                            <a target="_blank" [href]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId">
                                <img src="../../../../../assets/images/Gold.svg" *ngIf="kaspiOrderId != 0"
                                    style="margin: 0 auto;cursor: pointer;">
                            </a>
                        </div>
                        <div class="text-center my-4">
                            <button *ngIf="kaspiOrderId == 0" (click)="createOrder()"
                                class="create-order bg-blue-800 hover:bg-blue-700">
                                {{ 'FormAnOrder' | translate }}
                            </button>
                        </div>
                        <div class="nomer-zakaza text-center">
                            {{ 'YourOrderNumber' | translate }}: {{ kaspiOrderId }}
                        </div>


                    </div>


                </div>
                <div *ngIf="type == 4 && paymentForm.get('paymentProviderTypeId').value == 1"
                    class="bg-red-200 p-2 w-full text-center text-lg font-bold mt-6 rounded">
                    {{ 'HalykPay' | translate }}
                </div>
                <div *ngIf="paymentForm.get('paymentProviderTypeId').value == 2" class="flex online-pay-content">
                    <div class="w-1/2 online-pay-width">
                        <div class="">
                            <p>
                                {{ 'ChoseOnlinePay' | translate }}.
                            </p>
                            <p>
                                {{ 'OnlinePayInstructions-1' | translate }}:
                            </p>
                            <ol class="kaspi-list">
                                <li>
                                    {{ 'OnlinePayInstructions-3' | translate }}
                                </li>
                                <li>
                                    {{ 'OnlinePayInstructions-2' | translate }}
                                </li>
                                <li>
                                    {{ 'OnlinePayInstructions-4' | translate }}
                                </li>
                                <li>
                                    {{ 'OnlinePayInstructions-5' | translate }}
                                </li>
                                <li>
                                    {{ 'OnlinePayInstructions-6' | translate }}
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="w-1/2 online-pay-width">
                        <div (click)="createOrder()" class="text-center my-4">
                            <div class="create-order bg-blue-800 hover:bg-blue-700">
                                {{ 'Pay' | translate }}
                            </div>
                        </div>
                        <div class="">
                            <p class="attention text-center my-2">
                                {{ 'Attention' | translate }}!
                            </p>

                            <p class="at-text" *ngIf="type == 5; else unset">
                                {{ '2-hour' | translate }}
                            </p>
                            <ng-template #unset>
                                {{ '30-min' | translate }}
                            </ng-template>



                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex ml-8 mr-8 mt-4">
            <div class="w-full mr-2 status">
                <div class="mb-4">
                    {{ 'OrderStatus' | translate }}:
                    <span class="status-name" *ngIf="testStatusId == 9">
                        {{ 'PAID' | translate }}
                    </span>
                    <span class="status-name" *ngIf="testStatusId == 8">
                        {{ 'notPAID' | translate }}

                    </span>
                </div>
                <div class="">
                    {{ 'ApplicationStatus' | translate }}:
                    <span class="status-name" *ngIf="testStatusId == 9">
                        {{ 'REGISTERED' | translate }}
                    </span>
                    <span class="status-name" *ngIf="testStatusId == 8">
                        {{ 'notREGISTERED' | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div class="w-full mt-4 text-center">
            <div (click)="getTestStatus()" class="create-order bg-blue-800 hover:bg-blue-700">
                {{ 'Update' | translate }}
            </div>
        </div>
        <div *ngIf="testStatusId == 9" [routerLink]="'/profile/history'"
            class="text-center my-4 create-order bg-blue-800 hover:bg-blue-700">
            {{ 'Next' | translate }}
        </div>
    </form>
</div>
