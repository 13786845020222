import {Component, OnInit, isDevMode, Input, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService, NotificationType} from 'angular2-notifications';

@Component({
  selector: 'app-prob-kaztest-result',
  templateUrl: './prob-kaztest-result.component.html',
  styleUrls: ['./prob-kaztest-result.component.css']
})
export class ProbKaztestResultComponent implements OnInit {

  items: any;
  testList = [];
  pageOfItems: Array<any>;
  modal = false;
  deleteModal = false;
  kaspiOrderId = 0;
  quantity = 1;
  paymentUrl: string;
  loading = false;
  id: any;
  test: any;
  applications = [];
  variantCount: any;
  costOfAttempt: any;
  constructor(private api: ApiService, public translate: TranslateService, public router: Router, private route: ActivatedRoute, private _notifications: NotificationsService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.variantCount = JSON.parse(sessionStorage.getItem('variantCount'));
    this.id = JSON.parse(sessionStorage.getItem('id'));
    this.costOfAttempt = JSON.parse(sessionStorage.getItem('costOfAttempt'));

    this.getTests();
    this.getTestTries();
  }

  getTests() {
    this.testList = [];
    this.api.getTestTypegroup(5)
      .subscribe({
        next: (res: any) => {
          res.items.forEach((e: any) => {
            this.testList.push({ ...e, isCollapsed: true });
          });
          this.testList.sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            return dateB - dateA; // Сортировка от новых к старым
          });
        }, error: (err) => {
          this.loading = false;
        }
      });
  }

  getTestTries() {
    let token = localStorage.getItem('token');
    if (token != null) {
      this.api.getKazTestTries(token)
        .subscribe({
          next: (res: any) => {
            this.items = res.items;
          }
        });
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  createOrder() {
    this.loading = true;
    let token = localStorage.getItem('token');
    let body = {
      paymentProviderTypeId: 2,
      returnUrl: "",
      quantity: this.quantity
    };
    if (token != null) {
      this.api.createOrderProbKazTest(token, body).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.kaspiOrderId = res.data.orderId;
        }, error: (err) => {
          this.loading = false;
        }
      });
    }
  }

  closeModal() {
    this.kaspiOrderId = 0;
    this.quantity = 0;
    this.getTestTries();
    this.modal = false;
  }

  links(){
    this.router.navigate([`/profile/prob-test/5/${this.id}/2`]);
  }

  link(p: any) {

    const isMobile = localStorage.getItem('isMobile');

    if (!isDevMode()) {
      if (isMobile){
        window.location.href = 'https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&authUserId=' + p.authUserId + '&url=' + window.location.href;
      }else{
        window.open('https://probtest.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&authUserId=' + p.authUserId + '&url=' + window.location.href);
      }
    }
    if (isDevMode()) {
      if (isMobile){
        window.location.href = 'https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&authUserId=' + p.authUserId + '&url=' + window.location.href;
      }else{
        window.open('https://probtest.debug.testcenter.kz/authorizationHidden?CodeKT=' + p.username + '&pswrd=' + p.password + '&authUserId=' + p.authUserId + '&url=' + window.location.href);
      }
    }
  }

  showEditEnt(id: number){
    this.router.navigate(['profile/prob-test/5/e-zayav-prob-kaztest/' + id], { queryParams: { id: id} });
  }
  showPayment(id: any){
    this.router.navigate(['profile/applications/payment'], { queryParams: { id: id, type: 12 }});
  }
  showDeleteModal(){
    this.deleteModal = true;
  }

  deleteProb(){
    this.api.deleteProbEnt(this.testList[0].id)
      .subscribe((res: any) => {
        this.deleteModal = false;
        this.getTests();
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        this.deleteModal = false;
        this.loading = false;
      });
  }

}
