<div *ngIf="router.url =='/profile/prob-test/6/2'" class="">
  <div *ngIf="router.url =='/profile/prob-test/6/2'" class="block space-y-4 md:flex md:space-y-0 md:space-x-4 ">
    <button (click)="links()" class="mx-auto w-full hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded" type="button">
      {{ 'Buy' | translate}}
    </button>
  </div>
  <p class="font-bold my-3 text-center">{{ 'uniqueOptions' | translate }}: {{ variantCount }}</p>
  <div *ngIf="deleteModal" class="">
    <div class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
    <div class="text-center border-2 p-4 realy-delete fixed z-30 w-1/3 bg-white">
      <p class="mb-8">
        {{ 'SureDeleteApplication' | translate }}?
      </p>
      <div class="flex justify-around w-64 mx-auto">
        <button (click)="deleteProb()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {{ 'Yes' | translate }}
        </button>
        <button (click)="deleteModal = !deleteModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          {{ 'No' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngFor="let app of testList; index as i"  class="bg-blue-100 border-blue-500 zayav border-2 rounded mb-4 p-3">
    <div class="flex w-full justify-between test-type-name">
      <div>
        <span class="text-lg text-blue-500 font-bold uppercase" *ngIf="translate.currentLang == 'kz'">{{ app.testTypeGroup.name.kk }}</span>
        <span class="text-lg text-blue-500 font-bold uppercase" *ngIf="translate.currentLang == 'ru'">{{ app.testTypeGroup.name.ru }}</span>
        <div class="font-bold uppercase">
          <span *ngIf="translate.currentLang == 'kz'">{{ app.moduleType.name.kk }}</span>
          <span *ngIf="translate.currentLang == 'ru'">{{ app.moduleType.name.ru }}</span>
        </div>
      </div>
      <div class="text-right">
        <span class="text-lg text-green-400 font-bold uppercase" *ngIf="translate.currentLang == 'kz'">{{ app.testStatus.name.kk }}</span>
        <span class="text-lg text-green-400 font-bold uppercase" *ngIf="translate.currentLang == 'ru'">{{ app.testStatus.name.ru }}</span>
        <div style="margin-left: -10%" class="font-bold">
          {{ 'AppDate' | translate }}: {{ app.createdAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
        <div style="margin-left: -20%" *ngIf="app.isPaid == false" class="font-bold">
          {{ 'oplatado' | translate }} {{ app.willBeDeletedAt*1000 | date: 'dd.MM.yyy H:mm' }}
        </div>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="app.isCollapsed">
      <div class="card">
        <div class="card-body">
          <div class="px-3 text-gray-600">
            <div class="mb-2">
              {{ 'IKT' | translate }} : {{ app?.id }}
            </div>
            <div class="mb-2">
              {{ 'IIN' | translate }} : {{ app?.iin }}
            </div>
            <div class="mb-2">
              {{ 'fio' | translate }} : {{ app.lastname}} {{ app.firstname}} {{ app.patronymic}}
            </div>
            <div *ngIf="!!app.testPeriod.testDate" class="mb-2">
              {{ 'TestDate' | translate }}:
              <span class="font-bold"> {{ app.testPeriod.testDate }} ({{ 'startTime' | translate }}: {{ app.testPeriod.enterTime }})</span>
            </div>
            <div *ngIf="!!app.testOrg.name.kk" class="mb-2">
              {{ 'TestOrg' | translate }}:
              <span *ngIf="translate.currentLang == 'kz'">{{ app.testOrg.name.kk }}</span>
              <span *ngIf="translate.currentLang == 'ru'">{{ app.testOrg.name.ru }}</span>
            </div>
            <div *ngIf="app.subjects != null" class="mobile-version">
              <div class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                {{ 'TestSubj' | translate }}
              </div>
            </div>
            <div *ngIf="app.subjects != null" class="pc-version">
              <div class="text-lg mb-2 text-gray-500 font-bold uppercase text-center">
                {{ 'TestSubj' | translate }}
              </div>
            </div>
            <table *ngIf="app.subjects != null" class="table table-bordered pc-version">
              <tr class="text-center">
                <td class="w-1/12">
                  №
                </td>
                <td class="w-7/12">
                  {{ 'Subject' | translate }}
                </td>
                <td *ngIf="app.testStatus.id == 5" class="w-3/12">
                  {{ 'Баллы' | translate }}
                </td>
              </tr>
              <tr *ngFor="let sub of app.subjects; index as i">
                <td class="text-center">
                  <span>{{ sub.ord }}</span>
                </td>
                <td class="text-center">
                  <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk }}</span>
                  <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru }}</span>
                </td>
                <td *ngIf="app.testStatus.id == 5" class="text-center">
                  <span>{{ sub.testScore }}</span>
                </td>
              </tr>
              <tr *ngIf="app.testStatus.id == 5">
                <td class="w-3/4" colspan="2">
                  {{ 'totalScore' | translate }}
                </td>
                <td class="text-center">
                  {{ app.totalScore }}
                </td>
              </tr>
            </table>
            <table *ngIf="app.subjects != null" class="table table-bordered mobile-version">
              <tr class="text-center">
                <td class="w-1/12">
                  №
                </td>
                <td class="w-7/12">
                  {{ 'Subject' | translate }}
                </td>
                <td *ngIf="app.testStatus.id == 5" class="w-3/12">
                  {{ 'Баллы' | translate }}
                </td>
              </tr>
              <tr *ngFor="let sub of app.subjects; index as i">
                <td class="text-center">
                  <span>{{ sub.ord }}</span>
                </td>
                <td class="text-center">
                  <span *ngIf="translate.currentLang == 'kz'">{{ sub.subject.name.kk }}</span>
                  <span *ngIf="translate.currentLang == 'ru'">{{ sub.subject.name.ru }}</span>
                </td>
                <td *ngIf="app.testStatus.id == 5" class="text-center">
                  <span>{{ sub.testScore }}</span>
                </td>
              </tr>
              <tr *ngIf="app.testStatus.id == 5">
                <td class="w-3/4" colspan="2">
                  {{ 'totalScore' | translate }}
                </td>
                <td class="text-center">
                  {{ app.totalScore }}
                </td>
              </tr>
            </table>
          </div>
          <div class="flex w-full justify-end mt-2 zayav-buttons px-3">
            <div class="text-center">
              <p *ngIf="app.testStatus.id == 8" class="">{{ ' ' }}</p>
              <button *ngIf="app.testStatus.id == 8" (click)="showPayment(app.id)" class="mt-4 w-64 bg-blue-400 ml-3 tracking-wide hover:bg-blue-700 zayav-button text-white font-hairline py-1 px-3 rounded">
                {{ 'Pay' | translate }}
              </button>
            </div>
            <div class="text-center">
              <p *ngIf="app.isCanBeChanged == true" class="text-blue-700">
                {{ 'vozmojnoDo' | translate }}: {{ app.canBeChangedBefore*1000 | date: 'dd.MM.yyy H:mm'  }}
              </p>
              <button *ngIf="app.isCanBeChanged == true" (click)="showEditEnt(app.id)" class="w-64 bg-blue-400 zayav-button ml-3 tracking-wide hover:bg-blue-700 text-white font-hairline py-1 px-3 rounded">
                {{ 'EditApplication' | translate }}
              </button>
            </div>
            <div class="text-center">
              <p *ngIf="app.isCanBeDeleted == true" class="text-red-400">
                {{ 'vozmojnoDo' | translate }}: {{ app.canBeDeletedBefore*1000 | date: 'dd.MM.yyy H:mm'  }}
              </p>
              <button *ngIf="app.isCanBeDeleted == true" (click)="showDeleteModal()" class="w-64 bg-red-400 zayav-button ml-3 tracking-wide hover:bg-red-700 text-white font-hairline py-1 px-3 rounded">
                {{ 'DeleteApplication' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div (click)="collapse.toggle()" [attr.aria-expanded]="!app.isCollapsed" aria-controls="collapseExample" class="text-center arr-btn cursor-pointer">
      <svg *ngIf="app.isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z"/></svg>
      <svg *ngIf="!app.isCollapsed" class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z"/></svg>
    </div>
  </div>
</div>
<div *ngIf="router.url =='/profile/prob-test/6/1'" class="">
  <div class="block space-y-4 md:flex md:space-y-0 md:space-x-4 ">
    <button (click)="modal = true" class="mx-auto w-full mb-8 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded" type="button">
      {{ 'Buy' | translate}}
    </button>
  </div>
  <div *ngIf="modal == true" class="overlay">
    <div class="modal-window">
      <div class="relative">
        <div class="modal-sub-header">
          {{ 'OnlinePay' | translate }}
        </div>
      </div>
      <div class="modal-text">
        <div class="flex kaspi-content">
          <div class="w-1/2 kaspi-width">
            <p>
              {{ 'KaspiInstructions-1' | translate }}:
            </p>
            <ol class="kaspi-list">
              <li>
                {{ 'KaspiInstructions-2' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-3' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-4' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-5' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-6' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-7' | translate }}
              </li>
              <li>
                {{ 'KaspiInstructions-81' | translate }}
              </li>
            </ol>
          </div>
          <div class="w-1/2 kaspi-width">
            <div *ngIf="kaspiOrderId != 0" class="kaspi-qr">
              <div>
                <img src="../../../../../../assets/kaspi.jpeg" class="kaspiimg" alt="">
              </div>
              <div class="obshee">
                <qrcode class="qrcode" [qrdata]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
              </div>
            </div>
            <div class="text-center my-4">
              <a target="_blank" [href]="'https://kaspi.kz/pay/NCT?4148=' + kaspiOrderId">
                <img src="../../../../../../assets/images/Gold.svg" *ngIf="kaspiOrderId != 0" style="margin: 0 auto;cursor: pointer;">
              </a>
            </div>
            <div class="text-center" *ngIf="kaspiOrderId == 0">
              <p class="mb-3 font-bold">{{'ProbPrice' | translate: {value: costOfAttempt} }}</p>
              <p class="text-sm font-semibold">{{'AddNumberTestingAttempts' | translate}}</p>
              <p class="mb-3 text-sm font-semibold">{{'AddNumberTestingAttemptsContinue' | translate: {value: variantCount} }}</p>
              <div class="mb-3" data-te-input-wrapper-init>
                <input type="number" [(ngModel)]="quantity" required class="w-full text-center bg-gray-200 rounded mb-2" id="exampleFormControlInputNumber" [min]="1" [max]="5" maxlength="5" placeholder="{{'NumberTestingAttempts' | translate}}" />
              </div>
              <button *ngIf="kaspiOrderId == 0 && quantity >0 && quantity<=5" (click)="createOrder()" class="create-order bg-blue-800 hover:bg-blue-700">
                {{ 'FormAnOrder' | translate }}
              </button>
            </div>
            <div class="nomer-zakaza text-center font-bold" *ngIf="kaspiOrderId != 0">
              {{ 'YourOrderNumber' | translate }}: {{ kaspiOrderId }}
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="kaspiOrderId != 0">
          <button (click)="closeModal()" class="mx-auto w-full mt-10 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{'Close' | translate}}</button>
        </span>
      <span *ngIf="kaspiOrderId == 0">
          <button (click)="closeModal()" class="mx-auto w-full mt-10 hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{'Cancel' | translate}}</button>
        </span>
      <div class="my-4 w-full rounded-lg flex bg-blue-200 p-4 text-lg">
        <button class="znak-blue mr-4">
          !
        </button>
        <div class="text-blue-600">
          <div class="uppercase font-bold">
               <span *ngIf="translate.currentLang == 'ru'">
        <p class="text-blue-800">Денежные средства, оплаченные за пробное онлайн тестирование возврату не подлежат.</p>
      </span>
            <span *ngIf="translate.currentLang == 'kz'">
        <p class="text-blue-800">Онлайн байқау тестілеуге төленген ақша қаражаты қайтарылуға жатпайды.</p>
      </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative overflow-x-auto sm:rounded-lg shadow-inner">
    <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400 mb-4">
      <thead class="text-xs text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3">
          {{'DateAdd' | translate}}
        </th>
        <th scope="col" class="px-6 py-3">
          {{'TestStatus' | translate}}
        </th>
        <th scope="col" class="px-6 py-3">
          {{'Link' | translate}}
        </th>
      </tr>
      </thead>
      <tbody *ngFor="let item of pageOfItems">
      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 text-gray-900">
        <td class="py-1">
          {{item.createdAt}}
        </td>
        <td class="py-1">
            <span *ngIf="translate.currentLang=='kz'">
              {{item.testStatusName.kk}}
            </span>
          <span *ngIf="translate.currentLang=='ru'">
              {{item.testStatusName.ru}}
            </span>
        </td>
        <td class="py-1">
            <span *ngIf="translate.currentLang=='kz'">
              <button (click)="link(item)" class="mx-auto w-full hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{item.actionName.kk}}</button>
            </span>
          <span *ngIf="translate.currentLang=='ru'">
              <button (click)="link(item)" class="mx-auto w-full hover:bg-blue-800 text-white font-semibold hover:text-white py-2 px-4 border border-black bg-blue-600 rounded">{{item.actionName.ru}}</button>
            </span>
        </td>
      </tr>
      </tbody>
    </table>
    <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
  </div>
</div>
<router-outlet></router-outlet>






















