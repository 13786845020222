<div class="text-xl z-10 relative justify-center uppercase text-blue-900 bg-blue-100 font-medium p-4 py-16 flex">
  {{ 'NCT' | translate }}
</div>
<div class="container">
  <div class="flex mb-4">
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
      {{ 'TestParams' | translate }}:
    </div>
    <div class="w-1/5 h-1 bg-blue-300 mt-4">
    </div>
  </div>
  <form action="" [formGroup]="applicationForm">
    <!-- Не ТИПО -->
    <div  class="flex mb-2">
      <div class="w-1/3">
        {{ 'SelectProfileSubjects' | translate }}:
      </div>
      <div class="w-1/3">
        <select (change)="getLangList(0)" formControlName="subjectId" class="w-full pl-1 bg-gray-200 rounded">
          <option disabled [value]="0">
            {{ 'ACoupleOfItems' | translate }}
          </option>
          <option *ngFor="let item of subjectList" [value]="item.id">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
          </option>
        </select>
      </div>
    </div>
    <div  class="flex mb-2">
      <div class="w-1/3">
        {{ 'SelectTheLanguageForTesting' | translate }}:
      </div>
      <div class="w-1/3">
        <select formControlName="testLangId" class="w-full pl-1 bg-gray-200 rounded">
          <option disabled [value]="0">
            {{ 'LanguageOfSubjects' | translate }}
          </option>
          <option *ngFor="let item of langList" [value]="item.id">
            <span *ngIf="translate.currentLang == 'kz'">{{ item.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.name.ru }} </span>
          </option>
        </select>
      </div>
    </div>

    <div class="flex gap-6">
      <div (click)="edit()"
           class=" bg-green-500 hover:bg-green-700 w-full cursor-pointer text-center p-2 text-white mt-8">

        {{ 'Save' | translate }}

      </div>
      <div (click)="cancel()"
           class=" bg-blue-500 hover:bg-blue-700 w-full cursor-pointer text-center p-2 text-white mt-8">

        {{ 'Cancel' | translate }}

      </div>
    </div>


  </form>

</div>
